import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { LoadingService } from './services/loading/loading.service';
import { TranslationService } from './modules/i18n';
import { locale as enLang } from './modules/i18n/vocabs/en';
import { locale as trLang } from './modules/i18n/vocabs/tr';
import { ThemeModeService } from './extras/theme-mode-switcher/theme-mode.service';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'body[root]',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  public loading: boolean = false;

  constructor(
    private translationService: TranslationService,
    private modeService: ThemeModeService,
    private _loading: LoadingService,
    private cdr: ChangeDetectorRef,
  ) {
    // register translations
    this.translationService.loadTranslations(enLang, trLang);
  }

  ngOnInit() {
    this.modeService.init();
    this._loading.loadingSub.subscribe((loading) => {
      this.loading = loading;
      this.cdr.detectChanges();
    });
  }
}
