import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable({providedIn: "root"})
export class LoadingService {
  timeout: any
  loadingSub: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  loadingMap: Map<string, boolean> = new Map<string, boolean>();

  setLoading(loading: boolean, url: string): void {

    if (!url) {
      throw new Error('The request URL must be provided to the LoadingService.setLoading function')
    }
    if (loading) {
      clearTimeout(this.timeout);
      this.loadingMap.set(url, loading);
      setTimeout(() => this.loadingSub.next(true))
    } else if (!loading && this.loadingMap.has(url)) {
      clearTimeout(this.timeout);
      this.loadingMap.delete(url)
      this.timeout = setTimeout(() => this.loadingSub.next(false), 500)
    }
    if (this.loadingMap.size === 0) {
      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => this.loadingSub.next(false), 500)
    }
  }
}
