import { Component } from '@angular/core';

@Component({
  selector: 'app-table-thead-tr',
  template: `
    <tr class="fw-semibold text-muted text-uppercase">
      <ng-content />
    </tr>
  `,
  styles: [
    `
      :host {
        display: contents;
      }
    `,
  ],
})
export class TableTheadTrComponent {}
