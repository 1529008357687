import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import * as XLSX from 'xlsx';
@Component({
  selector: 'app-tables-widget10',
  templateUrl: './tables-widget10.component.html',
})
export class TablesWidget10Component implements OnInit {
  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() onClickAddNew = new EventEmitter();
  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() onClickDelete = new EventEmitter();
  @Input() selectedLength: number = 0;
  @Input() head!: string;
  @Input() exportList: any[] = [];
  @Input() disableAddNew: boolean = false;
  @Input() disableDrawer: boolean = false;

  constructor() {}

  ngOnInit(): void {}

  exportXLSX() {
    const data = this.exportList;
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(data);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Veriler');

    XLSX.writeFile(wb, `${this.head}.xlsx`);
  }
}
