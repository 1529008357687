import { Component, HostListener, Inject } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';

@Component({
  selector: 'app-atom-logout-confirm',
  templateUrl: './atom-logout-confirm.component.html',
  styleUrls: ['./atom-logout-confirm.component.scss'],
})
export class AtomLogoutConfirmComponent {
  constructor(
    public dialogRef: MatDialogRef<AtomLogoutConfirmComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ILogoutConfirmModal
  ) {}

  onApprove = () => this.dialogRef.close({ approved: true });
  onCancel = () => this.dialogRef.close({ approved: false });

  @HostListener('document:mousemove', ['$event'])
  onMouseMove() {
    this.onCancel();
  }
}

export const handleLogOutConfirmModal = (
  dialog: MatDialog,
  data: ILogoutConfirmModal = { approved: false },
  callBack: () => void
) => {
  const dialogRef = dialog.open(AtomLogoutConfirmComponent, {
    data,
    width: '450px',
    maxWidth: '450px',
    panelClass: 'metronic-custom-modal',
  });

  dialogRef.afterClosed().subscribe((result) => {
    if (result?.approved) callBack();
  });
};

export interface ILogoutConfirmModal {
  approved?: boolean;
}
