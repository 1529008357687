<!-- begin::Header -->

<div class="card-header border-0 pt-5">
  <div class="d-flex align-items-center">
    <h3 class="card-title align-items-start flex-column">
      <span class="card-label fw-semibold fs-3 mb-1" *ngIf="head">{{
        head
      }}</span>
    </h3>
    <button
      *ngIf="exportList.length"
      class="btn fw-semibold p-0"
      (click)="exportXLSX()"
    >
      <ng-icon
        name="heroCloudArrowDown"
        size="20"
        class="text-primary"
        style="display: contents"
      ></ng-icon>
    </button>
  </div>
  <div
    class="card-toolbar"
    data-bs-toggle="tooltip"
    data-bs-placement="top"
    data-bs-trigger="hover"
  >
    <button
      class="btn btn-sm btn-light-danger me-3"
      *ngIf="selectedLength"
      (click)="onClickDelete.emit()"
    >
      <ng-icon name="heroTrash" size="16" style="display: contents"></ng-icon>
      {{ "GENERAL.RemoveSelected" | translate }} ({{ selectedLength }})
    </button>
    <button
      id="kt_activities_toggle"
      class="btn btn-sm btn-light-primary"
      (click)="onClickAddNew.emit()"
      *ngIf="!disableAddNew && !disableDrawer"
    >
      <ng-icon
        name="heroPlusSmall"
        size="16"
        style="display: contents"
      ></ng-icon>
      {{ "STOCK_PRODUCT.AddNew" | translate }}
    </button>
    <button
      class="btn btn-sm btn-light-primary"
      (click)="onClickAddNew.emit()"
      *ngIf="disableDrawer"
    >
      <ng-icon
        name="heroPlusSmall"
        size="16"
        style="display: contents"
      ></ng-icon>
      {{ "STOCK_PRODUCT.AddNew" | translate }}
    </button>
  </div>
</div>
<!-- end::Header -->
<!-- begin::Body -->
<div class="card-body py-3">
  <!-- begin::Table container -->
  <div class="table-responsive">
    <!-- begin::Table -->
    <ng-content></ng-content>
    <!-- end::Table -->
  </div>
  <!-- end::Table container -->
</div>
<!-- begin::Body -->
