import {
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { LoadingService } from '../../../services/loading/loading.service';

@Component({
  selector: 'app-atom-no-data',
  templateUrl: 'atom-no-data.component.html',
  styles: [
    `
      :host {
        display: contents;
      }

      .fade-in-element {
        opacity: 0;
      }

      @keyframes fadeIn {
        from {
          opacity: 0;
        }
        to {
          opacity: 1;
        }
      }

      .fade-in-element.show {
        animation: fadeIn 2s 0.5s forwards;
      }

      .spinner {
        width: 50px;
        height: 50px;
        border: 5px solid rgba(0, 0, 255, 0.2) !important;
        border-top: 5px solid blue !important;
        border-radius: 50%;
        animation: spin 1s linear infinite;
      }

      @keyframes spin {
        0% {
          transform: rotate(0deg);
        }
        100% {
          transform: rotate(360deg);
        }
      }
    `,
  ],
})
export class AtomNoDataComponent implements OnInit {
  loading: boolean = false;
  @Input() text!: string;
  @Input() className!: string;

  constructor(
    private _loading: LoadingService,
    private cdr: ChangeDetectorRef,
  ) {}

  public ngOnInit(): void {
    this._loading.loadingSub.subscribe((loading) => {
      this.loading = loading;
      this.cdr.detectChanges();
    });
  }
}
