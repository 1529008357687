import { Component } from '@angular/core';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'icon-angle-down',
  template: `
    <ng-icon
      name="heroChevronDown"
      size="20"
      style="display: contents"
    ></ng-icon>
  `,
  styles: [
    `
      :host {
        display: contents;
      }
    `,
  ],
})
export class IconAngleDownComponent {}
