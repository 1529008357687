import { Component, OnInit, ViewChild } from '@angular/core';
import { PdfService } from '../../../services/pdf/pdf.service';
import { PluspayContractItemComponent } from '../pluspay-contract-item/pluspay-contract-item.component';
import { PluspayContractModel } from './pluspay-contract-model';

@Component({
  selector: 'app-comp-pluspay-contract',
  templateUrl: './pluspay-contract.component.html',
  styleUrls: ['./pluspay-contract.component.scss'],
})
export class PluspayCompContractComponent implements OnInit {
  @ViewChild('page_01') page_01: PluspayContractItemComponent;
  @ViewChild('page_02') page_02: PluspayContractItemComponent;
  @ViewChild('page_03') page_03: PluspayContractItemComponent;
  @ViewChild('page_04') page_04: PluspayContractItemComponent;
  @ViewChild('page_05') page_05: PluspayContractItemComponent;
  @ViewChild('page_06') page_06: PluspayContractItemComponent;
  @ViewChild('page_07') page_07: PluspayContractItemComponent;
  @ViewChild('page_08') page_08: PluspayContractItemComponent;
  @ViewChild('page_09') page_09: PluspayContractItemComponent;
  @ViewChild('page_10') page_10: PluspayContractItemComponent;
  @ViewChild('page_11') page_11: PluspayContractItemComponent;

  doc: PluspayContractModel;

  constructor(public pdfService: PdfService) {}

  public ngOnInit(): void {
    this.doc = PluspayContractModel.fromObject({});
  }

  onPrintClick() {
    window.print();
  }

  onExportClick() {
    const generatePages = () => {
      const arr = [
        'page_01',
        'page_02',
        'page_03',
        'page_04',
        'page_05',
        'page_06',
        'page_07',
        'page_08',
        'page_09',
        'page_10',
        'page_11',
      ];
      const elements: HTMLElement[] = [];

      for (const key of arr) {
        // @ts-ignore
        elements.push(this[key].getPrint.nativeElement);
      }

      return elements;
    };

    this.pdfService.generatePdf(generatePages(), 'pluspay-sozlesme.pdf');
  }
}
