import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  PersonnelBody,
  Personnel,
  PersonnelDetailWelcome,
} from './personnel.interface';

const API_URL = `/personnels`;

@Injectable()
export class PersonnelService {
  constructor(private http: HttpClient) {}

  fetchPersonnelList() {
    return this.http.get<Personnel[]>(API_URL);
  }

  createPersonnel(body: PersonnelBody) {
    return this.http.post(API_URL, body);
  }

  personnelDetail(personnel_id: string) {
    return this.http.get<PersonnelDetailWelcome>(`${API_URL}/${personnel_id}`);
  }

  updatePersonnel(personnel_id: string, body: PersonnelBody) {
    return this.http.put(`${API_URL}/${personnel_id}`, body);
  }

  deletePersonnel(personnel_id: string) {
    return this.http.delete(`${API_URL}/${personnel_id}`);
  }

  handlePersonnelCreateAndUpdate(
    personnel_body: PersonnelBody,
    personnel_id?: string,
  ) {
    if (personnel_id) {
      return this.updatePersonnel(personnel_id, personnel_body);
    }

    return this.createPersonnel(personnel_body);
  }
}
