<div class="position-relative">
  <div class="atom-select" (click)="open = true">
    <span class="atom-select-label">{{ label }}</span>
    <span class="atom-select-item">{{ selected?.text }}</span>
    <icon-angle-down />
  </div>
  <app-atom-select-dropdown
    *ngIf="open"
    [data]="data"
    [open]="open"
    (clickOutside)="open = false"
    (clickItem)="selected = $event"
  />
</div>
