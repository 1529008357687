import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ReportValorWelcomeItem } from '../../../services/reports/reports.types';
import { TempApproveModalComponent } from '../../templates/temp-approve-modal/temp-approve-modal.component';
import { TempValorModalComponent } from '../../templates/temp-valor-modal/temp-valor-modal.component';

interface Day {
  date: number;
  month: number;
  year: number;
}

export interface DateRange {
  startDate: string;
  endDate: string;
}

@Component({
  selector: 'app-atom-full-calendar-v2',
  templateUrl: './atom-full-calendar-v2.component.html',
  styleUrls: ['./atom-full-calendar-v2.component.scss'],
})
export class AtomFullCalendarV2Component implements OnInit {
  @Output() dateChanged = new EventEmitter<DateRange>();
  @Input() events: ReportValorWelcomeItem[] = [];

  weekDays = ['Pzt', 'Sal', 'Çar', 'Per', 'Cum', 'Cmt', 'Paz'];
  monthNames = [
    'Ocak',
    'Şubat',
    'Mart',
    'Nisan',
    'Mayıs',
    'Haziran',
    'Temmuz',
    'Ağustos',
    'Eylül',
    'Ekim',
    'Kasım',
    'Aralık',
  ];
  currentMonth: number;
  currentYear: number;
  calendar: Day[][];

  constructor(private dialog: MatDialog) {
    const today = new Date();
    this.currentMonth = today.getMonth();
    this.currentYear = today.getFullYear();
  }

  ngOnInit(): void {
    this.generateCalendar(this.currentMonth, this.currentYear);
  }

  handleOpenModal(data: ReportValorWelcomeItem) {
    this.dialog.open(TempValorModalComponent, {
      data: {
        valor: data,
        head: data.company_name,
      },
      width: '450px',
      maxWidth: '450px',
      panelClass: 'metronic-custom-modal',
    });
  }

  getEventsForDate(
    year: number,
    month: number,
    date: number,
  ): ReportValorWelcomeItem[] {
    return this.events.filter((event) => {
      const eventDate = new Date(event.pp_valor_date);
      return (
        eventDate.getFullYear() === year &&
        eventDate.getMonth() === month &&
        eventDate.getDate() === date
      );
    });
  }

  generateCalendar(month: number, year: number): void {
    const firstDay = new Date(year, month, 1).getDay();
    const daysInMonth = new Date(year, month + 1, 0).getDate();
    const calendar: Day[][] = [];
    let week: Day[] = [];

    for (let i = 0; i < firstDay; i++) {
      week.push({ date: 0, month: 0, year: 0 });
    }

    for (let date = 1; date <= daysInMonth; date++) {
      const day: Day = {
        date,
        month,
        year,
      };
      week.push(day);
      if (week.length === 7) {
        calendar.push(week);
        week = [];
      }
    }

    while (week.length < 7) {
      week.push({ date: 0, month: 0, year: 0 });
    }

    calendar.push(week);
    this.calendar = calendar;

    this.emitMonthRange();
  }

  isToday(day: Day): boolean {
    const today = new Date();
    return (
      day.date === today.getDate() &&
      day.month === today.getMonth() &&
      day.year === today.getFullYear()
    );
  }

  prevMonth(): void {
    if (this.currentMonth === 0) {
      this.currentMonth = 11;
      this.currentYear--;
    } else {
      this.currentMonth--;
    }
    this.generateCalendar(this.currentMonth, this.currentYear);
  }

  isLastMonth() {
    const today = new Date();
    const currentMonth = today.getMonth();
    const currentYear = today.getFullYear();

    if (
      this.currentMonth === currentMonth &&
      this.currentYear === currentYear
    ) {
      return true;
    }

    return false;
  }

  nextMonth(): void {
    const today = new Date();
    const currentMonth = today.getMonth();
    const currentYear = today.getFullYear();

    if (
      this.currentMonth === currentMonth &&
      this.currentYear === currentYear
    ) {
      return;
    }

    if (this.currentMonth === 11) {
      this.currentMonth = 0;
      this.currentYear++;
    } else {
      this.currentMonth++;
    }
    this.generateCalendar(this.currentMonth, this.currentYear);
  }

  getPrevMonthName(): string {
    if (this.currentMonth === 0) {
      return this.monthNames[11];
    }
    return this.monthNames[this.currentMonth - 1];
  }

  getNextMonthName(): string {
    if (this.currentMonth === 11) {
      return this.monthNames[0];
    }
    return this.monthNames[this.currentMonth + 1];
  }

  emitMonthRange(): void {
    const startDate = new Date(this.currentYear, this.currentMonth, 1);
    const endDate = new Date(this.currentYear, this.currentMonth + 1, 0);
    const formattedStartDate = startDate.toISOString().split('T')[0];
    const formattedEndDate = endDate.toISOString().split('T')[0];
    this.dateChanged.emit({
      startDate: formattedStartDate,
      endDate: formattedEndDate,
    });
  }
}
