import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'getPercentage'
})
export class GetPercentagePipe implements PipeTransform {

  transform(start: number, end: number): {
    className: string,
    percentage: string
  } {

    if (start === 0) {
      return {
        className: 'secondary',
        percentage: '0%'
      }
    } else if (start === end) {
      return {
        className: 'warning',
        percentage: '20%'
      }
    } else if (end > start) {
      return {
        className: 'danger',
        percentage: `10%`
      };
    } else if (start > (end + 10)) {
      return {
        className: 'success',
        percentage: `70%`
      };
    } else {
      return {
        className: 'primary',
        percentage: `40%`
      };
    }
  }
}
