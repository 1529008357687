import { Component, Inject } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';

@Component({
  selector: 'app-temp-approve-modal',
  template: `
    <div class="card-header d-flex align-items-center justify-content-between">
      <h3 class="card-title fw-semibold text-dark mb-0 fs-1">
        {{ data.head }}
      </h3>
      <button mat-dialog-close="" class="btn btn-sm btn-icon">
        <ng-icon
          name="heroXMarkSolid"
          size="22"
          style="display: contents"
        ></ng-icon>
      </button>
    </div>
    <div mat-dialog-content class="app-modal-content">
      <div class="fs-2 my-5 text-dark" *ngIf="data.content">
        {{ data.content }}
      </div>
      <div class="separator mb-5"></div>
      <div class="d-flex justify-content-end">
        <button class="btn" mat-dialog-close="">
          {{ 'GENERAL.Cancel' | translate }}
        </button>
        <button class="btn btn-primary" (click)="onClick()">
          {{ 'GENERAL.Confirm' | translate }}
        </button>
      </div>
    </div>
  `,
  styles: [],
})
export class TempApproveModalComponent {
  constructor(
    public dialogRef: MatDialogRef<TempApproveModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: IApproveModal,
  ) {}

  onClick() {
    this.data.approved = true;
    this.dialogRef.close(this.data);
  }
}

export const handleApproveModal = (
  dialog: MatDialog,
  data: IApproveModal = { approved: false },
  callBack: () => void,
) => {
  const dialogRef = dialog.open(TempApproveModalComponent, {
    data,
    width: '450px',
    maxWidth: '450px',
    panelClass: 'metronic-custom-modal',
  });

  dialogRef.afterClosed().subscribe((result) => {
    if (result?.approved) callBack();
  });
};

export interface IApproveModal {
  approved?: boolean;
  head?: string;
  content?: string;
}
