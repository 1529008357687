import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { SNACKBAR_CONFIGS } from '../../../services/configs';
import {
  PersonnelBody,
  PersonnelDetailWelcome,
} from '../../../services/personnels/personnel.interface';
import { PersonnelService } from '../../../services/personnels/personnel.service';
import { BranchesWelcome } from '../../../pages/management/branches/branches.component';
import { AtomSnackbarComponent } from '../../components/atom-snackbar/atom-snackbar.component';

@Component({
  selector: 'app-temp-personnel-form',
  templateUrl: './temp-personnel-form.component.html',
  styles: [],
})
export class TempPersonnelFormComponent implements OnInit {
  @Input({ required: true }) loading: boolean;
  @Output() handleOnAddedNewPersonnel = new EventEmitter();

  currentPersonnelId: string | undefined;
  personnelForm: FormGroup | undefined;
  roles = ['USER', 'CANCEL', 'REFUND', 'DISCOUNT', 'PRODUCT'];
  roleList = ['SUPERADMIN', 'AUTHORIZED', 'CASHIER', 'COURIER'];
  branchList: BranchesWelcome[] = [];

  constructor(
    private fb: FormBuilder,
    private angularSnackbar: MatSnackBar,
    private translate: TranslateService,
    private personnelService: PersonnelService,
    private http: HttpClient,
  ) {}

  public ngOnInit(): void {
    this.http.get<BranchesWelcome[]>('/info/branchs').subscribe((res) => {
      this.branchList = res;
    });
  }

  handleOpenPersonnelDetail(personnel_id: string) {
    this.personnelService.personnelDetail(personnel_id).subscribe((res) => {
      if (res.id) {
        this.initForm(res);
        this.currentPersonnelId = res.id;
        setTimeout(() => {
          const kt_activities_toggle = document.getElementById(
            'kt_activities_toggle',
          );
          kt_activities_toggle?.click();
        }, 100);
      }
    });
  }

  initForm(personnel_detail?: PersonnelDetailWelcome) {
    this.personnelForm = undefined;

    this.currentPersonnelId = personnel_detail?.id || undefined;

    this.personnelForm = this.fb.group({
      name: [personnel_detail?.name || ''],
      phone: [personnel_detail?.phone || ''],
      email: [personnel_detail?.email || ''],
      role: [personnel_detail?.role || ''],
      firm_ids: [personnel_detail?.firm_ids || []],
      roles: [personnel_detail?.roles || []],
      is_active: [personnel_detail?.is_active || true],
    });
  }

  submit() {
    const createPersonnelBody = this.personnelForm?.value as
      | PersonnelBody
      | undefined;

    if (createPersonnelBody)
      this.personnelService
        .handlePersonnelCreateAndUpdate(
          createPersonnelBody,
          this.currentPersonnelId,
        )
        .subscribe({
          next: () => {
            const kt_activities_close = document.getElementById(
              'kt_activities_close',
            );

            kt_activities_close?.click();
            this.handleOnAddedNewPersonnel.emit();

            this.angularSnackbar.openFromComponent(
              AtomSnackbarComponent,
              SNACKBAR_CONFIGS['topRightSuccess'](
                this.translate.instant('GENERAL.SuccessfullySaved'),
              ),
            );
          },
          error: (error) => {
            this.angularSnackbar.openFromComponent(
              AtomSnackbarComponent,
              SNACKBAR_CONFIGS['topRightError'](error),
            );
          },
        });
  }
}
