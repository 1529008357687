<div class="card mb-5">
  <div class="card-body py-5 px-6">
    <div
      class="d-flex align-items-center flex-wrap {{
        !disableDatePicker && 'justify-content-end'
      }}"
      style="gap: 15px"
    >
      <div
        class="position-relative w-100 {{
          !disableDatePicker && activeParameters.length <= 0 ? 'w-100' : ''
        }}"
        style="max-width: 400px"
      >
        <ng-icon
          name="heroMagnifyingGlass"
          size="20"
          style="position: absolute; left: 8px; top: 10px"
        ></ng-icon>
        <input
          type="text"
          class="form-control form-control-solid ps-10"
          name="search"
          value=""
          [placeholder]="searchPlaceholder"
          (change)="handleSetSearchKeyword($event)"
        />
      </div>
      <div
        class="{{
          !disableDatePicker && activeParameters.length <= 0 ? '' : 'ms-lg-auto'
        }}"
        *ngIf="!disableDatePicker"
      >
        <app-atom-datepicker-v2
          (clickedSave)="handleSaveDates($event.startDate, $event.endDate)"
        />
      </div>
      <button
        type="button"
        class="btn btn-light-primary"
        data-kt-menu-trigger="click"
        data-kt-menu-placement="bottom-end"
        *ngIf="activeParameters.length > 0"
      >
        <span class="me-2">{{ "GENERAL.Filter" | translate }}</span>
        <ng-icon
          name="heroFunnel"
          size="20"
          style="display: contents"
        ></ng-icon>
      </button>
      <div
        class="menu menu-sub menu-sub-dropdown w-300px {{
          parameterWithCols ? 'w-md-375px' : 'w-md-325px'
        }}"
        data-kt-menu="true"
        *ngIf="activeParameters.length > 0"
      >
        <div class="px-7 py-5">
          <div class="fs-5 text-dark fw-bold">
            {{ "GENERAL.FilterOptions" | translate }}
          </div>
        </div>
        <div class="separator border-gray-200"></div>
        <div class="px-7 py-5">
          <div class="row g-8 mb-4">
            <div *ngIf="isFirmSelect">
              <label class="col-form-label fw-semibold fs-6 required"
                >{{ "GENERAL.FirmIds" | translate }}:</label
              >
              <mat-select
                multiple
                class="form-control form-control-lg form-control-solid"
                #select
                (selectionChange)="onSelectionChange(select, $event)"
              >
                <mat-option
                  *ngFor="let branch of branchList"
                  [value]="branch.id"
                >
                  {{ branch.title }}
                </mat-option>
              </mat-select>
            </div>
            <div
              *ngFor="let parameter of activeParameters"
              [hidden]="parameter.key !== 'special_code'"
            >
              <label class="fs-6 form-label fw-semibold text-dark">{{
                parameter.name
              }}</label>
              <input
                type="text"
                class="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                (change)="handleChangeStatus(parameter.key, $event)"
                [value]="filtersService.getCurrentFilterByKey(parameter.key)"
                autocomplete="off"
              />
            </div>
            <div
              class="{{ parameterWithCols ? 'col-6' : '' }}"
              *ngFor="let parameter of activeParameters"
              [hidden]="parameter.key === 'special_code'"
            >
              <label class="fs-6 form-label fw-semibold text-dark">{{
                parameter.name
              }}</label>
              <select
                class="form-select form-select-solid"
                (change)="handleChangeStatus(parameter.key, $event)"
                [value]="filtersService.getCurrentFilterByKey(parameter.key)"
              >
                <option value="0" selected>
                  {{ "GENERAL.NoSelection" | translate }}
                </option>
                <option
                  [value]="statuses.code"
                  *ngFor="let statuses of getLocalParameter(parameter.key)"
                >
                  {{ statuses.title }}
                </option>
              </select>
            </div>
          </div>
          <div class="d-flex justify-content-end">
            <button
              type="reset"
              class="btn btn-light btn-active-light-primary fw-semibold me-2 px-6"
              data-kt-menu-dismiss="true"
              data-kt-subscription-table-filter="reset"
              (click)="filtersService.clearAllFilters()"
            >
              {{ "GENERAL.Reset" | translate }}
            </button>
            <button
              type="submit"
              class="btn btn-primary fw-semibold px-6"
              data-kt-menu-dismiss="true"
              data-kt-subscription-table-filter="filter"
              (click)="onClickApply.emit()"
              *ngIf="enableOkayBtn"
            >
              {{ "GENERAL.Confirm" | translate }}
            </button>
          </div>
        </div>
      </div>
      <button
        (click)="onClickApply.emit()"
        class="btn btn-primary {{
          !disableDatePicker && activeParameters.length <= 0 ? 'me-auto' : ''
        }}"
      >
        {{ "GENERAL.Search" | translate }}
      </button>
    </div>
  </div>
</div>

<ng-content></ng-content>

<div class="card py-0" *ngIf="!disablePagination">
  <div class="card-body py-3">
    <div class="d-flex flex-stack flex-wrap">
      <div class="flex-center">
        <div
          class="fs-6 fw-semibold text-gray-700 whitespace"
          translate="GENERAL.ListedResults"
          [translateParams]="{ length: total_count }"
        ></div>
        <select
          class="form-select form-select-solid fw-bold ms-2"
          (change)="handleSetLimit($event)"
          [value]="filtersService.getCurrentFilterByKey('limit')"
        >
          <option value="20">20</option>
          <option value="50">50</option>
          <option value="100">100</option>
          <option value="500">500</option>
        </select>
      </div>

      <ul class="pagination" *ngIf="total_page > 1">
        <li class="page-item previous">
          <span
            class="page-link cursor-pointer"
            (click)="onClickPrev.emit(filtersService.getCurrentPage() - 1)"
          >
            <i class="previous"></i>
          </span>
        </li>

        <li
          class="page-item {{
            filtersService.getCurrentPage() === num ? 'active' : ''
          }}"
          *ngFor="
            let num of pagination(
              total_page,
              filtersService.getCurrentPage(),
              5
            )
          "
          [hidden]="num === 0"
        >
          <span
            (click)="onClickPagination.emit(num)"
            class="page-link cursor-pointer"
          >
            {{ num === -1 ? "..." : num }}
          </span>
        </li>

        <li class="page-item next">
          <span
            class="page-link cursor-pointer"
            (click)="onClickNext.emit(filtersService.getCurrentPage() + 1)"
          >
            <i class="next"></i>
          </span>
        </li>
      </ul>
    </div>
  </div>
</div>
