import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
} from '@angular/core';

@Component({
  selector: 'app-atom-select-dropdown',
  templateUrl: './atom-select-dropdown.component.html',
  styleUrls: ['./atom-select-dropdown.component.scss'],
})
export class AtomSelectDropdownComponent implements OnInit {
  @Output() clickOutside: EventEmitter<any> = new EventEmitter();
  @Output() clickItem: EventEmitter<{ id: any; text: string }> =
    new EventEmitter();
  @Input() data: { id: any; text: string }[] = [];
  @Input() open: boolean = false;
  count: number = 0;

  constructor(private elementRef: ElementRef) {}

  public ngOnInit(): void {
    setTimeout(() => (this.count = 1), 100);
  }

  handleSelectItem(item: { id: any; text: string }) {
    this.clickOutside.emit(null);
    this.clickItem.emit(item);
  }

  @HostListener('document:click', ['$event.target'])
  public onClick(targetElement: any): void {
    const clickedInside = this.elementRef.nativeElement.contains(targetElement);
    if (!clickedInside && this.open && this.count) {
      this.clickOutside.emit(null);
      this.count = 0;
    }
  }
}
