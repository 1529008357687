<div class="card-header border-0 pt-5" *ngIf="!noHeaders">
  <div class="d-flex align-items-center justify-content-between w-100">
    <div class="d-flex align-items-center">
      <h3 class="card-title align-items-start flex-column">
        <span class="card-label fw-semibold fs-3">{{ head }}</span>
      </h3>
      <button
        class="btn fw-semibold p-0"
        (click)="exportWithPath ? justExportWithApi() : openDialog()"
        *ngIf="(!disableExport && exportList.length > 0) || exportWithPath"
      >
        <ng-icon
          name="heroCloudArrowDown"
          size="20"
          class="text-primary"
          style="display: contents"
        ></ng-icon>
      </button>
    </div>

    <app-atom-datepicker-v2
      className="me-auto"
      *ngIf="datePickerForm"
      (clickedSave)="handleSaveDates($event.startDate, $event.endDate)"
    />

    <button
      id="kt_activities_toggle"
      class="btn btn-sm btn-light-primary"
      (click)="handleOnClickAddNew.emit()"
      *ngIf="enableAddNew"
    >
      <ng-icon
        name="heroPlusSmall"
        size="16"
        style="display: contents"
      ></ng-icon>
      {{ "STOCK_PRODUCT.AddNew" | translate }}
    </button>
  </div>
</div>
<div class="separator border-gray-200 mt-3" *ngIf="datePickerForm"></div>
<div class="card-body py-3">
  <div
    class="table-responsive {{
      disableOverflow ? 'min-h-no-ow' : 'overflow-y-a-cat'
    }}"
  >
    <table
      class="table align-middle table-row-dashed table-row-gray-300 {{
        tableVariant
      }} gs-0 gy-3"
    >
      <ng-content></ng-content>
    </table>
  </div>
</div>
