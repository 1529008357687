<div class="splash-screen page-inner" *ngIf="pdfService.loading">
  <img src="./assets/media/logos/default.png" alt="Pluspay" width="100" />
  <span>{{ "APPLY.CreatingCustomContract" | translate }}...</span>
</div>

<div class="row" style="max-width: 950px">
  <div class="col-12 mb-5 position-relative">
    <div class="card card-custom sticky-top-10">
      <div class="card-header">
        <h2 class="my-4 fw-bold ff-roboto">
          {{ "APPLY.CustomContract" | translate }}
        </h2>
      </div>
      <div class="card-body ff-roboto">
        <p class="ff-roboto mb-5">
          * {{ "APPLY.NoPrinterMessage" | translate }}
          <code>'{{ "APPLY.NoPrinter" | translate }}'</code>
          {{ "APPLY.RequestFreeShipping" | translate }}
        </p>

        <div class="separator mb-4"></div>

        <div class="mb-5">
          <p class="ff-roboto mb-2">
            * {{ "APPLY.PluspayContractReview" | translate }}
          </p>
          <p class="ff-roboto mb-2">
            * {{ "APPLY.FreeShippingAfterApproval" | translate }}
          </p>
          <p class="ff-roboto mb-2">
            * {{ "APPLY.DeliveryInstructions" | translate }}
          </p>
        </div>

        <div class="separator mb-4"></div>

        <div class="row">
          <div class="col-md-3 col-12 mb-4">
            <button
              type="button"
              class="btn btn-secondary w-100 fs-8"
              (click)="onExportClick()"
            >
              <ng-container *ngIf="pdfService.loading">
                <span class="indicator-progress" [style.display]="'block'">
                  {{ "APPLY.PleaseWait" | translate }}...
                  <span
                    class="spinner-border spinner-border-sm align-middle ms-2"
                  ></span>
                </span>
              </ng-container>
              <ng-container *ngIf="!pdfService.loading">
                <div class="flex-center-center">
                  <!--<mat-icon
                    fontIcon="sim_card_download"
                    style="margin-right: 10px; font-size: 20px"
                  ></mat-icon>-->
                  <span class="indicator-label">{{
                    "APPLY.DownloadContract" | translate
                  }}</span>
                </div>
              </ng-container>
            </button>
          </div>
          <div class="col-md-3 col-12 mb-4">
            <button
              type="button"
              class="btn btn-secondary w-100 flex-center-center fs-8"
              (click)="onPrintClick()"
            >
              <!--<mat-icon fontIcon="print" style="margin-right: 10px"></mat-icon>-->
              <span>{{ "APPLY.PrintContract" | translate }}</span>
            </button>
          </div>
          <div class="col-md-3 col-12 mb-4">
            <button
              type="button"
              class="btn btn-warning w-100 flex-center-center c-dark fs-8"
            >
              truck
              <span style="margin-left: 10px">{{
                "APPLY.NoPrinter" | translate
              }}</span>
            </button>
          </div>
          <div class="col-md-3 col-12 mb-4">
            <button
              type="button"
              class="btn btn-success w-100 flex-center-center c-dark fs-8"
            >
              <!--<mat-icon
                fontIcon="upload_file"
                style="margin-right: 10px; font-size: 20px"
              ></mat-icon>-->
              <span>{{ "APPLY.UploadSignedContract" | translate }}</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-12">
    <div class="card card-custom gutter-b example example-compact">
      <div class="card-body">
        <div id="printer_content" class="doc-content">
          <app-pluspay-contract-item #page_01 page="1" ip="AAAAAAA">
            <h5 class="fw-bold">1. Taraflar</h5>
            <p class="ps-4">
              İşbu üye İşyeri Sözleşmesi (“<span class="fw-bold">Sözleşme</span
              >”); Cevizli Mahallesi Tugay Yolu Cad. No:69/C Piazza Office Link
              No:222 Maltepe/İstanbul adresinde mukim PLUSPAY FİNANSAL TEKNOLOJİ
              HİZMETLERİ ANONİM ŞİRKETİ (“<span class="fw-bold">PlusPay</span>”)
              ile işbu Sözleşme’nin ekinde yer alan “<span class="fw-bold"
                >PlusPay Başvuru Formu</span
              >” içeriğinde belirtilen adreste mukim ve unvana sahip firma
              (“<span class="fw-bold">üye İşyeri</span>”) arasında
              imzalanmıştır.
            </p>
            <p class="ps-4">
              (PlusPay ve üye İşyeri ayrı ayrı “<span class="fw-bold"
                >Taraf</span
              >” ve birlikte “<span class="fw-bold">Taraflar</span>” olarak
              anılacaklardır.)
            </p>
            <h5 class="fw-bold">2. Tanımlar</h5>
            <table class="contract-table">
              <tr>
                <td colspan="1" rowspan="1">
                  <span>Taraf:</span>
                </td>
                <td colspan="1" rowspan="1">
                  <span>Ayrı olarak PlusPay veya üye’yi,</span>
                </td>
              </tr>
              <tr>
                <td colspan="1" rowspan="1">
                  <span>Taraflar:</span>
                </td>
                <td colspan="1" rowspan="1">
                  <span>Birlikte PlusPay ve üye’yi,</span>
                </td>
              </tr>
              <tr>
                <td colspan="1" rowspan="1">
                  <span>PlusPay:</span>
                </td>
                <td colspan="1" rowspan="1">
                  <p>
                    <span>PlusPay Finansal Teknoloji Hizmetleri A.Ş.’yi,</span>
                  </p>
                </td>
              </tr>
              <tr class="c127">
                <td class="c36" colspan="1" rowspan="1">
                  <p class="c31"><span class="c14">üye:</span></p>
                </td>
                <td class="c54" colspan="1" rowspan="1">
                  <p class="c31">
                    <span class="c2"
                      >Ticari işletmeleri için üye Hesabı oluşturan Platform
                      kullanıcılarını,</span
                    >
                  </p>
                </td>
              </tr>
              <tr class="c116">
                <td class="c36" colspan="1" rowspan="1">
                  <p class="c31"><span class="c14">üye Hesabı:</span></p>
                </td>
                <td class="c54" colspan="1" rowspan="1">
                  <p class="c31">
                    <span class="c2"
                      >Platformdan yararlanmak için oluşturması gereken,
                      bilgilerini güncelleyebildiği ve münhasıran kendisi
                      tarafından kullanılacağını taahhüt ettiği, kullanıcı adı
                      ve şifre ile erişilebilen Platform kimliği,</span
                    >
                  </p>
                </td>
              </tr>
              <tr class="c35">
                <td class="c36" colspan="1" rowspan="1">
                  <p class="c31"><span class="c14">Platform:</span></p>
                </td>
                <td class="c54" colspan="1" rowspan="1">
                  <p class="c31">
                    <span class="c2"
                      >Tüm hakları PlusPay’e ait pluspay.com.tr alan adlı web
                      sitesi, PlusPay isimli mobil uygulama ile PlusPay
                      tarafından Hizmetlerin sunulacağı ve/veya aracılık
                      edileceği her türlü online mecrayı,</span
                    >
                  </p>
                </td>
              </tr>
              <tr class="c86">
                <td class="c36" colspan="1" rowspan="1">
                  <p class="c31"><span class="c14">Sözleşme:</span></p>
                </td>
                <td class="c54" colspan="1" rowspan="1">
                  <p class="c31">
                    <span class="c2"
                      >PlusPay üye iş Sözleşmesi ve eklerini,</span
                    >
                  </p>
                </td>
              </tr>
              <tr class="c56">
                <td class="c36" colspan="1" rowspan="1">
                  <p class="c31"><span class="c14">Hizmetler:</span></p>
                </td>
                <td class="c54" colspan="1" rowspan="1">
                  <p class="c31">
                    <span class="c2"
                      >Sözleşme çerçevesinde üye’nin talepleri ile sınırlı,
                      PlusPay ve/veya PlusPay aracılığıyla iş ortakları
                      tarafından sunulacak Finansal Danışmanlık Hizmetleri,
                      Danışmanlık Hizmetleri, E-Dönüşüm Hizmetleri, ödeme ve
                      Elektronik Para Hizmetleri, Sanal Ofis Hizmetleri, bunlara
                      aracılık hizmetleri, ön Muhasebe Hizmetleri ve PlusPay
                      tarafından kapsamı belirlenecek diğer ürün ve/veya
                      hizmetler,</span
                    >
                  </p>
                </td>
              </tr>
              <tr class="c137">
                <td class="c36" colspan="1" rowspan="1">
                  <p class="c31"><span class="c14">Hizmet Bedeli:</span></p>
                </td>
                <td class="c54" colspan="1" rowspan="1">
                  <p class="c31">
                    <span class="c2"
                      >Platform üzerinden madde 4.3.’e göre PlusPay tarafından
                      yayınlanacak Hizmetlere ilişkin üye tarafından ödenmesi
                      gereken hizmet bedelini,</span
                    >
                  </p>
                </td>
              </tr>
              <tr class="c133">
                <td class="c36" colspan="1" rowspan="1">
                  <p class="c31">
                    <span class="c14">E-Dönüşüm Hizmetleri:</span>
                  </p>
                </td>
                <td class="c54" colspan="1" rowspan="1">
                  <p class="c31">
                    <span class="c2"
                      >PlusPay iş ortakları aracılığıyla, üye ile ilgili iş
                      ortağı arasında imzalanacak sözleşme çerçevesinde
                      sunulacak e-fatura, e-smm, e-arşiv ve diğer e-dönüşüm
                      hizmetlerini,</span
                    >
                  </p>
                </td>
              </tr>
              <tr class="c35">
                <td class="c36" colspan="1" rowspan="1">
                  <p class="c31">
                    <span class="c14">E-imza & E-Mühür:</span>
                  </p>
                </td>
                <td class="c54" colspan="1" rowspan="1">
                  <p class="c31">
                    <span class="c2"
                      >PlusPay iş ortakları aracılığıyla, üye ile ilgili iş
                      ortağı arasında imzalanacak sözleşme ve 5070 sayılı
                      Elektronik imza Kanunu çerçevesinde sunulacak güvenli
                      elektronik imzayı,</span
                    >
                  </p>
                </td>
              </tr>
              <tr class="c86">
                <td class="c36" colspan="1" rowspan="1">
                  <p class="c31">
                    <span class="c14">Danışmanlık Hizmetleri:</span>
                  </p>
                </td>
                <td class="c54" colspan="1" rowspan="1">
                  <p class="c31">
                    <span class="c2"
                      >üye’ye sağlanacak diğer danışmanlık hizmetlerini,</span
                    >
                  </p>
                </td>
              </tr>
              <tr class="c28">
                <td class="c36" colspan="1" rowspan="1">
                  <p class="c42 c83">
                    <span class="c14">Finansal Danışmanlık Hizmetleri:</span>
                  </p>
                </td>
                <td class="c54" colspan="1" rowspan="1">
                  <p class="c31">
                    <span class="c2"
                      >PlusPay iş ortakları aracılığıyla, üye ile ilgili iş
                      ortağı arasında imzalanacak sözleşme çerçevesinde
                      sunulacak şirket kuruluşu, beyanname, muhasebe, vergi
                      hizmetlerini, vb. finansal danışmanlık hizmetleri,</span
                    >
                  </p>
                </td>
              </tr>
              <tr class="c86">
                <td class="c36" colspan="1" rowspan="1">
                  <p class="c31"><span class="c14">KVKK:</span></p>
                </td>
                <td class="c54" colspan="1" rowspan="1">
                  <p class="c31">
                    <span class="c2"
                      >6698 sayılı Kişisel Verilerin Korunması Kanunu’nu,</span
                    >
                  </p>
                </td>
              </tr>
              <tr class="c107">
                <td class="c36" colspan="1" rowspan="1">
                  <p class="c31">
                    <span class="c14">ön Muhasebe Hizmetleri:</span>
                  </p>
                </td>
                <td class="c54" colspan="1" rowspan="1">
                  <p class="c31">
                    <span class="c2"
                      >Platform içerisinde, PlusPay tarafından belirlenen
                      çerçevede sunulacak ön muhasebe fonksiyonlarını,</span
                    >
                  </p>
                </td>
              </tr>
            </table>
          </app-pluspay-contract-item>

          <app-pluspay-contract-item #page_02 page="2" ip="AAAAAAA">
            <table class="contract-table">
              <tr class="c114">
                <td class="c36" colspan="1" rowspan="1">
                  <p class="c83 c42">
                    <span class="c14"
                      >ödeme ve Elektronik Para Hizmetleri:</span
                    >
                  </p>
                </td>
                <td class="c54" colspan="1" rowspan="1">
                  <p class="c31">
                    <span class="c2"
                      >Platform içerisinde, PlusPay’yin iş ortağı 6493 sayılı
                      Kanun veya hizmet sağlayıcının tabi olacağı ülke mevzuatı
                      kapsamında lisanslı ödeme hizmeti sağlayıcısı, elektronik
                      para kuruluşu ve bankalar tarafından, üye ile ilgili iş
                      ortağı arasında imzalanacak sözleşme ve/veya işbu Sözleşme
                      çerçevesinde sunulacak ödeme ve elektronik para hizmeti
                      fonksiyonlarının tamamını veya bir kısmını,</span
                    >
                  </p>
                </td>
              </tr>
              <tr class="c142">
                <td class="c36" colspan="1" rowspan="1">
                  <p class="c31">
                    <span class="c14">6493 Sayılı Kanun:</span>
                  </p>
                </td>
                <td class="c54" colspan="1" rowspan="1">
                  <p class="c31">
                    <span class="c2"
                      >ödeme ve Menkul Kıymet Mutabakat Sistemleri, ödeme
                      Hizmetleri ve Elektronik Para Kuruluşları Hakkında Kanun
                      ve ikincil düzenlemelerini,</span
                    >
                  </p>
                </td>
              </tr>
              <tr class="c91">
                <td class="c36" colspan="1" rowspan="1">
                  <p class="c31">
                    <span class="c14">ödeme ve E-Para Mevzuatı</span>
                  </p>
                </td>
                <td class="c54" colspan="1" rowspan="1">
                  <p class="c31">
                    <span class="c2"
                      >6493 Sayılı Kanun, hizmetin sunulacağı diğer ilgili ülke
                      mevzuatları ve kartlı ödeme sistemleri kurallarını ifade
                      eder.</span
                    >
                  </p>
                </td>
              </tr>
              <tr class="c48">
                <td class="c36" colspan="1" rowspan="1">
                  <p class="c31">
                    <span class="c14">Sanal Ofis Hizmetleri:</span>
                  </p>
                </td>
                <td class="c54" colspan="1" rowspan="1">
                  <p class="c31">
                    <span class="c2"
                      >PlusPay iş ortakları aracılığıyla, üye ile ilgili iş
                      ortağı arasında imzalanacak sözleşme çerçevesinde
                      sunulacak sanal ofis kiralama hizmetlerini,</span
                    >
                  </p>
                </td>
              </tr>
              <tr class="c111">
                <td class="c36" colspan="1" rowspan="1">
                  <p class="c31"><span class="c14">Materyaller:</span></p>
                </td>
                <td class="c54" colspan="1" rowspan="1">
                  <p class="c31">
                    <span class="c2"
                      >Platformun genel görünüm ve tasarımı ile Platformdaki tüm
                      bilgi, metin, görseller ile PlusPay markası, PlusPay ve iş
                      ortaklarına ait logo, ikon, demonstratif, yazılı,
                      elektronik, grafik veya makinede okunabilir şekilde
                      sunulan teknik veriler, bilgisayar yazılımları, uygulanan
                      satış sistemi, iş metodu ve iş modeli de dahil tüm
                      materyalleri,</span
                    >
                  </p>
                </td>
              </tr>
            </table>
            <h5 class="fw-bold mt-4">3. Konu</h5>
            <div class="ps-4">
              <p class="c42 c68">
                <span class="c2"
                  >işbu Sözleşme ile üye’nin talepleri ile sınırlı olarak
                  Hizmetlerin sunulması ve/veya Hizmetlere aracılık edilmesi
                  karşılığında Hizmet Bedellerinin ödenmesine ilişkin Tarafların
                  hak ve yükümlülükleri düzenlenmektedir.</span
                >
              </p>
              <p class="c42 c118">
                <span class="c2"
                  >PlusPay’in, Sözleşme’nin 4. Maddesinde belirlenen hükümler
                  saklı kalmak şartıyla sunmayı taahhüt ettiği hizmetler için
                  gerekli olan teknolojik alt yapıyı tesis etme yükümlülüğü,
                  sınırsız ve eksiksiz bir hizmet taahhüdü anlamına
                  gelmemektedir.</span
                >
              </p>
            </div>
            <h5 class="fw-bold mt-4">4. Genel Hükümler</h5>
            <div class="ps-2">
              <ol class="c37 lst-kix_6gtie1cfpau9-1 start" start="1">
                <li class="c10 li-bullet-0">
                  <span class="c2"
                    >Sözleşme’nin üye tarafından onaylanması, PlusPay tarafından
                    Hizmetlerin verileceği taahhüdü anlamına gelmeyecek olup,
                    üye’nin onayladığı işbu Sözleşme’yi PlusPay’in sebep
                    göstermeksizin reddetme hakkı saklıdır.</span
                  >
                </li>
                <li class="c10 li-bullet-0">
                  <span class="c2"
                    >üye, Platform hesabının aktif edilmesi ile yalnızca
                    Platformun temel özelliklerini kullanabileceğini,
                    Hizmetlerden yararlanabilmek için, talep ettiği Hizmetlere
                    ilişkin talepte bulunması, PlusPay ve/veya ilgili iş
                    ortakları tarafından talep edilecek belge ve bilgileri
                    sağlaması, sağlanacak bilgiler çerçevesinde
                    gerçekleştirilebilecek değerlendirmenin olumlu sonuçlanması,
                    talep edilmesi halinde ilgili iş ortağı ile Hizmetlere
                    ilişkin sözleşme akdetmesi ve Hizmet Bedeli’ni ödemesi
                    gerektiğini kabul ve beyan eder.</span
                  >
                </li>
                <li class="c10 li-bullet-0">
                  <span class="c2"
                    >üye, PlusPay’in Hizmetleri gruplar halinde ve/veya ayrı
                    ayrı sunma hakkına sahip olduğunu, Hizmetleri Platform’da
                    PlusPay tarafından belirlenen şekilde ve çerçevede temin
                    edebileceğini kabul ve beyan eder.</span
                  >
                </li>
                <li class="c10 li-bullet-0">
                  <span class="c2"
                    >üye, Platform’dan yararlanabilmesi için internet bağlantısı
                    ve teknik alt yapı yeterliliğine sahip olması gerektiğini ve
                    bu kriterlerin çağın teknolojik gelişmeleriyle birlikte
                    değişebileceğini kabul ve beyan eder.</span
                  >
                </li>
                <li class="c10 li-bullet-0">
                  <span class="c2"
                    >üye, PlusPay’in Sözleşme çerçevesinde sağlanacak ve/veya
                    aracılık edilecek Hizmetleri sağlayacak iş ortaklarını
                    belirleme veya tek taraflı olarak değiştirme yetkisine sahip
                    olduğunu, bu bağlamda PlusPay tarafından yapılacak bildirim
                    ve yönlendirmelere süresinde ve eksiksiz uyacağını kabul ve
                    beyan eder.</span
                  >
                </li>
              </ol>
            </div>
          </app-pluspay-contract-item>

          <app-pluspay-contract-item #page_03 page="3" ip="AAAAAAA">
            <ol start="6">
              <li class="c10 li-bullet-0">
                <span class="c2"
                  >üye, yalnızca hukuki statüsü gereği mevzuat çerçevesinde
                  sağlanabilecek Hizmetlere ilişkin talepte bulunabileceğini,
                  PlusPay’in ve/veya iş ortaklarının hiçbir gerekçe sunmadan
                  üye’nin Hizmetlerini &nbsp;denetleme ve Hizmet alma talebini
                  kabul etmeme hakkına sahip olduğunu kabul ve beyan eder.</span
                >
              </li>
              <li class="c10 li-bullet-0">
                <span class="c2"
                  >üye, üye Hesabı oluşumu esnasında PlusPay ile paylaşmış
                  olduğu cep telefonu numarasına gönderilecek aktivasyon kodunu
                  yalnızca kendisi tarafından kullanacağını, aktivasyon kodunun
                  ve üye hesabının güvenliğinden bizzat sorumlu olduğunu kabul
                  ve beyan eder.</span
                >
              </li>
              <li class="c10 li-bullet-0">
                <span class="c2"
                  >üye, Sözleşmeyi onaylamasına ve/veya Hizmetleri almasına
                  herhangi bir yasal engel olmadığını kabul ve beyan eder.</span
                >
              </li>
              <li class="c10 li-bullet-0">
                <span class="c2"
                  >üye, Hizmetlere ilişkin soru, şikayet, talep ve önerilerini
                  PlusPay ile paylaşacağını kabul ve beyan eder.</span
                >
              </li>
            </ol>
            <h5 class="fw-bold">5. Üyenin Yükümlülükleri</h5>
            <div class="ps-2">
              <ol class="c37 lst-kix_6gtie1cfpau9-1 start" start="1">
                <li class="c10 li-bullet-0">
                  <span class="c2"
                    >üye, Sözleşme çerçevesinde sağlayacağı belge ve bilgilerin
                    doğru, güncel ve eksiksiz olduğunu, bilgilerde bir
                    değişiklik olması halinde Sözleşme’de belirtilen yöntemlerle
                    gerekli bildirimleri PlusPay ve/veya ilgili PlusPay iş
                    ortağına derhal gerçekleştireceğini kabul, beyan ve taahhüt
                    eder.</span
                  >
                </li>
                <li class="c10 li-bullet-0">
                  <span class="c2"
                    >üye, Hizmetler için PlusPay ve/veya iş ortakları tarafından
                    ek bilgi ve belgelere ihtiyaç olabileceğini, böyle bir
                    durumda talep doğrultusunda süresinde ve eksiksiz olarak
                    bilgi ve belgeleri sağlayacağını kabul ve beyan eder. Talep
                    edilen bilgi ve belgelerin sunulmaması durumunda, PlusPay
                    Hizmetleri askıya alabilecek ya da tamamen
                    durdurabilecektir.
                  </span>
                </li>
                <li class="c10 li-bullet-0">
                  <span class="c2"
                    >üye, Platform ve Hizmetler’den Sözleşme, Platform
                    içerisindeki yönlendirmeler ve kullanım koşullarına, iş
                    ortakları ile imzalayacağı sözleşmelere ve mevzuata uygun
                    olarak yararlanacağını, Platform ve/veya Hizmetler aracılığı
                    ile dolaylı ve/veya doğrudan üçüncü bir kişinin hakkının
                    veya yasal bir düzenlemenin ihlaline sebebiyet vermeyeceğini
                    kabul ve beyan eder.</span
                  >
                </li>
                <li class="c10 li-bullet-0">
                  <span class="c2"
                    >üye, ödeme ve Elektronik Para Hizmetleri çerçevesinde
                    kendisine sunulacak ödeme aracına ilişkin bir güvenlik
                    sorunu yaşaması halinde en kısa sürede PlusPay ile iletişime
                    geçeceğini kabul ve beyan eder.</span
                  >
                </li>
                <li class="c10 li-bullet-0">
                  <span class="c2"
                    >üye, PlusPay iletişim kanalları üzerinden PlusPay ile
                    gerçekleştireceği görüşmelerde kimliğini doğrulama amaçlı
                    PlusPay tarafından ek bilgi ve/veya belge talep
                    edilebileceğini, bilgi ve belgeleri sağlamaması halinde
                    talebinin/işleminin reddedilebileceğini kabul ve beyan eder.
                    Bu doğrulama hiçbir koşul altında PlusPay’e üyeyi doğrulama
                    yükümlülüğü getirmemektedir. üye, PlusPay ile paylaşacağı
                    bilgilerin güvenliğinden, başkaları tarafından kullanılması
                    sebebiyle oluşabilecek zararlardan münhasıran
                    sorumludur.</span
                  >
                </li>
                <li class="c10 li-bullet-0">
                  <span class="c2"
                    >üye, Sözleşme çerçevesinde kendisine sunulacak Hizmetleri
                    üçüncü bir kişi yararına kullandırmayacağını, ödeme ve
                    Elektronik Para Hizmetleri üzerinden haksız menfaat veya
                    ödeme ve E-Para Mevzuatı ile kendisine yazılı veya sözlü
                    biçimde bildirilecek kurulum ve kullanım talimatlarına
                    aykırı işlem gerçekleştirmeyeceğini kabul ve beyan
                    eder.</span
                  >
                </li>
                <li class="c10 li-bullet-0">
                  <span class="c2"
                    >üye, Platformun yüklü olduğu ve/veya kullanıldığı cihazın
                    ve/veya işletim sisteminin güvenliğinden ve kullanımından
                    münhasıran sorumlu olduğunu, bu ve/veya bağlantılı
                    nedenlerle doğabilecek finansal, parasal, maddi, manevi vb.
                    hiçbir zarardan PlusPay’in sorumluluğu olmadığını kabul ve
                    beyan eder.</span
                  >
                </li>
                <li class="c10 li-bullet-0">
                  <span class="c2"
                    >üye, üye Hesabı ile gerçekleştirilecek işlemlerden ve üye
                    Hesabı bilgilerinin güvenliğinden münhasıran sorumlu
                    olduğunu kabul ve beyan eder.</span
                  >
                </li>
                <li class="c10 li-bullet-0">
                  <span class="c2"
                    >üye, üye Hesabına girişte kullandığı araç ve/veya
                    bilgilerin kaybolması ve/veya değişmesi halinde PlusPay
                    tarafından belirlenecek yöntemlerle kimliğini ispat edecek
                    ve yeni üye Hesabı giriş bilgilerini belirleyecektir.</span
                  >
                </li>
              </ol>
            </div>
          </app-pluspay-contract-item>

          <app-pluspay-contract-item #page_04 page="4" ip="AAAAAAA">
            <ol class="c37 lst-kix_luwpgxk3urkg-1 start" start="10">
              <li class="c10 li-bullet-0">
                <span class="c2"
                  >üye, PlusPay’in herhangi bir zamanda Platform’un çalışmasını
                  teknik nedenler, güvenlik vb. sebeplerle geçici bir süre
                  askıya alma ve/veya tamamen durdurma hakkı olduğunu kabul ve
                  beyan eder.</span
                >
              </li>
              <li class="c10 li-bullet-0">
                <span class="c2"
                  >üye, PlusPay tarafından kendisi ve/veya iş ortaklarına
                  ilişkin sözleşmelerin ıslak imzalı nüshasını talep etme
                  hakkına sahip olduğunu, PlusPay tarafından belirlenecek
                  yöntemlerle talep edilecek sözleşmelerin ıslak imzalı
                  suretlerinin kimliğini doğrulamak suretiyle gerçekleştirmekle
                  yükümlü olduğunu kabul eder. PlusPay, anılan sürece ilişkin
                  üçüncü taraf hizmet sağlayıcı kullanma hakkına sahiptir.</span
                >
              </li>
              <li class="c10 li-bullet-0">
                <span class="c2"
                  >üye, madde 5.11 çerçevesinde PlusPay tarafından belirlenen
                  sürece uymaması halinde PlusPay’in Sözleşme çerçevesindeki
                  yükümlülüklerinin sona ereceğini, anılan süreci tekrar
                  başlatmaktan münhasıran kendisinin sorumlu olduğunu kabul ve
                  beyan eder.</span
                >
              </li>
            </ol>
            <h5 class="fw-bold">6. Hizmet Bedeli ve ödeme</h5>
            <ol class="c37 lst-kix_gaormjt53921-0 start" start="1">
              <li class="c10 li-bullet-0">
                <span class="c2"
                  >üye, Hizmetler’den yararlanmak için PlusPay tarafından
                  Platform’da yayınlanacak Hizmet Bedeli’ni, PlusPay tarafından
                  belirlenecek ödeme araçları ve yöntemleri ile süresi içinde
                  eksiksiz olarak ödeyeceğini kabul ve beyan eder. Hizmetlere
                  ilişkin aksi PlusPay tarafından yazılı olarak belirtilmediği
                  sürece iş ortaklarının hiçbir tahsil yetkisi bulunmamakta
                  olup, üye’nin iş ortağına ödeme yapması Hizmet Bedeli’ni ödeme
                  yükümlülüğünü ortadan kaldırmayacaktır.</span
                >
              </li>
              <li class="c10 li-bullet-0">
                <span class="c2"
                  >üye, Hizmetlere ilişkin PlusPay tarafından belirlenecek
                  dönemlerle hizmetin ücretlendirileceğini, bu dönemler
                  içerisinde Hizmetlerin iptalinin talebi halinde PlusPay’in
                  ilgili dönemin ücretinin tamamına hak kazanacağını, iptalin
                  takip eden dönem için geçerli olacağını kabul ve beyan eder.
                  Hizmete ilişkin bedelin PlusPay tarafından tahsil edilmediği
                  durumlarda üye, ilgili PlusPay iş ortağı ile akdedeceği
                  sözleşmedeki ödemeye ilişkin hükümlerin geçerli olacağını
                  kabul ve beyan eder.</span
                >
              </li>
              <li class="c10 li-bullet-0">
                <span class="c2"
                  >üye, Hizmet Bedeli’ni ödememesi ve/veya geciktirmesi halinde
                  PlusPay’in Platform ve/veya Hizmetlere erişimini engelleme
                  hakkı olduğunu kabul ve beyan eder.</span
                >
              </li>
              <li class="c10 li-bullet-0">
                <span class="c2"
                  >üye, Hizmet Bedeli’ni süresinde ödememesi halinde ticari
                  temerrüt faizine ek olarak günlük %1 gecikme bedeli
                  ödeyeceğini kabul ve beyan eder.</span
                >
              </li>
              <li class="c10 li-bullet-0">
                <span class="c2"
                  >üye, aksi PlusPay tarafından yazılı olarak belirtilmediği
                  sürece Platform üzerinden satın alma gerçekleştireceği
                  Hizmetlere ilişkin faturanın PlusPay tarafından
                  düzenleneceğini kabul ve beyan eder.</span
                >
              </li>
              <li class="c10 li-bullet-0">
                <span class="c2"
                  >üye, Sözleşme çerçevesinde ödemekle yükümlü olduğu bedellerin
                  ödeme ve Elektronik Para Hizmetleri sunan iş ortağı ve/veya
                  PlusPay nezdindeki hak ve alacaklarından tahsil edilmesine
                  rızası olduğunu kabul ve beyan eder.</span
                >
              </li>
              <li class="c10 li-bullet-0">
                <span class="c2"
                  >üye, Hizmetleri ay içerisinde iptal etmek istemesi halinde
                  ilgili aya ilişkin Hizmet Bedeli’nin tamamını, bir aydan uzun
                  süreli taahhütlü olarak satın aldığı hizmetleri taahhüdünden
                  önce iptal etmek istemesi halinde Hizmetleri kullandığı aylara
                  ilişkin taahhüt sebebiyle PlusPay tarafından gerçekleştirilen
                  indirim tutarını ödemekle yükümlü olduğunu kabul ve beyan
                  eder.</span
                >
              </li>
              <li class="c10 li-bullet-0">
                <span class="c2"
                  >üye, PlusPay PCI DSS (Payment Card Industry Data Security
                  Standard) sertifikalı iş ortağı aracılığıyla sunulacak kayıtlı
                  kart ile ödeme yönteminden faydalanarak anlaşmalı kurumlardaki
                  fatura ve Sözleşmeden doğacak herhangi bir yükümlülüğüne
                  ilişkin ödemelerini gerçekleştirebilecektir. PlusPay bu
                  servise ilişkin iş ortağını değiştirme ve üye’ye sunmama
                  hakkına sahiptir.</span
                >
              </li>
            </ol>
          </app-pluspay-contract-item>

          <app-pluspay-contract-item #page_05 page="5" ip="AAAAAAA">
            <h5>9. Kayıtlı kart ile ödeme yöntemini kullanması halinde üye;</h5>
            <ol class="c37 lst-kix_luwpgxk3urkg-2 start" start="1">
              <li class="c61 c42 li-bullet-0">
                <span class="c2"
                  >üyelik süresince ödemelerinin kaydettiği karttan belirlenecek
                  periyotlarla otomatik olarak tahsil edileceğini,</span
                >
              </li>
              <li class="c61 c42 li-bullet-0">
                <span class="c2"
                  >Belirleyeceği ve kullanacağı tüm şifrelerin güvenliğinden ve
                  gizliliğinden münhasıran sorumlu olduğunu,</span
                >
              </li>
              <li class="c61 c42 li-bullet-0">
                <span class="c2"
                  >Kaydedeceği kartın kendisine ait olduğunu,</span
                >
              </li>
              <li class="c61 c42 li-bullet-0">
                <span class="c2"
                  >Kaydedeceği kartın yeterli işlem limitine sahip olmasından ve
                  ilgili kanal üzerinden işleme açık olmasından münhasıran
                  sorumlu olduğunu,</span
                >
              </li>
              <li class="c61 c42 li-bullet-0">
                <span class="c2"
                  >Talep halinde hizmeti sunacak PlusPay iş ortağının hizmet
                  sözleşmesini onaylaması gerektiğini bildiğini,</span
                >
              </li>
              <li class="c61 c42 li-bullet-0">
                <span class="c2"
                  >Kayıtlı kart ile ödeme yöntemini yalnızca PlusPay tarafından
                  sunulma amacına ve kart hamilinin iradesine uygun olarak
                  kullanacağını</span
                >
              </li>
              <li class="c61 c42 li-bullet-0">
                <span class="c2"
                  >PlusPay’in veya iş ortağının işlem akışında dilediği zaman
                  değişiklik yapma, ek bilgi talep etme ve üye’nin güvenliği
                  için ek önlemler (şifre vb.) getirme hakkı olduğunu,</span
                >
              </li>
              <li class="c61 c42 li-bullet-0">
                <span class="c2"
                  >PlusPay’in dilediği zaman herhangi bir bildirim ve tazmin
                  yükümlülüğü olmadan kayıtlı kart ile ödeme yöntemine kısmen
                  ve/veya tamamen erişimi engelleme ve/veya kayıtlı kart ile
                  ödeme yöntemini kısmen ve/veya tamamen sona erdirme hakkına
                  sahip olduğunu,</span
                >
              </li>
              <li class="c61 c42 li-bullet-0">
                <span class="c2"
                  >Kayıtlı kart ile ödeme yöntemini Sözleşme ve yürürlükteki
                  mevzuata uygun olarak kullanacağını, aksi durumda ve/veya bu
                  yükümlülüklere herhangi bir aykırılık şüphesi meydana
                  geldiğinde kayıtlı kart ile ödeme yönteminin PlusPay ve/veya
                  iş ortağı tarafından askıya alınabileceğini ve/veya
                  Sözleşmenin derhal tek taraflı feshedilebileceğini,</span
                >
              </li>
              <li class="c61 c42 li-bullet-0">
                <span class="c2"
                  >PlusPay’in, kar kaybı, şerefiye ve itibar kaybı, ikame ürün
                  ve hizmet temini için yapılan harcama dâhil ancak bununla
                  sınırlı olmaksızın, kayıtlı kart ile ödeme yönteminin
                  kullanımı neticesinde meydana gelebilecek hiçbir dolaylı,
                  özel, arızi, cezai zarardan sorumluluğu bulunmadığını,</span
                >
              </li>
              <li class="c42 c61 li-bullet-0">
                <span class="c2"
                  >Sözleşme ve/veya mevzuata aykırılığı neticesinde PlusPay
                  nezdinde meydana gelen tüm zararları tazmin edeceğini kabul ve
                  beyan eder.</span
                >
              </li>
            </ol>
            <h5 class="fw-bold">7. Sözleşmenin Süresi ve Feshi</h5>
            <div class="ps-2">
              <ol class="c37 lst-kix_rq7pka29pdcs-0 start" start="1">
                <li class="c10 li-bullet-0">
                  <span class="c2"
                    >işbu Sözleşme, üye’nin onaylaması ile yürürlüğe girecek
                    olup, Sözleşme’de belirlenen koşullarda sona erdirilmemesi
                    halinde süresiz olarak yürürlükte kalacaktır.</span
                  >
                </li>
                <li class="c10 li-bullet-0">
                  <span class="c32"
                    >Taraflardan her biri, 1 (bir) ay önceden haber vermek
                    kaydıyla işbu Sözleşme’yi tek taraflı ve tazminatsız olarak </span
                  ><span class="c32">feshedebilecektir</span
                  ><span class="c2"
                    >. Tarafların fesih tarihine dek kesinleşen hak ve
                    alacakları saklıdır.
                  </span>
                </li>
                <li class="c10 li-bullet-0">
                  <span class="c2"
                    >Taraflar'dan birinin Sözleşme'deki yükümlülüğünü ihlal
                    etmesi ve diğer Tarafça ihlalin giderilmesi için yapılacak
                    bildirim tarihinden itibaren 15 (on beş) gün içerisinde
                    ihlalin giderilmemesi halinde hakkı ihlal edilen Taraf,
                    yazılı bildirim ile Sözleşme'yi derhal fesih hakkına sahip
                    olacaktır.</span
                  >
                </li>
                <li class="c10 li-bullet-0">
                  <span class="c2"
                    >üye, Sözleşmedeki yükümlülüklerini yerine getirmediği
                    takdirde PlusPay’in Platforma ve/veya Hizmetlere erişim
                    hakkını kısmen veya tamamen sınırlandırabileceği veya
                    kaldırabileceğini kabul ve beyan eder. üye’nin bu madde
                    bağlamında Hizmetlerden ve/veya Platformdan yararlanamaması
                    Hizmet Bedeli ödeme yükümlülüğünü ortadan
                    kaldırmamaktadır.</span
                  >
                </li>
                <li class="c10 li-bullet-0">
                  <span class="c2"
                    >PlusPay’in Hizmetlerin sağlanmasına aracılık eden iş
                    ortakları ile iş ilişkisinin herhangi bir nedenle sona
                    ermesi/erdirilmesi/iptal olması/hükümsüz kalması/askıya
                    alınması halinde PlusPay, söz konusu durumun gerçekleşmesini
                    takiben iş Ortağı’na yazılı olarak bildirecektir ve işbu
                    Sözleşme kendiliğinden sona erecektir. Böyle bir durumda
                    üye, PlusPay’e karşı herhangi hak ve/veya alacak talebinde
                    bulunmayacağını kabul ve beyan eder.</span
                  >
                </li>
              </ol>
            </div>
          </app-pluspay-contract-item>

          <app-pluspay-contract-item #page_06 page="6" ip="AAAAAAA">
            <ol class="c37 lst-kix_rq7pka29pdcs-0 start" start="6">
              <li class="c10 li-bullet-0">
                <span class="c2"
                  >PlusPay, üye’nin Platfomu belirli bir süre boyunca
                  kullanmaması, ticari hayatını sonlandırması veya
                  faaliyetlerini değiştirmesi halinde Sözleşmeyi tek taraflı
                  fesih hakkına sahip olacaktır.</span
                >
              </li>
              <li class="c10 li-bullet-0">
                <span class="c2"
                  >PlusPay, üye işlemlerinin güvenlik şüphesi yaratması halinde,
                  üye’nin Platform ve/veya Hizmetler üzerinden haksız kazanç
                  elde ettiğini ve/veya yapılan işlemlerin dolandırıcılık,
                  sahtekârlık veya hile şüphesi taşıdığını tespit etmesi veya
                  böyle bir durumdan şüphe duyması halinde, üye’nin Platforma
                  ve/veya Hizmetlere erişimini geçici bir süre askıya alabilir
                  veya tamamen durdurabilir. Bu tür durumlarda PlusPay’in her
                  türlü zararını tazmin ve hukuki/cezai yollara başvurma hakkı
                  saklıdır.
                </span>
              </li>
              <li class="c10 li-bullet-0">
                <span class="c2"
                  >PlusPay, iş ortağı ödeme hizmeti sağlayıcısı, banka ve/veya
                  herhangi bir kamu otoritesinden Sözleşme’nin devam etmesi
                  ve/veya Hizmetlerin verilmesini engelleyebilecek veya olumsuz
                  etki edecek bir bildirim olması halinde ilgili üye’nin
                  Platforma ve/veya Hizmetlere erişimini geçici bir süre askıya
                  alabilir veya tamamen durdurabilir.</span
                >
              </li>
              <li class="c10 li-bullet-0">
                <span class="c2"
                  >üye, madde 7.6 ve/veya 7.7. çerçevesinde doğabilecek tüm
                  zararlardan münhasıran sorumlu olduğunu kabul ve beyan
                  eder.</span
                >
              </li>
            </ol>
            <h5 class="fw-bold">8. Fikri Sınai Haklar</h5>
            <ol class="c37 lst-kix_uc5odn3hkxf-0 start" start="1">
              <li class="c10 li-bullet-0">
                <span class="c2"
                  >üye, işbu Sözleşmede açıkça izin verilen durumlar ve amaçlar
                  haricinde, Platformu ve Materyalleri kullanmayacak, üçüncü
                  kişilerin erişimine imkan tanımayacak, kopyalamayacak,
                  uyarlamayacak, tadil etmeyecek, işleme eserlerini
                  yaratmayacak, dağıtmayacak, lisansını vermeyecek, satmayacak,
                  devretmeyecek, kamuya açık bir şekilde sergilemeyecek,
                  göstermeyecek, kamuya açık bir şekilde icra etmeyecek,
                  yayınlamayacak, iletmeyecek veya başka bir şekilde bunlardan
                  faydalanmayacaktır.</span
                >
              </li>
              <li class="c10 li-bullet-0">
                <span class="c2"
                  >işbu Sözleşme tahtında açıkça tanınan kullanım hakları
                  haricinde; üye’ye başka herhangi bir lisans ya da hak, zımni
                  olarak ve yahut başka bir şekilde verilmemektedir.</span
                >
              </li>
              <li class="c10 li-bullet-0">
                <span class="c2"
                  >Taraflar, aralarında bayi, temsilci veya acente ilişkisi
                  olmadığını, bu tür izlenimler yaratacak girişimlerde
                  bulunmayacağını kabul ve taahhüt eder.</span
                >
              </li>
              <li class="c10 li-bullet-0">
                <span class="c2"
                  >üye, marka, ticari unvan ve logosunun PlusPay tarafından
                  reklam, tanıtım ve sair amaçlar ile Sözleşme kapsamındaki
                  faaliyetler ile sınırlı olarak kullanılmasına izin verdiğini
                  kabul ve beyan eder.</span
                >
              </li>
            </ol>
            <h5 class="fw-bold">9. Kişisel Veriler</h5>
            <ol class="c37 lst-kix_n0ftcr20m9aq-0 start" start="1">
              <li class="c10 li-bullet-0">
                <span class="c2"
                  >üye, Platforma üyelik başvurusunun incelenmesi, Sözleşme
                  ilişkisinin kurulabilmesi ve Hizmetlerin sağlanabilmesi
                  amacıyla şirket yetkililerinin kişisel verilerinin Aydınlatma
                  Metni kapsamında işlenebileceğini ve PlusPay’in ilgili iş
                  ortakları ile paylaşılabileceğini kabul ve beyan eder.</span
                >
              </li>
              <li class="c10 li-bullet-0">
                <span class="c2"
                  >PlusPay, üye tarafından Sözleşme çerçevesinde sağlanan
                  kişisel verileri Aydınlatma Metninde yer alan amaçla
                  işleyeceğini ve yasal mevzuatın izin verdiği süre ile muhafaza
                  edeceğini kabul ve beyan eder.</span
                >
              </li>
            </ol>
            <h5 class="fw-bold">10. Gizlilik</h5>
            <div class="ps-2">
              <ol start="1">
                <li class="c10 li-bullet-0">
                  <span class="c2"
                    >işbu Sözleşme kapsamında, Taraflarca birbirlerine, işbu
                    Sözleşme çerçevesinde yazılı, sözlü, elektronik formatta
                    veya sair şekillerde açıklanan, ifşa edilen, temin edilen
                    veya erişim olanağı verilen, Taraflar'a, çalışanlarına,
                    yöneticilerine, tedarikçilerine, iş ortaklarına veya
                    müşterilerine ait, her türlü ticari, teknik, hukuki, mali
                    bilgi “Gizli Bilgi” olarak kabul edilecektir.</span
                  >
                </li>
                <li class="c10 li-bullet-0">
                  <span class="c2"
                    >Taraflar, Gizli Bilgi'yi yalnızca veriliş amacına uygun ve
                    bu amaçla sınırlı olarak kullanacak ve ilgili Tarafın
                    önceden vereceği yazılı izni olmaksızın Gizli Bilgileri
                    hiçbir gerekçe ile kısmen veya tamamen üçüncü şahısların
                    bilgisine sunmayacak veya herhangi bir şekilde ifşa
                    etmeyecektir.</span
                  >
                </li>
              </ol>
            </div>
          </app-pluspay-contract-item>

          <app-pluspay-contract-item #page_07 page="7" ip="AAAAAAA">
            <ol class="c37 lst-kix_5fiqjyacvn1t-0 start" start="3">
              <li class="c10 li-bullet-0">
                <span class="c2"
                  >Taraflar, Gizli Bilgiyi ancak iş gereği bu Gizli Bilgiyi
                  öğrenmesi gereken personeline ve işbu Sözleşmenin ifası için
                  iş birliği yaptığı üçüncü kişilere ifşa eden Taraf'ın ve/veya
                  ilgili kişinin açık yazılı onayı ile verebilecektir. Böyle bir
                  durumda Gizli Bilginin gizli olduğu konusunda personelini ve
                  üçüncü kişileri uyaracak ve onların Gizli Bilginin gizli
                  tutulması ve korunması için gerekli her türlü önlemi
                  almalarını sağlayacaktır.</span
                >
              </li>
              <li class="c10 li-bullet-0">
                <span class="c2"
                  >Gizli Bilgilerin yasal düzenlemeler ve/veya mahkeme
                  kararları, idari veya düzenleyici makamların talepleri
                  gereğince ilgili merci ve makamlara açıklanması gerektiği
                  hallerde, Taraflar açıklama öncesinde diğer Taraf'a yazılı
                  bildirimde bulunmak şartıyla yasal sınırlar ve ilgili talep
                  çerçevesinde gerekli bilgiyi yalnızca açıklama yapılması
                  gereken merci veya makama açıklayabilecektir.</span
                >
              </li>
            </ol>
            <div class="fw-bold mb-3">11. Sorumluluk</div>
            <ol class="c37 lst-kix_fnzhr4p3k7eg-0 start" start="1">
              <li class="c68 c78 c42 li-bullet-0">
                <span class="c2"
                  >üye, Finansal Danışmanlık Hizmetleri, E-Dönüşüm Hizmetleri,
                  ödeme ve Elektronik Para Hizmetleri, Ofis Hizmetleri’ne
                  ilişkin PlusPay’in aracılık ve sunacağını taahhüt ettiği
                  teknik altyapı hizmeti dışında hiçbir sorumluluğu
                  bulunmadığını, anılan hizmetlere ilişkin münhasıran üye’nin
                  sözleşme imzaladığı iş ortağının sorumlu olduğunu kabul ve
                  beyan eder.</span
                >
              </li>
            </ol>

            <ol class="c37 lst-kix_fnzhr4p3k7eg-0 start" start="1">
              <li class="c68 c78 c42 li-bullet-0">
                <span class="c2"
                  >üye, PlusPay’in Sözleşme çerçevesinde sağlayacağı hizmetlere
                  ilişkin her türlü zarar tazmin sorumluluğunun, Sözleşme
                  çerçevesinde alacağı 1 (bir) yıllık hizmet bedeli ile sınırlı
                  olacağını kabul ve beyan eder.</span
                >
              </li>
              <li class="c68 c78 c42 li-bullet-0">
                <span class="c2"
                  >üye, eylemleri nedeniyle PlusPay nezdinde meydana gelecek
                  zararları, PlusPay’e ödeme ve Elektronik Para Hizmetlerini
                  sağlayacak PlusPay ve/veya PlusPay iş ortağı nezdindeki hak ve
                  alacaklardan mahsup yetkisi verdiğini kabul ve beyan
                  eder.</span
                >
              </li>
              <li class="c68 c42 c78 li-bullet-0">
                <span class="c2"
                  >üye, madde 4.5 çerçevesinde iş ortaklarının belirleme
                  yetkisinin Hizmetlere ve/veya üye’nin iş ortağı ile ikili
                  ilişkileri bakımından PlusPay’e herhangi bir sorumluluk
                  yüklemeyeceğini kabul ve beyan eder.</span
                >
              </li>
              <li class="c68 c78 c42 li-bullet-0">
                <span class="c2"
                  >PlusPay, Platformda yer vereceği bağlantılar aracılığı ile
                  üye’nin başkaca web sitelerine veya mobil uygulamalara
                  erişimini sağlayabilir. Böyle bir durumda, üye’nin geçiş
                  yapacağı sitelerin içeriğinden dolayı PlusPay’in herhangi bir
                  sorumluluğu bulunmamaktadır.</span
                >
              </li>
            </ol>
            <h5 class="fw-bold">12. Muhtelif Hükümler</h5>
            <ol start="1">
              <li class="c16 li-bullet-0">
                <span class="fw-bold">Tebligat: </span
                ><span class="c2"
                  >Türk Ticaret Kanunu’nun 18/3 fıkrasındaki haller saklı kalmak
                  kaydıyla, işbu Sözleşme'deki iş süreçlerine ilişkin olarak
                  PlusPay tarafından yapılacak her türlü talep ve diğer
                  bildirimler Platform aracılığıyla ve/veya üye’nin PlusPay’e
                  bildireceği en güncel e-posta adresine yapılmak suretiyle; üye
                  tarafından PlusPay’in 2. madde belirtilen adresine iadeli
                  taahhütlü posta ile yazılı olarak, Noter kanalı ile ya da KEP
                  adresi üzerinden yapılacaktır. Taraflar, yazılı olarak adres
                  değişikliğini bildirmediği takdirde Tarafların son
                  bildirdikleri adresleri geçerli olacaktır. Yazılı olarak
                  yapılmayan uyarı ve talepler nedeniyle işbu Sözleşme konusu
                  Hizmetlere ilişkin meydana gelebilecek aksaklıklardan ve
                  gecikmelerden PlusPay sorumlu olmayacaktır.</span
                >
              </li>
              <li class="c16 li-bullet-0">
                <span class="fw-bold">Mücbir Sebep: </span
                ><span class="c2"
                  >işbu Sözleşme’nin imzalandığı tarihte var olmayan ve
                  öngörülemeyen, Taraflar’ın veya tek bir Taraf’ın çalışma
                  imkânlarını kısmen veya tamamen, geçici veya daimi olarak
                  durduracak şekilde ve derecede meydana gelen yargı,
                  düzenleyici ve denetleyici kurum veya idare kararı, beşeri ve
                  doğal afetler, salgın hastalık, harp, seferberlik, yangın,
                  grev, lokavt vb. gibi tarafların kontrolü haricinde zuhur eden
                  haller mücbir sebep sayılır. Mücbir sebep dolayısıyla
                  Sözleşmede belirlenmiş olan yükümlülüklerini yerine
                  getiremeyecek veya yerine getirmekte gecikecek olan taraf,
                  zorunlu nedenin ortaya çıkmasının akabinde durumu en geç 5
                  (beş) gün içerisinde diğer tarafa yazılı olarak bildirecektir.
                  Mücbir sebep durumunun 30 (otuz) gün devam etmesi halinde
                  Taraflar bir araya gelerek, Sözleşme’nin devam, askıya alınma,
                  fesih veya başka bir şekilde tasfiye şekillerini müzakere
                  edeceklerdir. Taraflar’ın bu süre içersinde bir anlaşmaya
                  varamaması halinde, Taraflar’dan herhangi biri Sözleşme’yi tek
                  taraflı olarak feshetmek hakkına sahiptir. Bu durumdaki sona
                  erdirme neticesinde, Taraflar’ın fesih tarihine kadar yerine
                  getirdiği edimler dışında Sözleşme’nin ifa edilmemiş kısmı ile
                  ilgili olarak hiçbir nam ve ad altında herhangi bir talepte
                  bulunmayacağı gibi; birbirlerine karşı hiçbir tazmin
                  yükümlülüğü doğmayacaktır</span
                >
              </li>
            </ol>
          </app-pluspay-contract-item>

          <app-pluspay-contract-item #page_08 page="8" ip="AAAAAAA">
            <ol class="c37 lst-kix_yxdnnj6w99e-1 start" start="3">
              <li class="c16 li-bullet-0">
                <span class="fw-bold">Sözleşme Değişiklikleri: </span
                ><span class="c2"
                  >Taraflar, PlusPay’in Sözleşmede tek taraflı bildirimsiz
                  değişiklik gerçekleştirebileceğini ve değişikliğin
                  gerçekleştiği anda uygulanacağını kabul ve beyan ederler.
                  Taraflar, yasal düzenlemelerde meydana gelecek değişikliklerin
                  ve/veya ilgili kamu otoritelerinin karar ve/veya
                  bildirimlerinin Sözleşmeye derhal uygulanacağını kabul ve
                  beyan ederler.</span
                >
              </li>
            </ol>
            <ol class="c37 lst-kix_19hy1qyp1hdo-1 start" start="4">
              <li class="c16 li-bullet-0">
                <span class="fw-bold">Delil: </span><span class="c32">üye</span
                ><span class="c2"
                  >, Sözleşme'den doğabilecek ihtilaflarda, usulüne uygun
                  tutulmuş PlusPay’e ait defter ve kayıtlarının, bilgisayar
                  kayıtlarının, veri tabanında ve sunucularında tuttuğu
                  elektronik ve sistem kayıtlarının, ticari kayıtların, faks
                  mesajlarının, anlık mesajlaşma uygulamaları yazışmalarının,
                  e-maillerinin, bağlayıcı, kesin ve münhasır delil teşkil
                  edeceğini ve bunların kesin delil sözleşmesi niteliğinde
                  olduğunu kabul, beyan ve taahhüt eder.</span
                >
              </li>
              <li class="c16 li-bullet-0">
                <span class="fw-bold">Feragat: </span
                ><span class="c2"
                  >işbu Sözleşme’den doğan herhangi bir hakkın kullanılmamış
                  olması veya karşı Taraftan herhangi bir edimi yerine getirmesi
                  talep edilmemiş olması o haktan feragat edildiği ve ileride bu
                  edimin ifasının istenemeyeceği anlamına gelmeyecektir.</span
                >
              </li>
              <li class="c16 li-bullet-0">
                <span class="fw-bold">Sözleşme’nin Bütünlüğü: </span
                ><span class="c2"
                  >işbu Sözleşme’ye halel gelmeksizin, işbu Sözleşme ve işbu
                  Sözleşme’de atıf yapılmış olup Sözleşme uyarınca akdedilecek
                  ve ifa edilecek her türlü anlaşma, ve/veya evrak dâhil tüm
                  belgeler, işbu Sözleşme konusu kapsamında tanzim edilmiş
                  Taraflar arasında nihai, tam ve münhasır olan anlaşmayı teşkil
                  etmekte ve Taraflar arasında bu minvalde önceden yapılmış her
                  türlü düzenleme, anlaşma ve yazılı veya sözlü sözleşmenin
                  yerine geçmektedir.</span
                >
              </li>
            </ol>
            <ol start="7">
              <li class="c16 li-bullet-0">
                <span class="fw-bold">Devir: </span
                ><span class="c2"
                  >üye, Sözleşme’yi ve Sözleşme ile sahip olduğu hak, alacak ve
                  yükümlülüklerini tamamen ve/veya kısmen hiçbir şekil ve
                  koşulda 3. kişilere devir ve temlik edemez. PlusPay,
                  Sözleşme’yi, üye Hesabını ve Sözleşme ile sahip olduğu hak,
                  alacak ve yükümlülüklerini tamamen ve/veya kısmen devretme
                  hakkına sahiptir.</span
                >
              </li>
            </ol>
            <ol class="c37 lst-kix_19hy1qyp1hdo-1 start" start="8">
              <li class="c16 li-bullet-0">
                <span class="fw-bold">Yetki: </span
                ><span class="c2"
                  >işbu Sözleşme'den dolayı doğacak her türlü uyuşmazlıkta
                  istanbul Anadolu Mahkemeleri ve icra Daireleri
                  yetkilidir.</span
                >
              </li>
              <li class="c16 li-bullet-0">
                <span class="fw-bold">Sözleşme Ekleri: </span>
                <span class="c32">PlusPay</span>
                <span class="c32"
                  >tarafından Platformda yayınlanacak kullanım koşulları,
                  bildirimler, üye’nin PlusPay iş ortakları ile Hizmetlere
                  ilişkin imzalayacağı sözleşmeler işbu Sözleşme’nin ayrılmaz
                  birer ekidir.<br /><br />12 (oniki) madde ve eklerden oluşan
                  üye iş Yeri Sözleşmesi
                </span>
                <span class="fw-bold c23">{{ doc.tarihsaat }}</span>
                <span class="c2"
                  >tarihinde 1(bir) asıl olarak düzenlenmiş ve taraflarca kabul
                  ve imza olmuştur. Asıl nüsha PlusPay’de kalacak, bir örneği
                  üye işyerine temin edilecektir. Tarafların imza sirkülerleri
                  ektedir.</span
                >
              </li>
            </ol>
            <div class="row mt-12">
              <div class="col-6">
                <p class="c58 c42">
                  <span class="fw-bold">PlusPay</span>
                </p>
                <p class="c58 c42">
                  <span class="c32"
                    >PlusPay Finansal Teknoloji Hizmetleri A.Ş.
                  </span>
                </p>
              </div>
              <div class="col-6">
                <p class="c58 c42">
                  <span class="fw-bold">Üye İş Yeri</span>
                </p>
                <p class="c58 c42">
                  <span class="c32">ünvan :</span>
                  <span class="c15 c30 c23 c32 c80">{{ doc.unvan }}</span>
                </p>
                <p class="c58 c42">
                  <span class="c32">Vergi No :</span
                  ><span class="c15 c80 c30 c23 c32">{{ doc.vergi_no }}</span>
                </p>
                <p class="c58 c42">
                  <span class="c32">Yetkili Kişi : </span>
                  <span class="c15 c80 c30 c23 c32">{{ doc.yetkili }}</span>
                </p>
                <p class="c58 c42">
                  <span class="c32">Yetkili TC Kimlik : </span>
                  <span class="c15 c80 c30 c23 c32">{{ doc.yetkili_tc }}</span>
                </p>
              </div>
            </div>
          </app-pluspay-contract-item>

          <app-pluspay-contract-item #page_09 page="9" ip="AAAAAAA">
            <h4 class="fw-bold">13. Firma Bilgileri</h4>
            <table class="contract-table">
              <tr class="c41">
                <td class="c47" colspan="1" rowspan="1">
                  <p class="c6"><span class="c14">Marka Kodu</span></p>
                </td>
                <td class="c52" colspan="1" rowspan="1">
                  <p class="c6">
                    <span class="c15 fw-bold c30 c23">{{ doc.marka_id }}</span>
                  </p>
                </td>
                <td class="c26" colspan="1" rowspan="1">
                  <p class="c6"><span class="c14">Firma Kodu</span></p>
                </td>
                <td class="c5" colspan="1" rowspan="1">
                  <p class="c6">
                    <span class="c15 fw-bold c30 c23">{{ doc.firma_id }}</span>
                  </p>
                </td>
              </tr>
              <tr class="c79">
                <td class="c47" colspan="1" rowspan="1">
                  <p class="c6"><span class="c14">Firma ünvanı</span></p>
                </td>
                <td class="c105" colspan="3" rowspan="1">
                  <p class="c6">
                    <span class="fw-bold c23">{{ doc.unvan }}</span>
                  </p>
                </td>
              </tr>
              <tr class="c41">
                <td class="c47" colspan="1" rowspan="1">
                  <p class="c6"><span class="c14">Firma Vergi No</span></p>
                </td>
                <td class="c52" colspan="1" rowspan="1">
                  <p class="c6">
                    <span class="fw-bold c23">{{ doc.vkn }}</span>
                  </p>
                </td>
                <td class="c26" colspan="1" rowspan="1">
                  <p class="c6"><span class="c14">Vergi Dairesi</span></p>
                </td>
                <td class="c5" colspan="1" rowspan="1">
                  <p class="c6">
                    <span class="fw-bold c23">{{ doc.vd }}</span>
                  </p>
                </td>
              </tr>
              <tr class="c126">
                <td class="c47" colspan="1" rowspan="1">
                  <p class="c6"><span class="c14">Yetkili Kişi </span></p>
                </td>
                <td class="c52" colspan="1" rowspan="1">
                  <p class="c6">
                    <span class="fw-bold c23">{{ doc.yetkili }}</span>
                  </p>
                </td>
                <td class="c26" colspan="1" rowspan="1">
                  <p class="c6"><span class="c14">Yetkili TCK</span></p>
                </td>
                <td class="c5" colspan="1" rowspan="1">
                  <p class="c6">
                    <span class="fw-bold c23">{{ doc.tck }}</span>
                  </p>
                </td>
              </tr>
              <tr class="c124">
                <td class="c47" colspan="1" rowspan="1">
                  <p class="c6"><span class="c14">Yetkili Cep</span></p>
                </td>
                <td class="c52" colspan="1" rowspan="1">
                  <p class="c6">
                    <span class="fw-bold c23">{{ doc.cep }}</span>
                  </p>
                </td>
                <td class="c26" colspan="1" rowspan="1">
                  <p class="c6"><span class="c14">Yetkili Mail</span></p>
                </td>
                <td class="c5" colspan="1" rowspan="1">
                  <p class="c6">
                    <span class="fw-bold c23">{{ doc.mail }}</span>
                  </p>
                </td>
              </tr>
              <tr class="c41">
                <td class="c47" colspan="1" rowspan="1">
                  <p class="c6"><span class="c14">Merkez il</span></p>
                </td>
                <td class="c52" colspan="1" rowspan="1">
                  <p class="c6">
                    <span class="fw-bold c23">{{ doc.merkez_il }}</span>
                  </p>
                </td>
                <td class="c26" colspan="1" rowspan="1">
                  <p class="c6"><span class="c14">Merkez ilçe</span></p>
                </td>
                <td class="c5" colspan="1" rowspan="1">
                  <p class="c6">
                    <span class="fw-bold c23">{{ doc.merkez_ilce }}</span>
                  </p>
                </td>
              </tr>
              <tr class="c116">
                <td class="c47" colspan="1" rowspan="1">
                  <p class="c6"><span class="c14">Merkez Adresi</span></p>
                </td>
                <td class="c52" colspan="1" rowspan="1">
                  <p class="c6">
                    <span class="fw-bold c23">{{ doc.merkez_adres }}</span>
                  </p>
                </td>
                <td class="c26" colspan="1" rowspan="1">
                  <p class="c6"><span class="c14">Toplam POS Adeti</span></p>
                </td>
                <td class="c5" colspan="1" rowspan="1">
                  <p class="c6">
                    <span class="fw-bold c23">{{ doc.toplam_pos }}</span>
                  </p>
                </td>
              </tr>
            </table>

            <div class="mt-10">
              <p class="fw-bold">
                <span class="c3">PlusPay Kampanya Taahhütnamesi (“</span
                ><span class="c3 c62">Taahhütname</span
                ><span class="c3"
                  >”) ,ile birlikte hüküm ifade edecek ve işbu Başvuru Formu’nda
                  düzenlenmemiş olan tüm hususlarda “Taahhütname” hükümleri
                  geçerli olacaktır.</span
                >
              </p>

              <p>
                <span class="c50"
                  >PlusPay Kampanyası kapsamında sunulan PlusPay Servislerine
                  ilişkin paket seçenekleri, </span
                ><span class="fw-bold">vergiler hariç bedelleri</span
                ><span class="c15 c20"
                  >(indirimli paket bedelleri ve indirimsiz paket bedelleri ayrı
                  ayrı gösterilmiştir) aşağıdaki tabloda gösterilmiştir.
                  Seçilecek servis/hizmet ve pakete ilişkin bilgi aşağıdaki
                  tablonun son kolonunda işaretlenmelidir.</span
                >
              </p>
            </div>
            <h4 class="fw-bold">Paket Fiyatları :</h4>
            <table class="contract-table">
              <tr class="c121">
                <td class="c95" colspan="1" rowspan="1">
                  <p class="c6 c42 c17"></p>
                </td>
                <td class="c119" colspan="1" rowspan="1">
                  <p class="c6 c42"><span class="c40">Paketler*</span></p>
                </td>
                <td class="c25" colspan="1" rowspan="1">
                  <p class="c6 c42">
                    <span class="fw-bold c99">Lisans Adedi</span>
                  </p>
                </td>
                <td class="c51" colspan="1" rowspan="1">
                  <p class="c6 c42">
                    <span class="fw-bold c99">Liste fiyatı</span>
                  </p>
                  <p class="c6 c42 c17">
                    <span class="c15 c72 c80 c99 c32"></span>
                  </p>
                </td>
                <td class="c51" colspan="1" rowspan="1">
                  <p class="c6 c42">
                    <span class="fw-bold c99">Kampanya Fiyatı **</span>
                  </p>
                </td>
                <td class="c100" colspan="1" rowspan="1">
                  <p class="c6 c42">
                    <span class="fw-bold c99">Toplam Abonelik Tutarı **</span>
                  </p>
                </td>
                <td class="c84" colspan="1" rowspan="1">
                  <p class="c6 c42">
                    <span class="c15 fw-bold c72 c99">ücretsiz Ay</span>
                  </p>
                </td>
              </tr>
              <tr class="c21">
                <td class="c12" colspan="1" rowspan="1">✓</td>
                <td class="c24" colspan="1" rowspan="1">
                  <p class="c43">
                    <span class="c20 c15">Paycell Android POS</span>
                  </p>
                </td>
                <td class="c25" colspan="1" rowspan="1">
                  <p class="c73">
                    <span class="c27 c23">{{ doc.toplam_pos }}</span>
                  </p>
                </td>
                <td class="c89" colspan="1" rowspan="1">
                  <p class="c73">
                    <span class="c27 c23">{{ doc.PAYCELL_L }}</span>
                  </p>
                </td>
                <td class="c89" colspan="1" rowspan="1">
                  <p class="c73">
                    <span class="c27 c23">{{ doc.PAYCELL_K }}</span>
                  </p>
                </td>
                <td class="c113" colspan="1" rowspan="1">
                  <p class="c73">
                    <span class="c27 c23">{{ doc.PAYCELL_T }}</span>
                  </p>
                </td>
                <td class="c11" colspan="1" rowspan="1">
                  <p class="c73">
                    <span class="c27 c23">{{ doc.PAYCELL_U }}</span>
                  </p>
                </td>
              </tr>
              <tr class="c33">
                <td class="c12" colspan="1" rowspan="1"></td>
                <td class="c24" colspan="1" rowspan="1">
                  <p class="c43">
                    <span class="c20 c15">Entegrasyon Servisleri</span>
                  </p>
                </td>
                <td class="c25" colspan="1" rowspan="1">
                  <p class="c73">
                    <span class="c27 c23">{{ doc.sube_adet }}</span>
                  </p>
                </td>
                <td class="c89" colspan="1" rowspan="1">
                  <p class="c73">
                    <span class="c27 c23">{{ doc.ENT_L }}</span>
                  </p>
                </td>
                <td class="c89" colspan="1" rowspan="1">
                  <p class="c73">
                    <span class="c27 c23">{{ doc.ENT_K }}</span>
                  </p>
                </td>
                <td class="c113" colspan="1" rowspan="1">
                  <p class="c73">
                    <span class="c27 c23">{{ doc.ENT_T }}</span>
                  </p>
                </td>
                <td class="c11" colspan="1" rowspan="1">
                  <p class="c73">
                    <span class="c27 c23">{{ doc.ENT_U }}</span>
                  </p>
                </td>
              </tr>
              <tr class="c33">
                <td class="c12" colspan="1" rowspan="1"></td>
                <td class="c24" colspan="1" rowspan="1">
                  <p class="c43">
                    <span class="c20 c15">E-Belge (Limitsiz)</span>
                  </p>
                </td>
                <td class="c25" colspan="1" rowspan="1">
                  <p class="c73">
                    <span class="c23 c27">{{ doc.sube_adet }}</span>
                  </p>
                </td>
                <td class="c89" colspan="1" rowspan="1">
                  <p class="c73">
                    <span class="c27 c23">{{ doc.EPLUS_L }}</span>
                  </p>
                </td>
                <td class="c89" colspan="1" rowspan="1">
                  <p class="c73">
                    <span class="c27 c23">{{ doc.EPLUS_K }}</span>
                  </p>
                </td>
                <td class="c113" colspan="1" rowspan="1">
                  <p class="c73">
                    <span class="c27 c23">{{ doc.EPLUS_T }}</span>
                  </p>
                </td>
                <td class="c11" colspan="1" rowspan="1">
                  <p class="c73">
                    <span class="c27 c23">{{ doc.EPLUS_U }}</span>
                  </p>
                </td>
              </tr>
              <tr class="c130">
                <td class="c12" colspan="1" rowspan="1">✓</td>
                <td class="c24" colspan="1" rowspan="1">
                  <p class="c43">
                    <span class="c20 c15">Aktivasyon ücreti</span>
                  </p>
                </td>
                <td class="c25" colspan="1" rowspan="1">
                  <p class="c73">
                    <span class="c27 c23">{{ doc.sube_adet }}</span>
                  </p>
                </td>
                <td class="c51" colspan="1" rowspan="1">
                  <p class="c73">
                    <span class="c27 c23">{{ doc.AKTIF_L }}</span>
                  </p>
                </td>
                <td class="c51" colspan="1" rowspan="1">
                  <p class="c73">
                    <span class="c27 c23">{{ doc.AKTIF_K }}</span>
                  </p>
                </td>
                <td class="c100" colspan="1" rowspan="1">
                  <p class="c73">
                    <span class="c27 c23">{{ doc.AKTIF_T }}</span>
                  </p>
                </td>
                <td class="c84" colspan="1" rowspan="1">
                  <p class="c73">
                    <span class="c27 c23">{{ doc.AKTIF_U }}</span>
                  </p>
                </td>
              </tr>
              <tr class="c22">
                <td class="c95" colspan="1" rowspan="1"></td>
                <td class="c119" colspan="1" rowspan="1">
                  <p class="c6 c42"><span class="c3">Genel Toplam</span></p>
                </td>
                <td class="c125" colspan="3" rowspan="1">
                  <p class="c6 c42 c17"><span class="c20 c15"></span></p>
                </td>
                <td class="c96" colspan="2" rowspan="1">
                  <p class="c6 c42">
                    <span class="c20 c15">{{ doc.GENEL_TOPLAM }}</span>
                  </p>
                </td>
              </tr>
            </table>
            <p class="c6 c17 c42"><span class="c20 c15"></span></p>
            <ol class="c37 lst-kix_g7447vlcwo46-0 start" start="1">
              <li class="c6 c42 c63 li-bullet-0">
                <span class="c20 c15">Fiyatlar %20 KDV Hariçtir. </span>
              </li>
              <li class="c6 c42 c63 li-bullet-0">
                <span class="c20 c15"
                  >Taahhüt süresi 12 aydır. Sözleşme iptal edilene kadar, her
                  yıl güncel fiyatlardan yenilenecektir.</span
                >
              </li>
              <li class="c6 c42 c63 li-bullet-0">
                <span class="c50">Aylık ciro, POS Başı </span
                ><span class="c3 c23">{{ doc.POSLIMIT }} </span
                ><span class="c3">TL</span><span class="c27">&nbsp;</span
                ><span class="c50"
                  >aşması durumunda Paycell Android POS aidatı, Şube Başı </span
                ><span class="c3 c23">{{ doc.SUBELIMIT }} </span
                ><span class="c3">TL </span
                ><span class="c50"
                  >aşması durumunda E-Belge ve Entegrasyon servisi aidatları
                  alınmayacaktır.</span
                >
              </li>
              <li class="c42 c49 li-bullet-0">
                <span class="c20 c15"
                  >PlusPay fiyatlarda ve limitlerde değişiklik yapma hakkını
                  saklı tutar.</span
                >
              </li>
            </ol>
            <p class="c68 c42 c136 li-bullet-0 ps-4">
              <span class="c20 c15"
                >Paket abonelik ücretleri tercihe göre aylık olarak
                faturalandırılacaktır. Paket abonelik ücretleri ödemeleri
                işletme hak edişinden mahsup edilecektir. işletme alacağının
                abonelik ücretlerini karşılamaması durumunda PlusPay işletmeden
                ödeme talep edecektir.
              </span>
            </p>
          </app-pluspay-contract-item>

          <app-pluspay-contract-item #page_10 page="10" ip="AAAAAAA">
            <p class="c68 c42 c136 li-bullet-0 ps-4">
              <span class="c20 c15"
                >(**): Sözleşme imza tarihinde geçerli fiyatlar olup, paketler
                için lisans adedine (şube veya terminal sayısı) göre geçerli
                olacak baremli güncel birim fiyat listesine www.pluspay.com.tr
                adresindeki ürün sayfasından ulaşılabilir.</span
              >
            </p>
            <h4 class="fw-bold">Tek çekim Komisyon Oranları:</h4>
            <table class="contract-table">
              <tr class="c138">
                <td class="c44" colspan="1" rowspan="1">
                  <p class="c60 c42 c17"><span class="c18 c15"></span></p>
                </td>
                <td class="c94" colspan="1" rowspan="1">
                  <p class="c1">
                    <span class="c18 c15">Haftalık &#9723;&#65039;</span>
                  </p>
                </td>
                <td class="c93" colspan="1" rowspan="1">
                  <p class="c1">
                    <span class="c82">1 Gün Bloke &#9723;&#65039;</span>
                  </p>
                </td>
                <td class="c97" colspan="1" rowspan="1">
                  <p class="c1">
                    <span class="c18 c15">7 Gün Bloke &#9723;&#65039;</span>
                  </p>
                </td>
                <td class="c85" colspan="1" rowspan="1">
                  <p class="c1">
                    <span class="c18 c15">15 Gün Bloke &#9723;&#65039;</span>
                  </p>
                </td>
                <td class="c74" colspan="1" rowspan="1">
                  <p class="c1">
                    <span class="c18 c15"
                      >32 &nbsp;Gün Bloke &#9723;&#65039;</span
                    >
                  </p>
                </td>
              </tr>
              <tr class="c75">
                <td class="c44" colspan="1" rowspan="1">
                  <p class="c60 c42">
                    <span class="c18 c15">Paycell Android Pos</span>
                  </p>
                </td>
                <td class="c94" colspan="1" rowspan="1">
                  <p class="c1">
                    <span class="c57 c15 c23">{{ doc.price_h_yi }}</span>
                  </p>
                </td>
                <td class="c93" colspan="1" rowspan="1">
                  <p class="c1">
                    <span class="c23 c57">{{ doc.price_1_yi }}</span>
                  </p>
                </td>
                <td class="c97" colspan="1" rowspan="1">
                  <p class="c1">
                    <span class="c57 c23">{{ doc.price_7_yi }}</span>
                  </p>
                </td>
                <td class="c85" colspan="1" rowspan="1">
                  <p class="c1">
                    <span class="c57 c23">{{ doc.price_15_yi }}</span>
                  </p>
                </td>
                <td class="c74" colspan="1" rowspan="1">
                  <p class="c1">
                    <span class="c57 c23">{{ doc.price_32_yi }}</span>
                  </p>
                </td>
              </tr>
              <tr class="c75">
                <td class="c44" colspan="1" rowspan="1">
                  <p class="c60 c42">
                    <span class="c18 c15">Yurt Dışı Kredi Kartı</span>
                  </p>
                </td>
                <td class="c104" colspan="1" rowspan="1">
                  <p class="c1">
                    <span class="c57 c23">{{ doc.price_h_yd }}</span>
                  </p>
                </td>
                <td class="c120" colspan="1" rowspan="1">
                  <p class="c1">
                    <span class="c57 c23">{{ doc.price_1_yd }}</span>
                  </p>
                </td>
                <td class="c88" colspan="1" rowspan="1">
                  <p class="c1">
                    <span class="c57 c23">{{ doc.price_7_yd }}</span>
                  </p>
                </td>
                <td class="c102" colspan="1" rowspan="1">
                  <p class="c1">
                    <span class="c57 c23">{{ doc.price_15_yd }}</span>
                  </p>
                </td>
                <td class="c108" colspan="1" rowspan="1">
                  <p class="c1">
                    <span class="c57 c23">{{ doc.price_32_yd }}</span>
                  </p>
                </td>
              </tr>
              <tr class="c75">
                <td class="c44" colspan="1" rowspan="1">
                  <p class="c60 c42">
                    <span class="c18 c15">Turkcell Mobil ödeme</span>
                  </p>
                </td>
                <td class="c94" colspan="1" rowspan="1">
                  <p class="c1">
                    <span class="c57 c23">{{ doc.price_h_tm }}</span>
                  </p>
                </td>
                <td class="c93" colspan="1" rowspan="1">
                  <p class="c1">
                    <span class="c57 c23">{{ doc.price_1_tm }}</span>
                  </p>
                </td>
                <td class="c97" colspan="1" rowspan="1">
                  <p class="c1">
                    <span class="c57 c23">{{ doc.price_7_tm }}</span>
                  </p>
                </td>
                <td class="c85" colspan="1" rowspan="1">
                  <p class="c1">
                    <span class="c57 c23">{{ doc.price_15_tm }}</span>
                  </p>
                </td>
                <td class="c74" colspan="1" rowspan="1">
                  <p class="c1">
                    <span class="c57 c23">{{ doc.price_32_tm }}</span>
                  </p>
                </td>
              </tr>
            </table>
            <h4 class="fw-bold mt-10">Taksitli Komisyon Oranları:</h4>
            <table class="contract-table">
              <tr class="c135">
                <td class="c103" colspan="1" rowspan="1">
                  <p class="c43">
                    <span class="c18 c15"><br />Paycell Taksit Sayısı</span>
                  </p>
                </td>
                <td class="c109" colspan="1" rowspan="1">
                  <p class="c60 c42">
                    <span class="c82">Haftalık &#9723;&#65039;</span>
                  </p>
                </td>
                <td class="c122" colspan="1" rowspan="1">
                  <p class="c60 c42">
                    <span class="c82">1 Gün Bloke &#9723;&#65039;</span>
                  </p>
                </td>
                <td class="c76" colspan="1" rowspan="1">
                  <p class="c60 c42">
                    <span class="c18 c15">7 Gün Bloke &#9723;&#65039;</span>
                  </p>
                </td>
                <td class="c76" colspan="1" rowspan="1">
                  <p class="c42 c60">
                    <span class="c18 c15">15 Gün Bloke &#9723;&#65039;</span>
                  </p>
                </td>
                <td class="c101" colspan="1" rowspan="1">
                  <p class="c60 c42">
                    <span class="c18 c15">32 Gün Bloke &#9723;&#65039;</span>
                  </p>
                </td>
              </tr>
              <tr class="c139">
                <td class="c46" colspan="1" rowspan="1">
                  <p class="c13"><span class="c18 c15">2</span></p>
                </td>
                <td class="c66" colspan="1" rowspan="1">
                  <p class="c13">
                    <span class="c0 c15">{{ doc.price_h_2 }}</span>
                  </p>
                </td>
                <td class="c67" colspan="1" rowspan="1">
                  <p class="c13">
                    <span class="c0">{{ doc.price_1_2 }}</span>
                  </p>
                </td>
                <td class="c29" colspan="1" rowspan="1">
                  <p class="c13">
                    <span class="c0">{{ doc.price_7_2 }}</span>
                  </p>
                </td>
                <td class="c29" colspan="1" rowspan="1">
                  <p class="c13">
                    <span class="c0">{{ doc.price_15_2 }}</span>
                  </p>
                </td>
                <td class="c65" colspan="1" rowspan="1">
                  <p class="c13">
                    <span class="c0">{{ doc.price_32_2 }}</span>
                  </p>
                </td>
              </tr>
              <tr class="c41">
                <td class="c46" colspan="1" rowspan="1">
                  <p class="c13"><span class="c18 c15">3</span></p>
                </td>
                <td class="c66" colspan="1" rowspan="1">
                  <p class="c13">
                    <span class="c0 c15">{{ doc.price_h_3 }}</span>
                  </p>
                </td>
                <td class="c67" colspan="1" rowspan="1">
                  <p class="c13">
                    <span class="c0">{{ doc.price_1_3 }}</span>
                  </p>
                </td>
                <td class="c29" colspan="1" rowspan="1">
                  <p class="c13">
                    <span class="c0">{{ doc.price_7_3 }}</span>
                  </p>
                </td>
                <td class="c29" colspan="1" rowspan="1">
                  <p class="c13">
                    <span class="c0">{{ doc.price_15_3 }}</span>
                  </p>
                </td>
                <td class="c65" colspan="1" rowspan="1">
                  <p class="c13">
                    <span class="c0">{{ doc.price_32_3 }}</span>
                  </p>
                </td>
              </tr>
              <tr class="c41">
                <td class="c46" colspan="1" rowspan="1">
                  <p class="c13"><span class="c18 c15">4</span></p>
                </td>
                <td class="c66" colspan="1" rowspan="1">
                  <p class="c13">
                    <span class="c0 c15">{{ doc.price_h_4 }}</span>
                  </p>
                </td>
                <td class="c67" colspan="1" rowspan="1">
                  <p class="c13">
                    <span class="c0">{{ doc.price_1_4 }}</span>
                  </p>
                </td>
                <td class="c29" colspan="1" rowspan="1">
                  <p class="c13">
                    <span class="c0">{{ doc.price_7_4 }}</span>
                  </p>
                </td>
                <td class="c29" colspan="1" rowspan="1">
                  <p class="c13">
                    <span class="c0">{{ doc.price_15_4 }}</span>
                  </p>
                </td>
                <td class="c65" colspan="1" rowspan="1">
                  <p class="c13">
                    <span class="c0">{{ doc.price_32_4 }}</span>
                  </p>
                </td>
              </tr>
              <tr class="c41">
                <td class="c46" colspan="1" rowspan="1">
                  <p class="c13"><span class="c18 c15">5</span></p>
                </td>
                <td class="c66" colspan="1" rowspan="1">
                  <p class="c13">
                    <span class="c0 c15">{{ doc.price_h_5 }}</span>
                  </p>
                </td>
                <td class="c67" colspan="1" rowspan="1">
                  <p class="c13">
                    <span class="c0">{{ doc.price_1_5 }}</span>
                  </p>
                </td>
                <td class="c29" colspan="1" rowspan="1">
                  <p class="c13">
                    <span class="c0">{{ doc.price_7_5 }}</span>
                  </p>
                </td>
                <td class="c29" colspan="1" rowspan="1">
                  <p class="c13">
                    <span class="c0">{{ doc.price_15_5 }}</span>
                  </p>
                </td>
                <td class="c65" colspan="1" rowspan="1">
                  <p class="c13">
                    <span class="c0">{{ doc.price_32_5 }}</span>
                  </p>
                </td>
              </tr>
              <tr class="c41">
                <td class="c46" colspan="1" rowspan="1">
                  <p class="c13"><span class="c18 c15">6</span></p>
                </td>
                <td class="c66" colspan="1" rowspan="1">
                  <p class="c13">
                    <span class="c0 c15">{{ doc.price_h_6 }}</span>
                  </p>
                </td>
                <td class="c67" colspan="1" rowspan="1">
                  <p class="c13">
                    <span class="c0">{{ doc.price_1_6 }}</span>
                  </p>
                </td>
                <td class="c29" colspan="1" rowspan="1">
                  <p class="c13">
                    <span class="c0">{{ doc.price_7_6 }}</span>
                  </p>
                </td>
                <td class="c29" colspan="1" rowspan="1">
                  <p class="c13">
                    <span class="c0">{{ doc.price_15_6 }}</span>
                  </p>
                </td>
                <td class="c65" colspan="1" rowspan="1">
                  <p class="c13">
                    <span class="c0">{{ doc.price_32_6 }}</span>
                  </p>
                </td>
              </tr>
              <tr class="c41">
                <td class="c46" colspan="1" rowspan="1">
                  <p class="c13"><span class="c18 c15">7</span></p>
                </td>
                <td class="c66" colspan="1" rowspan="1">
                  <p class="c13">
                    <span class="c0 c15">{{ doc.price_h_7 }}</span>
                  </p>
                </td>
                <td class="c67" colspan="1" rowspan="1">
                  <p class="c13">
                    <span class="c0">{{ doc.price_1_7 }}</span>
                  </p>
                </td>
                <td class="c29" colspan="1" rowspan="1">
                  <p class="c13">
                    <span class="c0">{{ doc.price_7_7 }}</span>
                  </p>
                </td>
                <td class="c29" colspan="1" rowspan="1">
                  <p class="c13">
                    <span class="c0">{{ doc.price_15_7 }}</span>
                  </p>
                </td>
                <td class="c65" colspan="1" rowspan="1">
                  <p class="c13">
                    <span class="c0">{{ doc.price_32_7 }}</span>
                  </p>
                </td>
              </tr>
              <tr class="c41">
                <td class="c46" colspan="1" rowspan="1">
                  <p class="c13"><span class="c18 c15">8</span></p>
                </td>
                <td class="c66" colspan="1" rowspan="1">
                  <p class="c13">
                    <span class="c0 c15">{{ doc.price_h_8 }}</span>
                  </p>
                </td>
                <td class="c67" colspan="1" rowspan="1">
                  <p class="c13">
                    <span class="c0">{{ doc.price_1_8 }}</span>
                  </p>
                </td>
                <td class="c29" colspan="1" rowspan="1">
                  <p class="c13">
                    <span class="c0">{{ doc.price_7_8 }}</span>
                  </p>
                </td>
                <td class="c29" colspan="1" rowspan="1">
                  <p class="c13">
                    <span class="c0">{{ doc.price_15_8 }}</span>
                  </p>
                </td>
                <td class="c65" colspan="1" rowspan="1">
                  <p class="c13">
                    <span class="c0">{{ doc.price_32_8 }}</span>
                  </p>
                </td>
              </tr>
              <tr class="c41">
                <td class="c46" colspan="1" rowspan="1">
                  <p class="c13"><span class="c18 c15">9</span></p>
                </td>
                <td class="c66" colspan="1" rowspan="1">
                  <p class="c13">
                    <span class="c0 c15">{{ doc.price_h_9 }}</span>
                  </p>
                </td>
                <td class="c67" colspan="1" rowspan="1">
                  <p class="c13">
                    <span class="c0">{{ doc.price_1_9 }}</span>
                  </p>
                </td>
                <td class="c29" colspan="1" rowspan="1">
                  <p class="c13">
                    <span class="c0">{{ doc.price_7_9 }}</span>
                  </p>
                </td>
                <td class="c29" colspan="1" rowspan="1">
                  <p class="c13">
                    <span class="c0">{{ doc.price_15_9 }}</span>
                  </p>
                </td>
                <td class="c65" colspan="1" rowspan="1">
                  <p class="c13">
                    <span class="c0">{{ doc.price_32_9 }}</span>
                  </p>
                </td>
              </tr>
              <tr class="c41">
                <td class="c46" colspan="1" rowspan="1">
                  <p class="c13"><span class="c18 c15">10</span></p>
                </td>
                <td class="c66" colspan="1" rowspan="1">
                  <p class="c13">
                    <span class="c0 c15">{{ doc.price_h_10 }}</span>
                  </p>
                </td>
                <td class="c67" colspan="1" rowspan="1">
                  <p class="c13">
                    <span class="c0">{{ doc.price_1_10 }}</span>
                  </p>
                </td>
                <td class="c29" colspan="1" rowspan="1">
                  <p class="c13">
                    <span class="c0">{{ doc.price_7_10 }}</span>
                  </p>
                </td>
                <td class="c29" colspan="1" rowspan="1">
                  <p class="c13">
                    <span class="c0">{{ doc.price_15_10 }}</span>
                  </p>
                </td>
                <td class="c65" colspan="1" rowspan="1">
                  <p class="c13">
                    <span class="c0">{{ doc.price_32_10 }}</span>
                  </p>
                </td>
              </tr>
              <tr class="c41">
                <td class="c46" colspan="1" rowspan="1">
                  <p class="c13"><span class="c15 c18">11</span></p>
                </td>
                <td class="c66" colspan="1" rowspan="1">
                  <p class="c13">
                    <span class="c0 c15">{{ doc.price_h_11 }}</span>
                  </p>
                </td>
                <td class="c67" colspan="1" rowspan="1">
                  <p class="c13">
                    <span class="c0">{{ doc.price_1_11 }}</span>
                  </p>
                </td>
                <td class="c29" colspan="1" rowspan="1">
                  <p class="c13">
                    <span class="c0">{{ doc.price_7_11 }}</span>
                  </p>
                </td>
                <td class="c29" colspan="1" rowspan="1">
                  <p class="c13">
                    <span class="c0">{{ doc.price_15_11 }}</span>
                  </p>
                </td>
                <td class="c65" colspan="1" rowspan="1">
                  <p class="c13">
                    <span class="c0">{{ doc.price_32_11 }}</span>
                  </p>
                </td>
              </tr>
              <tr class="c41">
                <td class="c46" colspan="1" rowspan="1">
                  <p class="c13"><span class="c18 c15">12</span></p>
                </td>
                <td class="c66" colspan="1" rowspan="1">
                  <p class="c13">
                    <span class="c0 c15">{{ doc.price_h_12 }}</span>
                  </p>
                </td>
                <td class="c67" colspan="1" rowspan="1">
                  <p class="c13">
                    <span class="c0">{{ doc.price_1_12 }}</span>
                  </p>
                </td>
                <td class="c29" colspan="1" rowspan="1">
                  <p class="c13">
                    <span class="c0">{{ doc.price_7_12 }}</span>
                  </p>
                </td>
                <td class="c29" colspan="1" rowspan="1">
                  <p class="c13">
                    <span class="c0">{{ doc.price_15_12 }}</span>
                  </p>
                </td>
                <td class="c65" colspan="1" rowspan="1">
                  <p class="c13">
                    <span class="c0">{{ doc.price_32_12 }}</span>
                  </p>
                </td>
              </tr>
            </table>
            <ul class="c37 lst-kix_k0eil4y8f8q2-0 start mt-4">
              <li class="c90 c42 li-bullet-0">
                <span class="c3"
                  >Haftalık ödeme seçilmesi durumunda her çarşamba günü veya
                  takip eden ilk iş gününde bir önceki haftaya ait ödemeler
                  yapılmaktadır.</span
                >
              </li>
            </ul>
            <ul class="c37 lst-kix_vspaps8d3td-0 start">
              <li class="c42 c90 li-bullet-0">
                <span class="c50"
                  >Komisyon oranları %20 KDV hariçtir. PlusPay Komisyon
                  oranlarında değişiklik yapma hakkını saklı tutar. Komisyon
                  oranları bankalar arası kredi kartı oranlarının veya diğer
                  giderlerin değişiklik göstermesi durumunda 15 gün önceden
                  bilgi verme şartı değişebilecektir.</span
                >
              </li>
              <li class="c90 c42 li-bullet-0">
                <span class="c50"
                  >ilgili ödemeler tercih edilen bloke gününe uygun şekilde
                  vadesinde üye işyerinin hesabına varsa PlusPay ürün ve hizmet
                  ödemeleri düşülerek yapılmaktadır.</span
                >
              </li>
            </ul>
          </app-pluspay-contract-item>

          <app-pluspay-contract-item #page_11 page="11" ip="AAAAAAA">
            <h4 class="fw-bold">Şube Bilgileri :</h4>
            <table class="contract-table">
              <tr class="c41">
                <td class="c47" colspan="1" rowspan="1">
                  <p class="c6"><span class="c14">Şube Kodu</span></p>
                </td>
                <td class="c52" colspan="1" rowspan="1">
                  <p class="c6">
                    <span class="c15 fw-bold c30 c23">{{
                      doc.MARKA_KODU
                    }}</span>
                  </p>
                </td>
                <td class="c26" colspan="1" rowspan="1">
                  <p class="c6"><span class="c14">Şube Adı</span></p>
                </td>
                <td class="c5" colspan="1" rowspan="1">
                  <p class="c6">
                    <span class="c15 fw-bold c30 c23">{{ doc.SUBE_ADI }}</span>
                  </p>
                </td>
              </tr>
              <tr class="c131">
                <td class="c47" colspan="1" rowspan="1">
                  <p class="c6"><span class="c14">Şube IBAN</span></p>
                </td>
                <td class="c105" colspan="3" rowspan="1">
                  <p class="c6">
                    <span class="c15 fw-bold c23 c30">{{ doc.SUBE_IBAN }}</span>
                  </p>
                </td>
              </tr>
              <tr class="c41">
                <td class="c47" colspan="1" rowspan="1">
                  <p class="c6"><span class="c14">Şube Yetkili Kişi</span></p>
                </td>
                <td class="c52" colspan="1" rowspan="1">
                  <p class="c6">
                    <span class="c15 fw-bold c30 c23">{{
                      doc.SUBE_YETKILI
                    }}</span>
                  </p>
                </td>
                <td class="c26" colspan="1" rowspan="1">
                  <p class="c6"><span class="c14">Şube Yetkili TCK</span></p>
                </td>
                <td class="c5" colspan="1" rowspan="1">
                  <p class="c6">
                    <span class="c15 fw-bold c30 c23">{{
                      doc.SUBE_YETKILI_TCKN
                    }}</span>
                  </p>
                </td>
              </tr>
              <tr class="c41">
                <td class="c47" colspan="1" rowspan="1">
                  <p class="c6"><span class="c14">Şube Yetkili Cep </span></p>
                </td>
                <td class="c52" colspan="1" rowspan="1">
                  <p class="c6">
                    <span class="c15 fw-bold c30 c23">{{
                      doc.SUBE_YETKILI_CEP
                    }}</span>
                  </p>
                </td>
                <td class="c26" colspan="1" rowspan="1">
                  <p class="c6"><span class="c14">Şube Yetkili Mail </span></p>
                </td>
                <td class="c5" colspan="1" rowspan="1">
                  <p class="c6">
                    <span class="c15 fw-bold c30 c23">{{
                      doc.SUBE_YETKILI_MAIL
                    }}</span>
                  </p>
                </td>
              </tr>
              <tr class="c41">
                <td class="c47" colspan="1" rowspan="1">
                  <p class="c6"><span class="c14">Şube Telefonu</span></p>
                </td>
                <td class="c52" colspan="1" rowspan="1">
                  <p class="c6">
                    <span class="c15 fw-bold c30 c23">{{ doc.SUBE_TEL }}</span>
                  </p>
                </td>
                <td class="c26" colspan="1" rowspan="1">
                  <p class="c6"><span class="c14">Şube Mail Adresi</span></p>
                </td>
                <td class="c5" colspan="1" rowspan="1">
                  <p class="c6">
                    <span class="c15 fw-bold c30 c23">{{ doc.SUBE_MAIL }}</span>
                  </p>
                </td>
              </tr>
              <tr class="c41">
                <td class="c47" colspan="1" rowspan="1">
                  <p class="c6"><span class="c14">Şube il</span></p>
                </td>
                <td class="c52" colspan="1" rowspan="1">
                  <p class="c6">
                    <span class="c15 fw-bold c30 c23">{{ doc.SUBE_IL }}</span>
                  </p>
                </td>
                <td class="c26" colspan="1" rowspan="1">
                  <p class="c6"><span class="c14">Şube ilçe</span></p>
                </td>
                <td class="c5" colspan="1" rowspan="1">
                  <p class="c6">
                    <span class="c15 fw-bold c30 c23">{{ doc.SUBE_ILCE }}</span>
                  </p>
                </td>
              </tr>
              <tr class="c87">
                <td class="c47" colspan="1" rowspan="1">
                  <p class="c6"><span class="c14">Şube Adresi</span></p>
                </td>
                <td class="c52" colspan="1" rowspan="1">
                  <p class="c6">
                    <span class="c15 fw-bold c30 c23">{{
                      doc.SUBE_ADRES
                    }}</span>
                  </p>
                </td>
                <td class="c26" colspan="1" rowspan="1">
                  <p class="c6"><span class="c14">POS Adeti</span></p>
                </td>
                <td class="c5" colspan="1" rowspan="1">
                  <p class="c6">
                    <span class="c15 fw-bold c30 c23">{{ doc.SUBE_POS }}</span>
                  </p>
                </td>
              </tr>
            </table>
            <p class="c68 c42 c70 mt-10">
              <span class="fw-bold c23"
                >DİĞER ŞUBELER İÇİN OTOMATiK KAYIT OLUŞTURULACAK.</span
              >
            </p>
          </app-pluspay-contract-item>
        </div>
      </div>
    </div>
  </div>
</div>
