// Türkiye
export const locale = {
  lang: 'tr',
  data: {
    TRANSLATOR: {
      SELECT: 'Dilinizi seçin',
    },
    DATEPICKER: {
      January: 'Ocak',
      February: 'Şubat',
      March: 'Mart',
      April: 'Nisan',
      May: 'Mayıs',
      June: 'Haziran',
      July: 'Temmuz',
      August: 'Ağustos',
      September: 'Eylül',
      October: 'Ekim',
      November: 'Kasım',
      December: 'Aralık',
      Sun: 'Paz',
      Mon: 'Pts',
      Tue: 'Sal',
      Wed: 'Çar',
      Thu: 'Per',
      Fri: 'Cum',
      Sat: 'Cts',
    },
    DASHBOARD: {
      Sales: 'Satışlar',
      Filters: 'Filtreler',
      TransactionCount: 'Başarılı Satış İşlemi',
      TotalNet: 'Net Satış',
      SalesAmount: 'Satış Tutarı',
      TotalVAT: 'Toplam KDV',
      Amount: 'Tutarı',
      GrossSales: 'Toplam Satış',
      TotalCancellation: 'Toplam İptal',
      Collections: 'Tahsilatlar',
      CollectionDetails: 'Tahsilat Detayları',
      Collection: 'Tahsilat',
      Type: 'Türü',
      Total: 'Genel Toplam',
      Transaction: 'İşlem',
      Piece: 'Adet',
      Cart: 'Kart',
      Cash: 'Nakit',
      ForwardSale: 'Açık Hesap Satışlar',
      BANK_TRANSFER: 'Banka Transferi',
      CreditCard: 'Kredi Kartı',
      PayWithQR: 'QR İle ödeme',
      PayWithNFC: 'NFC İle ödeme',
      RdParty: '3rd Parti',
    },
    MENU: {
      NEW: 'Yeni',
      ACTIONS: 'Eylemler',
      CREATE_POST: 'Yeni Gönderi Oluştur',
      PAGES: 'Sayfalar',
      FEATURES: 'Özellikler',
      APPS: 'Uygulamalar',
      DASHBOARD: 'Güncel Durum',
      TRANSACTIONS: 'İşlemler',
      ORDERS: 'Siparişler',
      COLLECTIONS: 'Tahsilatlar',
      E_DOCUMENTS: 'E-Belgeler',
      CANCELLATION_REFUND: 'İptal / İade',
      CURRENT_ACCOUNT: 'Cari Hesap',
      BILLS: 'Cariler',
      OUTGOING_INVOICES: 'Giden Faturalar',
      INCOMING_INVOICES: 'Gelen Faturalar',
      REPORTS: 'Liste ve Raporlar',
      END_OF_DAY_REPORT: 'Gün Sonu Raporu',
      TURNOVER_REPORT: 'Belge Listesi',
      VALUE_REPORT: 'Valör Raporu',
      COMMISSION_REPORT: 'Komisyon Raporu',
      INVOICED_TRANSACTIONS: 'E-Belge',
      STOCK_TRANSACTIONS: 'Ürün ve Katalog Yönetimi',
      CATEGORIES: 'Kategoriler',
      PRODUCTS: 'Ürünler',
      VARIOUS_PRODUCTS: 'Muhtelif Ürünler',
      DailyCashReport: 'Günlük Kasa Raporu',
      UserCashReport: 'Kullanıcı Kasa Raporu',
      UserReport: 'Kullanıcı Raporu',
      XReport: 'X Raporu',
      MonthlySalesReport: 'Aylık Satış Raporu',
      ProductSalesReport: 'Ürün Satış Raporu',
      POSCashReport: 'Pos Terminali Detay Raporu',
      Management: 'Yönetim',
      CompanyInformation: 'Firma Bilgileri',
      Profiles: 'Profiller',
      Branches: 'Şubeler',
      Users: 'Kullanıcılar',
      Terminals: 'Terminaller',
      Application: 'Başvuru',
      ApplicationInformation: 'Başvuru Bilgileri',
      PluspayContract: 'Pluspay Sözleşmesi',
      PaycellContract: 'Paycell Sözleşmesi',
      CompanyDocuments: 'Şirket Evrakları',
      VUK507Application: 'VUK 507 Başvurusu',
      CompleteApplication: 'Başvuruyu Tamamla',
      Taxis: 'Taksiler',
      Pauses: 'Duraklar',
    },
    GENERAL: {
      numberOfTransactions: 'İşlem Sayısı',
      endorsement: 'Ciro',
      clickToCopy: "PDF'yi İndirmek ve Numarayı Kopyalamak İçin Tıklayın",
      Today: 'Bugün',
      Yesterday: 'Dün',
      ThisWeek: 'Bu Hafta',
      ThisMonth: 'Bu Ay',
      LastMonth: 'Geçen Ay',
      ThisYear: 'Bu Yıl',
      All: 'Tümü',
      PleaseWait: 'Lütfen Bekleyin...',
      AllRightsReserved: '© 2024 PlusPay. Tüm Hakları Saklıdır.',
      PlusPayTechnologyServices:
        'Pluspay Teknoloji Hizmetleri Anonim Şirketi, T.C. Merkez Bankası',
      PaycellPaymentInstitution:
        "(TCMB)'nin iznine sahip Paycell Ödeme Kuruluşunun entegratörü dür.",
      OPTIONAL: 'Opsiyonel',
      PleaseEnterTheCodeSentToYourMobilePhone:
        'Lütfen Cep Telefonunuza Gönderilen Kodu Giriniz.',
      SelectDate: 'Tarih Seçin',
      Filter: 'Filtrele',
      Search: 'Arama Yap',
      Export: 'Dışa Aktar',
      NotFound: 'Bulunamadı',
      ListedResults: 'Listelenen {{length}} Sonuç',
      Confirm: 'Onayla',
      Cancel: 'Vazgeç',
      FilterOptions: 'Filtreleme Seçenekleri',
      NoSelection: 'Seçim Yok',
      Reset: 'Sıfırla',
      SelectOrderStatus: 'Sipariş Durumu Seç',
      EndDate: 'Bitiş Tarihi',
      LightColor: 'Açık Renk',
      DarkColor: 'Koyu Renk',
      SystemColor: 'Sistem Rengi',
      SearchOrder: 'Sipariş Ara...',
      Orders: 'Siparişler',
      RemoveSelected: 'Seçilenleri Kaldır',
      City: 'Şehir',
      SelectCashier: 'Kasiyer Seçiniz',
      SelectTerminalPOS: 'Terminal Seçiniz',
      SelectDocument: 'Döküman Seçiniz',
      DeleteCategory: 'Kategori Sil',
      ConfirmDeleteCategory:
        'Adlı Kategoriyi Silmek istediğinizden emin misiniz?',
      SuccessfullyDeleted: 'Başarıyla silindi',
      SuccessfullySaved: 'Başarıyla kaydedildi',
      ProductSuccessfullyCreated: 'Ürün Başarıyla Oluşturuldu',
      ProductSuccessfullyDeleted: 'Ürün Başarıyla Silindi',
      DeleteProduct: 'Ürün Sil',
      ConfirmDeleteProduct: 'Ürünü Silmek istediğinizden emin misiniz?',
      MyProfile: 'Profilim',
      SUCCESS: 'Başarılı',
      FAIL: 'Başarısız',
      WAITING: 'Bekliyor',
      LOG_OUT: 'Çıkış yap',
      Profile: 'Profilim',
      Language: 'Dil',
      Yes: 'Evet',
      No: 'Hayır',
      TotalPaid: 'Toplam Ödenen',
      SelectStatus: 'Durum Seçiniz',
      Success: 'Başarılı',
      Warning: 'Uyarı',
      Failure: 'Başarısız',
      SUPERADMIN: 'Superadmin',
      AUTHORIZED: 'Yetkili',
      CASHIER: 'Kasiyer',
      COURIER: 'Kurye',
      DeleteUser: 'Kullanıcıyı Sil',
      ConfirmDeleteUser: 'Seçili kullanıcıyı silmek istediğinize emin misiniz?',
      Name: 'Ad',
      Phone: 'Telefon',
      Email: 'E-posta',
      Role: 'Rol',
      FirmIds: 'Firmalar',
      Roles: 'Yetkiler',
      USER: 'Kullanıcı',
      CANCEL: 'İptal',
      REFUND: 'İade',
      DISCOUNT: 'İndirim',
      PRODUCT: 'Ürün',
      PaymentStatus: 'Ödeme Durumu',
      DocumentStatus: 'Belge Durumu',
      serial_no: 'Seri Numarası',
      terminal_id: 'Terminal Kimliği',
      param_4: 'Kullanıcı Adı',
      param_5: 'Şifre',
      invoice_prefix: 'Fatura Ön Eki',
      param_3: 'Terminal Kodu',
      Start: 'Başlangıç',
      End: 'Bitiş',
      TotalOfMealCards: 'Yemek Kartları Toplamı',
      signinOut: 'Çıkış Yapılıyor.',
      allCards: 'Tüm kartları',
      hide: 'gizle',
      show: 'göster',
      brand: 'Marka',
      region: 'Bölge',
    },
    APPLY: {
      CompanyInformation: 'Firma Bilgileri',
      AuthorizedInformation: 'Yetkili Bilgileri',
      IBANInformation: 'IBAN Bilgileri',
      BranchInformation: 'Şube Bilgileri',
      Details: 'Ayrıntılar',
      Brand: 'Marka',
      Tariff: 'Tarife',
      Source: 'Kaynak',
      CompanyType: 'Firma Tipi Seçiniz',
      IndividualEnterprise: 'Şahıs Şirketi',
      IndividualEnterpriseDescription:
        'Şahıs şirketi, işletmenin sahibi ve işletme kendisi tarafından tamamen kontrol edilen ve işletilen bir tür işletme yapısıdır.',
      LimitedOrAS: 'Limited veya A.Ş',
      LimitedOrASDescription:
        'Anonim Şirket (A.Ş.), daha büyük işletmeler ve halka açık şirketler için daha yaygın bir işletme yapısıdır.',
      TaxNumber: 'Vergi No',
      GetFromGIB: "GİB'den Getir",
      TaxOffice: 'Vergi Dairesi',
      Title: 'Unvan',
      City: 'İl',
      District: 'İlçe',
      HeadquartersAddress: 'Merkez Adresi',
      PosInformation: 'Pos Bilgileri',
      Sector: 'Sektör',
      InstallmentOption: 'Taksit Seçeneği - Evet / Hayır',
      POSType: 'POS Tipi',
      TechnicalPartner: 'Teknik İş Ortağı',
      BlockedDayCount: 'Bloke Gün Sayısı',
      NextStep: 'Sonraki Adım',
      Day: 'Gün',
      Weekly: 'Haftalık',
      Monthly: 'Aylık',
      BlockedDayCountDefinition:
        'Bloke gün sayısı, belirli bir finansal işlem veya varlığın kullanılamayacağı veya kısıtlandığı gün sayısıdır.',
      TCIdentity: 'TC Kimlik',
      AuthorizedName: 'Yetkili Adı',
      AuthorizedSurname: 'Yetkili Soyadı',
      AuthorizedPhone: 'Yetkili Cep',
      AuthorizedEmail: 'Yetkili E-Posta',
      PrivateAuthorizedInformation: 'Firmanıza özel Yetkili Bilgileri',
      InitiateAuthorizedCreation:
        'Yetkili oluşturma modelini başlatmak için aşağıdaki butona tıklayın.',
      AddAuthorized: 'Yetkili Ekle',
      AuthorizedList: 'Yetkili Listesi',
      NameSurname: 'Adı / Soyadı',
      PhoneNumber: 'Cep Telefonu',
      Email: 'E-Posta',
      Operations: 'İşlemler',
      PreviousStep: 'Önceki Adım',
      Cancel: 'Vazgeç',
      Add: 'Ekle',
      AddIBAN: 'IBAN Ekle',
      IBANName: 'IBAN Adı',
      ConfirmIBANOwnership: "IBAN'ın şirketime ait olduğunu onaylıyorum",
      PrivateIBANInformation: 'Firmanıza özel IBAN Bilgileri',
      InitiateIBANCreation:
        'IBAN oluşturma modelini başlatmak için aşağıdaki butona tıklayın',
      IBANList: 'IBAN Listesi',
      SelectBank: 'Banka Seçiniz',
      AddBranch: 'Şube Ekle',
      BranchName: 'Şube Adı',
      PosCount: 'POS Sayısı',
      SelectIBAN: 'IBAN Seç',
      BranchManagerName: 'Şube Yetkilisi Adı',
      BranchManagerSurname: 'Şube Yetkilisi Soyadı',
      BranchManagerPhone: 'Şube Yetkilisi Telefon',
      BranchManagerEmail: 'Şube Yetkilisi Mail',
      AddressInformation: 'Adres Bilgileri',
      BranchCity: 'Şube İl',
      BranchDistrict: 'Şube İlçe',
      BranchAddress: 'Şube Adresi',
      PrivateBranchInformation: 'Firmanıza özel Şube Bilgileri',
      InitiateBranchCreation:
        'Şube oluşturma modelini başlatmak için aşağıdaki butona tıklayın',
      BranchList: 'Şubeler',
      Branch: 'Şube',
      AuthorizedNameSurname: 'Yetkili Adı / Soyadı',
      Address: 'Adres',
      Quantity: 'Adet',
      CreatingCustomContract:
        'Firmanıza özel Sözleşme (.pdf) oluşturuluyor, lütfen bekleyiniz',
      CustomContract: 'Firmanıza Özel Sözleşme',
      NoPrinterMessage:
        'Sözleşmeyi yazdırabileceğiniz yazıcı (printer) yok ise',
      NoPrinter: 'Yazıcım Yok',
      RequestFreeShipping:
        'butonu ile adresinize ücretsiz kargo talebinde bulunabilirsiniz',
      PluspayContractReview:
        'Pluspay Sözleşmeleri sisteme yüklendikten sonra operasyon ekibimiz tarafından incelenerek onaylanacaktır.',
      FreeShippingAfterApproval:
        'Onaylanması sonrasında otomatik olarak adresinize ücretsiz kargo firması yönlendirilecektir.',
      DeliveryInstructions:
        'Kargo personeline imzalı orijinal evrakları eksiksiz teslim ediniz.',
      PleaseWait: 'Lütfen Bekleyin',
      DownloadContract: 'Sözleşmeyi İndir',
      PrintContract: 'Sözleşmeyi Yazdır',
      UploadSignedContract: 'İmzalı Sözleşmeyi Yükle',
      UploadTaxCertificate: 'Vergi Levhası Yükle',
      FileLimitInfo: "Maks/min dosya sayısı 1'dir",
      UploadedFiles: 'Yüklenen Dosyalar',
      NoFileUploaded: 'Henüz Dosya Yüklemediniz',
      UploadSignatureCirculations: 'İmza Sirküleri Yükle',
      UploadTaxCertificatePluspay: 'Pluspay vergi levhası',
      Remove: 'Kaldır',
      UploadAuthorizedIDFront: 'Firma Yetkili Kimlik Belgesi (Ön Yüz)',
      IDCopyMaxMinInfo: "Maks/min dosya sayısı 1'dir",
      TaxCertificatePluspay: 'Pluspay vergi levhası',
      UploadAuthorizedIDBack: 'Firma Yetkili Kimlik Belgesi (Arka Yüz)',
      UploadIBANConfirmationDocument: 'IBAN Teyit Eder Belge',
      IBANDocumentMaxMinInfo:
        "IBAN Bilgisi İçeren Dekont ya da Hesap Cüzdan Fotokopisi. Maks/min dosya sayısı 1'dir",
      IBANCopyMaxMinInfo: "Maks/min dosya sayısı 1'dir",
      UploadOtherDocuments: 'Diğer Belgeler',
      OtherDocumentInfo:
        'Faaliyet belgesi, Oda Kayıt Belgesi, Ticaret Sicil Gazetesi v.b. diğer evrakları buradan yükleyebilirsiniz',
      NeedMoreInfo:
        'Daha fazla bilgiye ihtiyacın var ise lütfen bizimle iletişime geç',
      Save: 'Kaydet',
      SendUploadedDocuments: 'Yüklenen Evrakları Gönder',
      IDCopyMaxMinInfo2:
        "Nüfus Cüzdanı / Ehliyet / Pasaport Fotokopisi. Maks/min dosya sayısı 1'dir",
      VUK507ApplicationProcess: 'VUK 507 Başvuru Süreci',
      InteractiveTaxOffice: 'İnteraktif Vergi Dairesi',
      ViewProcess: 'Süreci Görüntüle',
      ReadAndApproveProcess: 'Süreci Okudum onaylıyorum',
      CompleteProcess: 'Süreci Tamamla / Başvuru Yap',
      CompleteApplicationNote:
        'Başvuru Tamamlanması için ekibimizin başvurunuzu incelemesi gerekmektedir. Sonrasında size ulaşılacaktır.',
      CompleteApplication: 'Başvuruyu Tamamla',
      ApplicationUnderReview: 'Başvurunuz İnceleniyor',
      DocumentVerification: 'Evraklarınız Kontrol Ediliyor',
      ContractApproval: 'Sözleşme Onayı',
      ActivationPending: 'Aktivasyon Bekleniyor',
      CongratulationsApplicationReady: 'Tebrikler! Başvurunuz Hazır!',
      SelectDocument: 'Döküman Seçiniz',
    },
    ORDER_DETAIL: {
      Print: 'Yazdır',
      Order: 'Sipariş',
      OrderCode: 'Özel Kodu',
      OrderDate: 'Sipariş Tarihi',
      PaymentType: 'Ödeme Tipi',
      Partial: 'Kısmi',
      OrderSource: 'Sipariş Kaynağı',
      Installment: 'Taksit',
      Actions: 'İşlemler',
      ClientIP: 'Client IP',
      TotalAmount: 'Toplam Tutar',
      Products: 'Ürünler',
      ProductName: 'Ürün Adı',
      Unit: 'Birim',
      Quantity: 'Adet',
      TotalPrice: 'Toplam Fiyat',
      OrderStatus: 'Sipariş Durumu',
      CustomerDetail: 'Müşteri Detayı',
      CustomerName: 'Müşteri Adı',
      ContactInfo: 'İletişim Bilgileri',
      CustomerCode: 'Müşteri Kodu',
      TaxOffice: 'Vergi Dairesi',
      InvoiceDetail: 'Fatura Detayı',
      Subtotal: 'Ara Toplam',
      VATAmount: 'KDV Tutarı',
      Discount: 'İndirim',
      GeneralAmount: 'Genel Toplam',
      AmountDue: 'Alacak Miktarı',
    },
    POS_LIST: {
      PosList: 'Terminaller',
      Definition: 'Tanımı',
      Company: 'Şube',
      AccountCode: 'Hesap Kodu',
      SerialNumber: 'Seri Numarası',
      TerminalID: 'Terminal ID',
      Mode: 'Mod',
      Online: 'Online',
      Status: 'Durumu',
      Active: 'Aktif',
      Passive: 'Pasif',
      OnlineStatus: 'Çevrimiçi',
      OfflineStatus: 'Çevrimdışı',
      TerminalInformation: 'Terminal Bilgileri Görüntüle',
      LoadTerminalParameters: 'Terminal Parametre Yükle',
      GetEndOfDay: 'Gün Sonu Al',
      TerminalDetail: 'Terminal Detayı',
    },
    USERS: {
      User: 'Kullanıcı',
      FullName: 'İsim Soyisim',
      PhoneNumber: 'Telefon Numarası',
      Role: 'Rolü',
      RegistrationDate: 'Kayıt Tarihi',
      Status: 'Durumu',
      UserList: 'Kullanıcılar',
      SearchUser: 'Kullanıcı Ara',
      EMail: 'E-Posta',
    },
    BRANCHES: {
      BranchList: 'Şubeler',
      BranchCode: 'Şube Kodu',
      BranchName: 'Şube Adı',
      Location: 'Konum',
      TerminalS: 'Terminal S',
      Record: 'Kayıt',
      Date: 'Tarihi',
      Status: 'Durumu',
      SearchBranch: 'Şube Ara',
    },
    DAILY_POS_REPORT: {
      UserCashReports: 'Pos Kasa Raporu',
      TotalCashSales: 'TOPLAM KASA SATIŞI',
      TotalCashRefund: 'TOPLAM NAKİT İADESİ',
      date: 'Tarih',
      time: 'Saat',
      terminalNo: 'Terminal No (Cihaz No)',
      user: 'Kullanıcı',
      vehiclePlate: 'Araç Plakası',
      stopInformation: 'Durak Bilgisi',
      totalRevenue: 'Toplam Hasılat',
      userEarnedAmount: 'Kullanıcı Hakediş Tutarı',
      userEarnedDate: 'Kullanıcı Hakediş Tarihi',
    },
    PRODUCT_SALE_REPORT: {
      SearchProduct: 'Ürün Ara',
      OrderList: 'Ürün Satış Raporu',
      ProductName: 'Ürün Adı',
      TaxRate: 'Vergi Oranı',
      Quantity: 'Adet',
      TotalPrice: 'Toplam Fiyat',
    },
    REPORT_MONTHLY: {
      SearchMonthlyReport: 'Aylık Rapor Ara',
      MonthlySalesList: 'Aylık Satış Listesi',
      DocumentType: 'Belge Tipi',
      Branch: 'Şube',
      PaymentType: 'Ödeme Tipi',
      PaymentAgentType: 'Ödeme Aracısı Tipi',
      VATRate: 'KDV Oranı (%)',
      VATAmount: 'KDV Tutarı',
      PaymentAmount: 'Ödeme miktarı',
      PaymentCount: 'Ödeme Sayısı',
      TotalAmount: 'Toplam Tutar',
    },
    REPORT_TURNOVER: {
      SearchSale: 'Satış Ara',
      SelectBranch: 'Şube Seçiniz',
      SelectCashier: 'Kasiyer Seçiniz',
      SelectDocumentType: 'Belge Tipi Seçiniz',
      SelectPOS: 'POS Seçiniz',
      SaleList: 'Belge Listesi',
      POS: 'POS',
      Branch: 'Şube',
      Cashier: 'Kasiyer',
      MerchantDocumentType: 'Belge Tipi',
      DocumentSerialNo: 'B. Seri No',
      PaymentType: 'Ödeme Türü',
      VATRate: 'KDV oranı',
      TotalQuantity: 'T.Ü Adedi',
      Unit: 'Birimi',
      TotalSalesAmount: 'T. Tutar',
      TotalVATAmounts: 'KDV Tutar',
      GeneralTotal: 'Genel Toplam',
      Status: 'Durum',
      Detail: 'Detay',
      DocumentDownload: 'Belge İndir',
      CustomerDetail: 'Müşteri Detayı',
      CustomerName: 'Müşteri Adı',
      INTEGRATOR: 'Entegre ID',
      Company: 'Firma',
      InvoiceDetail: 'Fatura Detayı',
      Payment: 'Ödeme',
      Print: 'Yazdır',
    },
    X_REPORT: {
      SearchXReportNo: 'X Rapor No Ara',
      XReportList: 'X Raporu',
      XReportNo: 'X Rapor No',
      CreationDate: 'Oluşturulma Tarihi',
      Branch: 'Şube',
      Terminal: 'Terminal',
      User: 'Kullanıcı',
      TotalPaymentCount: 'T. Ödeme Sayısı',
      TotalPaymentAmount: 'T. Ödeme Tutarı',
      TotalCancelCount: 'T. İptal Sayısı',
      TotalCancelAmount: 'T. İptal Tutarı',
      FirstPaymentDate: 'İlk Ödeme Tarihi',
      LastPaymentDate: 'Son Ödeme Tarihi',
      Status: 'Durum',
    },
    Z_REPORT: {
      SearchZReportNo: 'Gün Sonu Raporu No Ara',
      EndOfDayReportList: 'Gün Sonu Raporu',
      ZReportNo: 'Gün Sonu Raporu No',
      CreationDate: 'Oluşturulma Tarihi',
      Branch: 'Şube',
      Terminal: 'Terminal',
      User: 'Kullanıcı',
      TotalPaymentCount: 'Toplam Ödeme Sayısı',
      TotalPaymentAmount: 'Toplam Ödeme Tutarı',
      TotalCancelCount: 'Toplam İptal Sayısı',
      TotalCancelAmount: 'Toplam İptal Tutarı',
      Status: 'Durum',
    },
    USER_CASH_REPORT: {
      UserCashReports: 'Kullanıcı Kasa Raporları',
      User: 'Kullanıcı',
      TotalCashSales: 'Toplam Kasa Satışı',
      TotalCashRefund: 'Toplam Nakit İadesi',
    },
    DAILY_CASH_REPORT: {
      DailyCashReportList: 'Günlük Kasa Raporu Listesi',
      TransactionDate: 'İŞLEM TARİHİ',
      TotalCashSales: 'TOPLAM KASA SATIŞI',
      TotalCashRefund: 'TOPLAM NAKİT İADESİ',
    },
    REPORT_COMMISSION: {
      SearchCommission: 'Komisyon Ara',
      CommissionList: 'Komisyon Listesi',
      TransactionDate: 'İşlem Tarihi',
      Branch: 'Şube',
      OrderNumber: 'Sipariş Numarası',
      CollectionType: 'Tahsilat Tipi',
      Installment: 'Taksit',
      Amount: 'Tutar',
      CommissionRate: 'Komisyon Oranı',
      Commission: 'Komisyon',
      NetAmount: 'Net Tutar',
      ValueDate: 'Valör',
    },
    CURRENT_ACCOUNT: {
      SearchCurrent: 'Cari Ara',
      TransactionDate: 'İşlem Tarihi',
      TransactionType: 'İşlem Tipi',
      TransactionDescription: 'İşlem Açıklaması',
      Debt: 'Borç',
      Credit: 'Alacak',
      Balance: 'Bakiye',
      CurrentAccountList: 'Cari Hesap Listesi',
    },
    ORDER: {
      TransactionDate: 'İşlem Tarihi',
      OrderCode: 'Sipariş Kodu',
      SpecialCode: 'Özel Kod',
      Customer: 'Müşteri',
      TotalAmount: 'Toplam Tutar',
      RemainingAmount: 'Kalan Tutar',
      CollectedAmount: 'Tahsil Edilen',
      Status: 'Durumu',
      OrderList: 'Siparişler',
      SearchOrder: 'Sipariş Ara...',
    },
    COLLECTION: {
      OrderDate: 'Sipariş Tarihi',
      PaymentMethod: 'Ödeme Metodu',
      PaymentType: 'Ödeme Tipi',
      PaymentTypeMethod: 'Ödeme Tipi/Metodu',
      OrderCode: 'Sipariş Kodu',
      OrderSpecialCode: 'SIP. Özel Kodu',
      Customer: 'Müşteri',
      TotalAmount: 'Toplam',
      Status: 'Durumu',
      SearchCollection: 'Tahsilat Ara...',
      CollectionList: 'Tahsilatlar',
      SelectCollectionStatus: 'Tahsilat Durumu Seç',
      SelectPaymentType: 'Ödeme Tipi Seç',
      SelectPaymentMethod: 'Ödeme Metodu Seç',
    },
    TRANSACTION_CANCEL: {
      SearchCollection: 'İptal/İade Ara...',
      InvoiceList: 'İptal / İade',
      OrderDate: 'Sipariş Tarihi',
      PaymentMethod: 'Ödeme Metodu',
      PaymentType: 'Ödeme Tipi',
      OrderCode: 'Sipariş Kodu',
      OrderSpecialCode: 'SIP. Özel Kodu',
      Customer: 'Müşteri',
      Status: 'Durumu',
      SelectCollectionStatus: 'Tahsilat Durumu Seç',
      SelectPaymentType: 'Ödeme Tipi Seç',
      SelectPaymentMethod: 'Ödeme Metodu Seç',
    },
    INVOICE: {
      DocumentList: 'Döküman Listesi',
      SearchDocument: 'Döküman Ara...',
      OrderDate: 'Sipariş Tarihi',
      PaymentMethod: 'Ödeme Metodu',
      PaymentType: 'Ödeme Tipi',
      OrderCode: 'Sipariş Kodu',
      OrderSpecialCode: 'SIP. Özel Kodu',
      Customer: 'Müşteri',
      Status: 'Durumu',
      SelectCollectionStatus: 'Tahsilat Durumu Seç',
      SelectPaymentType: 'Ödeme Tipi Seç',
      SelectPaymentMethod: 'Ödeme Metodu Seç',
    },
    STOCK_CATEGORY: {
      CategorySelect: 'Kategori Seçin',
      Categories: 'Liste',
      ProductName: 'Ürün Adı',
      ProductCategory: 'Ürün Kategorisi',
      ProductType: 'Ürün Tipi',
      Unit: 'Birim',
      ProductPrice: 'Ürün Fiyatı (TL)',
      VATRate: 'KDV Oranı (%)',
      ProductPrice2: 'Ürün Fiyatı - 2',
      ProductPrice3: 'Ürün Fiyatı - 3',
      PurchasePrice: 'Satınalma Fiyatı',
      ProductDescription: 'Ürün Açıklaması',
      ProductCode: 'Ürün Kodu',
      Stock: 'Stok',
      StockWarningCount: 'Stok Uyarı Sayısı',
      IsActive: 'Ürün Aktif mi?',
      Save: 'Kaydet',
      CategoryName: 'Kategori Adı',
      CategoryCode: 'Kategori Kodu',
      IsCategoryActive: 'Kategori Aktif mi?',
      EditCategory: 'Kategori Düzenle',
      AddNewCategory: 'Yeni Kategori Ekle',
      NoCategoriesYet: 'Henüz Kategori Oluşturmadınız.',
      CreateCategoryInstructions: 'Kategori oluşturmak için',
      CreateCategoryButton: 'Kategori Oluştur',
      UseButtonBelow: 'Aşağıdaki butonu kullanabilirsiniz',
      NoAddedProductsFound: 'Eklenmiş ürün bulunamadı.',
      VAT: 'KDV',
      Price: 'Fiyat',
      AddNewProduct: 'Yeni Ürün Ekle',
    },
    STOCK_PRODUCT: {
      ProductList: 'Ürünler',
      Product: 'Ürün',
      VAT: 'KDV',
      UnitStock: 'Birim / Stok',
      StockWarning: 'Stok Uyarı',
      IsActive: 'Aktif mi?',
      Operations: 'İşlemler',
      AddNew: 'Yeni Ekle',
      AddNewProduct: 'Yeni Ürün Ekle',
      Warning: 'Uyarı',
      Active: 'Aktif',
      Passive: 'Pasif',
      ProductDetail: 'Ürün Detayı',
      Search: 'Ürün Ara...',
      SelectProductType: 'Ürün Tipi Seçin',
    },
    AUTH: {
      GENERAL: {
        OR: 'veya',
        SUBMIT_BUTTON: 'Gönder',
        NO_ACCOUNT: 'Hesabınız yok mu?',
        SIGNUP_BUTTON: 'Kaydol',
        FORGOT_BUTTON: 'Şifremi Unuttum',
        BACK_BUTTON: 'Geri',
        PRIVACY: 'Gizlilik',
        LEGAL: 'Hukuki',
        CONTACT: 'İletişim',
      },
      LOGIN: {
        TITLE: 'Hesaba Giriş',
        BUTTON: 'Giriş Yap',
        ToOurInternetBranch: 'İnternet Şubemize',
        Welcome: 'Hoşgeldiniz.',
        Now: 'Şimdi',
        Login: 'Giriş Yap',
        LoginInformationIsInvalid: 'Giriş Bilgileri Geçersiz.',
        Branch: 'Şube',
        PhoneNumber: 'Telefon Numarası',
        PhoneRequired: 'Telefon Gerekli',
        PhoneInvalid: 'Telefon Geçersiz',
        PhoneNumberMustBe12CharactersLong:
          'Telefon Numarası 12 Karakter Uzunluğunda Olmalı.',
        Verify: 'Doğrula',
        CreateAccount: 'Hesap Oluştur',
      },
      FORGOT: {
        TITLE: 'Şifrenizi mi Unuttunuz?',
        DESC: 'Şifrenizi sıfırlamak için e-posta adresinizi girin',
        SUCCESS: 'Hesabınız başarıyla sıfırlandı.',
      },
      REGISTER: {
        TITLE: 'Kayıt Ol',
        DESC: 'Hesabınızı oluşturmak için bilgilerinizi girin',
        SUCCESS: 'Hesabınız başarıyla kaydedildi.',
        CreateRegistration: 'Kayıt Oluştur',
        YourName: 'Adınız',
        EMailAddress: 'E-Mail Address',
        CampaignCode: 'Kampanya Kodu',
        BrandCode: 'Marka Kodu',
        PartnerCode: 'İş Ortağı Kodu',
        ClarificationText: 'Aydınlatma metnini',
        And: 've',
        KVKKRules: 'KVKK Kurallarını',
        IReadIApprove: 'okudum, onaylıyorum.',
        CommercialMessageText: 'Ticari ileti metnini',
        CompleteApplication: 'Başvuruyu Tamamla',
        ReturnToLoginScreen: 'Giriş Ekranına Dön',
      },
      INPUT: {
        EMAIL: 'E-posta',
        FULLNAME: 'Ad Soyad',
        PASSWORD: 'Şifre',
        CONFIRM_PASSWORD: 'Şifreyi Onayla',
        USERNAME: 'Kullanıcı Adı',
      },
      VALIDATION: {
        INVALID: '{{name}} geçerli değil',
        REQUIRED: '{{name}} gereklidir',
        MIN_LENGTH: '{{name}} minimum uzunluğu {{min}}',
        AGREEMENT_REQUIRED: 'Koşulları kabul etmek gereklidir',
        NOT_FOUND: 'İstenen {{name}} bulunamadı',
        INVALID_LOGIN: 'Giriş bilgisi yanlış',
        REQUIRED_FIELD: 'Bu alan gereklidir',
        MIN_LENGTH_FIELD: 'Minimum alan uzunluğu:',
        MAX_LENGTH_FIELD: 'Maksimum alan uzunluğu:',
        INVALID_FIELD: 'Alan geçerli değil',
      },
    },
    ECOMMERCE: {
      COMMON: {
        SELECTED_RECORDS_COUNT: 'Seçili kayıt sayısı: ',
        ALL: 'Hepsi',
        SUSPENDED: 'Askıda',
        ACTIVE: 'Aktif',
        FILTER: 'Filtre',
        BY_STATUS: 'duruma göre',
        BY_TYPE: 'türe göre',
        BUSINESS: 'İş',
        INDIVIDUAL: 'Bireysel',
        SEARCH: 'Ara',
        IN_ALL_FIELDS: 'tüm alanlarda',
      },
      ECOMMERCE: 'e-Ticaret',
      CUSTOMERS: {
        CUSTOMERS: 'Müşteriler',
        CUSTOMERS_LIST: 'Müşteri listesi',
        NEW_CUSTOMER: 'Yeni Müşteri',
        DELETE_CUSTOMER_SIMPLE: {
          TITLE: 'Müşteriyi Sil',
          DESCRIPTION:
            'Bu müşteriyi kalıcı olarak silmek istediğinizden emin misiniz?',
          WAIT_DESCRIPTION: 'Müşteri siliniyor...',
          MESSAGE: 'Müşteri silindi',
        },
        DELETE_CUSTOMER_MULTY: {
          TITLE: 'Müşterileri Sil',
          DESCRIPTION:
            'Seçili müşterileri kalıcı olarak silmek istediğinizden emin misiniz?',
          WAIT_DESCRIPTION: 'Müşteriler siliniyor...',
          MESSAGE: 'Seçili müşteriler silindi',
        },
        UPDATE_STATUS: {
          TITLE: 'Seçili müşterilerin durumu güncellendi',
          MESSAGE: 'Seçili müşterilerin durumu başarıyla güncellendi',
        },
        EDIT: {
          UPDATE_MESSAGE: 'Müşteri güncellendi',
          ADD_MESSAGE: 'Müşteri oluşturuldu',
        },
      },
    },
    customer: {
      search: 'Müşteri Ara',
      list: 'Müşteri Listesi',
      name: 'İsim',
      id: 'Kimlik',
      taxOffice: 'Vergi Dairesi',
      phone: 'Telefon',
      email: 'E-posta',
      address: 'Adres',
      city: 'Şehir',
      district: 'İlçe',
      neighborhood: 'Mahalle',
      detail: 'Müşteri Detay',
      addCustomer: 'Müşteri Ekle',
      deleteCustomer: 'Müşteri Sil',
      confirmDeleteCustomer:
        'adlı müşteriyi silmek istediğinizden emin misiniz?',
      account_type: 'Hesap Türü',
      account_tag: 'Hesap Etiketi',
    },
    wallet: {
      list: 'Cüzdan Listesi',
      name: 'Cüzdan Adı',
      balance: 'Bakiye',
      id: 'Kimlik',
      phone: 'Telefon',
      email: 'E-posta',
      taxOffice: 'Vergi Dairesi',
      postcode: 'Posta Kodu',
      addressLine1: 'Adres Satırı',
      addressLine2: 'Adres Satırı 2',
      city: 'Şehir',
      district: 'İlçe',
      neighborhood: 'Mahalle',
      deleteWallet: 'Cüzdan Sil',
      confirmDeleteWallet: 'adlı cüzdanı silmek istediğinizden emin misiniz?',
      editWallet: 'Cüzdanı Düzenle',
      addWallet: 'Cüzdan Ekle',
      walletName: 'Cüzdan Adı',
      type: 'Tipi',
      payLater: 'Sonradan Ödemeli',
      prepaid: 'Ön Ödemeli',
      dailyUsageAmount: 'Günlük Kullanım Adeti',
      dailyLimit: 'Günlük Limiti',
      weeklyLimit: 'Haftalık Limit',
      monthlyLimit: 'Aylık Limit',
      cardType: 'Kart Tipi',
      'wallet+': 'Cüzdan+',
      idCard: 'T.C. Kimlik Kartı',
      specialCard: 'Özel Kart',
      cardNumber: 'Kart Numarası',
      expiryDate: 'Son Kullanma Tarihi',
    },
  },
};
