import { DialogRef } from '@angular/cdk/dialog';
import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-modal-export',
  templateUrl: './modal-export.component.html',
  styles: [],
})
export class ModalExportComponent {
  selectedRadio: 'filters' | 'all' | '' = 'filters';

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public dialogRef: DialogRef<ModalExportComponent>,
  ) {}

  onClose() {
    this.dialogRef.close();
  }
}

export interface DialogData {
  path: string | undefined;
  head: string | undefined;
}
