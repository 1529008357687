import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { da } from 'date-fns/locale';
import { SNACKBAR_CONFIGS } from '../../../services/configs';
import { WalletDetailWelcome } from '../../../services/customers/customers.interface';
import { CustomersService } from '../../../services/customers/customers.service';
import { AtomSnackbarComponent } from '../../components/atom-snackbar/atom-snackbar.component';

@Component({
  selector: 'app-temp-add-wallet',
  templateUrl: './temp-add-wallet.component.html',
  styles: [],
})
export class TempAddWalletComponent implements OnInit {
  walletForm: FormGroup | undefined;
  walletId: string | undefined;

  constructor(
    public dialogRef: MatDialogRef<TempAddWalletComponent>,
    @Inject(MAT_DIALOG_DATA) public data: IAddWalletModal,
    private fb: FormBuilder,
    private angularSnackbar: MatSnackBar,
    private customerService: CustomersService,
    private translate: TranslateService,
  ) {}

  public ngOnInit(): void {
    const data = this.data;
    if (data.wallet_id) {
      this.customerService
        .handleFetchWalletDetail(data.customer_id, data.wallet_id)
        .subscribe((res) => this.initForm(res));
    } else {
      this.initForm();
    }
  }

  initForm(wallet_detail?: WalletDetailWelcome) {
    this.walletForm = undefined;
    this.walletId = wallet_detail?.id || undefined;

    this.walletForm = this.fb.group({
      title: [wallet_detail?.title || '', Validators.required],
      type: [wallet_detail?.type || 'POSTPAID', Validators.required],
      wallet_group: ['CUZDANPLUS', Validators.required],
      balance: [0, Validators.required],
      daily_limit: [wallet_detail?.daily_limit || 0, Validators.required],
      weekly_limit: [wallet_detail?.weekly_limit || 0, Validators.required],
      montly_limit: [wallet_detail?.montly_limit || 0, Validators.required],
      daily_using_limit: [
        wallet_detail?.daily_using_limit || 1,
        Validators.required,
      ],
      is_active: [true],
      expire_date: [wallet_detail?.expire_date || ''],
      card_type: ['CUZDANPLUS', Validators.required],
      card_number: [''],
    });
  }

  submit() {
    let createWalletBody = this.walletForm?.value as any | undefined;

    if (createWalletBody) {
      createWalletBody.card = {
        card_type: createWalletBody.card_type,
        card_number: createWalletBody.card_number,
      };

      delete createWalletBody.card_type;
      delete createWalletBody.card_number;

      this.customerService
        .handleCreateWalletAndUpdate(
          createWalletBody,
          this.data.customer_id,
          this.walletId,
        )
        .subscribe({
          next: () => {
            this.dialogRef.close(this.data);
            this.angularSnackbar.openFromComponent(
              AtomSnackbarComponent,
              SNACKBAR_CONFIGS['topRightSuccess'](
                this.translate.instant('GENERAL.SuccessfullySaved'),
              ),
            );
          },
          error: (error) => {
            this.angularSnackbar.openFromComponent(
              AtomSnackbarComponent,
              SNACKBAR_CONFIGS['topRightError'](error),
            );
          },
        });
    }
  }
}

export const handleAddWalletModal = (
  dialog: MatDialog,
  data: IAddWalletModal,
  callBack: () => void,
) => {
  const dialogRef = dialog.open(TempAddWalletComponent, {
    data,
    width: '650px',
    maxWidth: '650px',
    panelClass: 'metronic-custom-modal',
  });

  dialogRef.afterClosed().subscribe((result) => {
    callBack();
  });
};

export interface IAddWalletModal {
  customer_id: string;
  wallet_id?: string;
}
