import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-atom-select',
  templateUrl: './atom-select.component.html',
  styleUrls: ['./atom-select.component.scss'],
})
export class AtomSelectComponent implements OnInit {
  @Input() label: string = '';
  @Input() data: { id: any; text: string }[] = [];
  @Input() selected: { id: any; text: string } | undefined;
  open: boolean = false;

  public ngOnInit(): void {
    if (this.data.length) {
      this.selected = this.data[0];
    }
  }
}
