import { NgModule } from '@angular/core';
import { GetStatusPipe } from './get-status.pipe';
import { GetPercentagePipe } from './get-percentage.pipe';
import { GetTextLengthPipe } from './get-text-length.pipe';
import { GetPaymentTypePipe } from './get-payment-type.pipe';
import { GetPaymentMethodPipe } from './get-payment-method.pipe';
import { PriceFormatPipe } from './price-format.pipe';
import { GetRolePipe } from './get-role.pipe';
import { FormatListDatesPipe } from './format-list-dates.pipe';

@NgModule({
  imports: [],
  exports: [
    GetStatusPipe,
    GetPercentagePipe,
    GetTextLengthPipe,
    GetPaymentTypePipe,
    GetPaymentMethodPipe,
    PriceFormatPipe,
    GetRolePipe,
    FormatListDatesPipe,
  ],
  declarations: [
    GetStatusPipe,
    GetPercentagePipe,
    GetTextLengthPipe,
    GetPaymentTypePipe,
    GetPaymentMethodPipe,
    PriceFormatPipe,
    GetRolePipe,
    FormatListDatesPipe,
  ],
})
export class PipesModule {}
