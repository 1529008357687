import { Component, OnInit, ViewChild } from '@angular/core';

import {
  ChartComponent,
  ApexAxisChartSeries,
  ApexChart,
  ApexXAxis,
  ApexDataLabels,
  ApexTooltip,
  ApexStroke,
} from 'ng-apexcharts';
import { FiltersService } from '../../../services/filters/filters.service';
import { ReportTurnoverService } from '../../../pages/reports/report-turnover/report-turnover.service';

export type ChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  xaxis: ApexXAxis;
  stroke: ApexStroke;
  tooltip: ApexTooltip;
  dataLabels: ApexDataLabels;
};

@Component({
  selector: 'app-atom-apex-chart',
  templateUrl: './atom-apex-chart.component.html',
  styleUrls: ['./atom-apex-chart.component.scss'],
  providers: [ReportTurnoverService, FiltersService],
})
export class AtomApexChartComponent implements OnInit {
  @ViewChild('chart') chart: ChartComponent;
  public chartOptions!: Partial<ChartOptions>;
  totalAmount: number = 0;

  constructor(
    private reportTurnoverService: ReportTurnoverService,
    public filtersService: FiltersService,
  ) {}

  handleReportTurnoverListFetch() {
    const path = '?page=1&start_date=2024-01-01&end_date=2024-03-03&limit=8';

    if (path)
      this.reportTurnoverService
        .fetchReportTurnoverList(path)
        .subscribe((res) => {
          this.totalAmount = res.total_count;
          this.chartOptions = {
            series: [
              {
                name: 'Amount',
                data: res.data.map((m) => m.total_sales_amount),
                /* data: [31, 40, 28, 51, 42, 109, 100],*/
              },
            ],
            chart: {
              height: 350,
              type: 'area',
            },
            dataLabels: {
              enabled: false,
            },
            stroke: {
              curve: 'smooth',
            },
            xaxis: {
              type: 'datetime',
              /*categories: res.data.map((m) => m.document_date),*/
              categories: [
                '2018-09-19T00:00:00.000Z',
                '2018-09-19T01:30:00.000Z',
                '2018-09-19T02:30:00.000Z',
                '2018-09-19T03:30:00.000Z',
                '2018-09-19T04:30:00.000Z',
                '2018-09-19T05:30:00.000Z',
                '2018-09-19T06:30:00.000Z',
              ],
            },
            tooltip: {
              x: {
                format: 'dd/MM/yy HH:mm',
              },
            },
          };
        });
  }

  public ngOnInit(): void {
    this.handleReportTurnoverListFetch();
  }
}
