import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { ComponentsModule } from "./components/components.module";
import { IconsModule } from "./icons/icons.module";
import { MaterialModule } from "./material/material.module";
import { PipesModule } from "./pipes/pipes.module";
import { TemplatesModule } from "./templates/templates.module";

@NgModule({
  exports     : [
    MaterialModule,
    ComponentsModule,
    TemplatesModule,
    PipesModule,
    IconsModule
  ],
  imports     : [
    CommonModule,
    MaterialModule,
    ComponentsModule,
    TemplatesModule,
    PipesModule,
    IconsModule
  ]
})
export class SharedModule {}
