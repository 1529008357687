export const generateArrayFromNumber = (totalPage: number, currentPage: number, pagesToShow: number = 5): number[] => {
  let startPage = Math.max(1, currentPage - Math.floor(pagesToShow / 2));
  let endPage = Math.min(totalPage, startPage + pagesToShow - 1);

  if (totalPage > pagesToShow) {
    // Başlangıçta gösterilen sayfa numarasının düzeltme işlemi
    if (endPage < pagesToShow) {
      startPage = 1;
    }

    // Sonunda gösterilen sayfa numarasının düzeltme işlemi
    if (endPage === totalPage) {
      startPage = Math.max(1, endPage - pagesToShow + 1);
    }

    // Sayfaların arasındaki üç noktayı ekleyelim
    if (startPage > 1) {
      startPage = Math.max(1, startPage - 1);
      endPage++;
    }

    if (endPage < totalPage) {
      endPage = Math.min(totalPage, endPage + 1);
      startPage--;
    }
  }

  return Array.from({ length: endPage - startPage + 1 }, (_, i) => startPage + i);

}
