import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgIcon } from '@ng-icons/core';
import { TranslateModule } from '@ngx-translate/core';
import { ThemeModeSwitcherComponent } from './theme-mode-switcher.component';

@NgModule({
  declarations: [ThemeModeSwitcherComponent],
  imports: [CommonModule, TranslateModule, NgIcon],
  exports: [ThemeModeSwitcherComponent],
})
export class ThemeModeModule {}
