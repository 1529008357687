<div
  *ngIf="category"
  class="accordion-outer card card-body p-0 {{ opened ? 'opened' : '' }}"
>
  <div class="accordion-header" (click)="onClickCard($event)">
    <span>{{ category.name }}</span>

    <div class="d-flex justify-content-end flex-shrink-0">
      <span class="ki-duotone fs-2 me-1" (click)="handleClickEdit.emit()">
        <ng-icon
          name="heroPencilSquare"
          size="20"
          style="display: contents"
        ></ng-icon>
      </span>

      <span class="ki-duotone fs-2 me-1" (click)="handleClickDelete.emit()">
        <ng-icon name="heroTrash" size="20" style="display: contents"></ng-icon>
      </span>

      <span class="text-gray-600 fs-1">
        <span style="position: relative; top: -2px">
          <ng-icon
            name="heroChevronDown"
            size="16"
            style="display: contents"
          ></ng-icon>
        </span>
      </span>
    </div>
  </div>
  <div class="accordion-content">
    <h2
      class="text-center fs-5 text-gray-500 mt-5 fw-medium"
      *ngIf="!categoryWithProducts?.products?.length"
    >
      <ng-icon name="heroArchiveBoxXMark" size="32"></ng-icon>
      <p>{{ "STOCK_CATEGORY.NoAddedProductsFound" | translate }}</p>
    </h2>
    <div cdkDropList class="drag-drop-list" (cdkDropListDropped)="drop($event)">
      <div
        class="drag-drop-item shadow-none mb-2 border-bottom-dashed border-light-subtle pb-2"
        style="border-width: 1px !important"
        *ngFor="let product of categoryWithProducts?.products"
        cdkDrag
        [cdkDragDisabled]="!enableDragDrop || getBoolean()"
      >
        <div
          class="ps-3 cursor-pointer text-800-hover-outer justify-content-between d-flex align-items-center {{
            selectedProductId === product.id ? 'active' : ''
          }}"
          (click)="handleOnEditProduct(product.id)"
        >
          <div>
            <span
              class="fw-semibold fs-6 text-hover-primary {{
                selectedProductId === product.id
                  ? 'text-primary'
                  : 'text-gray-800'
              }}"
            >
              {{ product.title }}
            </span>
            <span class="text-muted fw-semibold d-block"
              >{{ product.price_with_tax | priceFormat }}
            </span>
          </div>
          <div>
            <div class="d-flex justify-content-end flex-shrink-0">
              <button
                type="button"
                class="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1"
                (click)="handleOnEditProduct(product.id)"
              >
                <ng-icon
                  name="heroPencilSquareMini"
                  size="16"
                  style="display: contents"
                  class="text-gray-600"
                ></ng-icon>
              </button>
              <button
                type="button"
                class="btn btn-icon btn-bg-light btn-active-color-primary btn-sm"
                (click)="handleDeleteProductFunc($event, product.id)"
              >
                <ng-icon
                  name="heroTrashMini"
                  size="16"
                  style="display: contents"
                  class="text-gray-600"
                ></ng-icon>
              </button>
            </div>
            <div class="flex-center" style="justify-content: flex-start">
              <span class="text-muted fw-semibold d-block ms-auto me-2"
                >{{ "STOCK_CATEGORY.VAT" | translate }}: {{ product.tax_rate }}%
              </span>
              <span class="text-muted fw-semibold d-block me-3"
                >{{ "STOCK_CATEGORY.Stock" | translate }}:
                {{ product.stock }}</span
              >
            </div>
          </div>
        </div>
      </div>
    </div>

    <form
      class="d-flex align-items-center mt-4"
      [formGroup]="newProduct"
      *ngIf="newProduct"
    >
      <div class="me-2 w-100">
        <label class="form-label fw-semibold text-gray-900 fs-6">{{
          "STOCK_CATEGORY.ProductName" | translate
        }}</label>
        <input
          type="text"
          class="form-control"
          formControlName="title"
          name="title"
          autocomplete="off"
          (keyup.enter)="handleSave()"
          [ngClass]="getValid('title')"
        />
      </div>
      <div class="w-100 me-2" style="max-width: 120px">
        <label class="form-label fw-semibold text-gray-900 fs-6"
          >{{ "STOCK_CATEGORY.VAT" | translate }} (%)</label
        >
        <select
          class="form-select"
          formControlName="tax_rate"
          name="tax_rate"
          autocomplete="off"
          [ngClass]="getValid('tax_rate')"
        >
          <option [value]="20">%20</option>
          <option [value]="10">%10</option>
          <option [value]="1">%1</option>
          <option [value]="0">%0</option>
        </select>
      </div>
      <div class="w-100" style="max-width: 100px">
        <label class="form-label fw-semibold text-gray-900 fs-6"
          >{{ "STOCK_CATEGORY.Price" | translate }} (₺)</label
        >
        <input
          type="number"
          class="form-control"
          (keyup.enter)="handleSave()"
          formControlName="price"
          name="price"
          autocomplete="off"
          [ngClass]="getValid('price')"
        />
      </div>
    </form>
  </div>
  <div class="accordion-footer">
    <span class="accordion-add-new" (click)="handleAddNewProduct()">{{
      "STOCK_CATEGORY.AddNewProduct" | translate
    }}</span>
  </div>
</div>
