import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  CreateProductBody,
  ProductDetailWelcome,
  ProductsWelcome,
} from './products.interface';

const API_PRODUCTS_PATH = '/products';

@Injectable()
export class ProductsService {
  constructor(private http: HttpClient) {}

  fetchProducts() {
    return this.http.get<ProductsWelcome[]>(API_PRODUCTS_PATH);
  }

  fetchProductsPaginate(path?: string, various?: boolean) {
    const parameters = new URLSearchParams(path);
    parameters.delete('start_date');
    parameters.delete('end_date');
    if (various) parameters.append('various', 'Y');
    return this.http.get<ProductsWelcomeList>(
      '/products-paginate?' + parameters,
    );
  }

  deleteProduct(product_id: string) {
    return this.http.delete(`${API_PRODUCTS_PATH}/${product_id}`);
  }

  handleProductCreateAndUpdate(body: CreateProductBody, product_id?: string) {
    if (product_id) {
      return this.http.put(`${API_PRODUCTS_PATH}/${product_id}`, body);
    }

    return this.http.post(API_PRODUCTS_PATH, body);
  }

  fetchProductDetail(product_id: string) {
    return this.http.get<ProductDetailWelcome>(
      `${API_PRODUCTS_PATH}/${product_id}`,
    );
  }

  favoriteProduct(product_id: string, is_favorite: boolean) {
    return this.http.patch<ProductDetailWelcome>(
      `${API_PRODUCTS_PATH}/${product_id}/favorite`,
      { is_favorite },
    );
  }
}

export interface ProductsWelcomeList {
  total_count: number;
  total_page: number;
  last_page: number;
  prev_page: number;
  next_page: number;
  page: number;
  limit: number;
  data: ProductsWelcome[];
}
