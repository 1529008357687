import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ReportValorWelcomeItem } from '../../../services/reports/reports.types';

@Component({
  selector: 'app-temp-valor-modal',
  templateUrl: './temp-valor-modal.component.html',
  styleUrls: ['./temp-valor-modal.component.scss'],
})
export class TempValorModalComponent {
  constructor(
    public dialogRef: MatDialogRef<TempValorModalComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: { head: string; valor: ReportValorWelcomeItem },
  ) {}

  onClick() {
    this.dialogRef.close();
  }
}
