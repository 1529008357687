import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  Output,
} from '@angular/core';

@Component({
  selector: 'app-atom-timepicker',
  templateUrl: './atom-timepicker.component.html',
  styles: [],
})
export class AtomTimepickerComponent {
  @Input() className: string = '';
  @Input() startTime: string | null;
  @Output() startTimeChange: EventEmitter<string | null> = new EventEmitter<
    string | null
  >();
  @Input() endTime: string | null;
  @Output() endTimeChange: EventEmitter<string | null> = new EventEmitter<
    string | null
  >();
  opened: boolean = false;

  constructor(private elementRef: ElementRef) {}

  @HostListener('document:click', ['$event.target'])
  public onClick(targetElement: any): void {
    const clickedInside = this.elementRef.nativeElement.contains(targetElement);
    if (!clickedInside && this.opened) {
      this.opened = false;
      this.startTimeChange.emit(this.startTime);
      this.endTimeChange.emit(this.endTime);
    }
  }
}
