import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { NgIcon } from "@ng-icons/core";
import { IconAngleDownComponent } from './icon-angle-down/icon-angle-down.component';
import { IconFilterComponent } from './icon-filter/icon-filter.component';
import { IconSearchComponent } from './icon-search/icon-search.component';

@NgModule({
  imports: [CommonModule, NgIcon],
  exports: [IconAngleDownComponent, IconFilterComponent, IconSearchComponent],
  declarations: [
    IconAngleDownComponent,
    IconFilterComponent,
    IconSearchComponent,
  ],
})
export class IconsModule {}
