import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'getRole',
})
export class GetRolePipe implements PipeTransform {
  constructor(private translate: TranslateService) {}

  transform(value: string): string {
    return this.translate.instant(`GENERAL.${value}`);
  }
}
