export const START_END_LOCAL_KEY = 'start-end-local-key';

export const SNACKBAR_CONFIGS: { [key: string]: (text: string) => any } = {
  topRightError(text: string) {
    return {
      duration: 2000,
      horizontalPosition: 'right',
      verticalPosition: 'top',
      data: {
        text,
        variant: 'error',
      },
    };
  },
  topRightSuccess(text: string) {
    return {
      duration: 2000,
      horizontalPosition: 'right',
      verticalPosition: 'top',
      data: {
        text,
        variant: 'success',
      },
    };
  },
  topRightAlert(text: string) {
    return {
      duration: 2000,
      horizontalPosition: 'right',
      verticalPosition: 'top',
      data: {
        text,
        variant: 'alert',
      },
    };
  },
};
