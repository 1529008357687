import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {
  endOfDay,
  endOfMonth,
  format,
  startOfDay,
  startOfMonth,
  startOfWeek,
  startOfYear,
  subDays,
  subMonths,
} from 'date-fns';
import { tr, enGB } from 'date-fns/locale';

@Component({
  selector: 'app-atom-datepicker-v2',
  templateUrl: './atom-datepicker-v2.component.html',
  styleUrls: ['./atom-datepicker-v2.component.scss'],
})
export class AtomDatepickerV2Component implements OnInit {
  @Output() monthChange = new EventEmitter<Date>();
  @Output() clickedClose = new EventEmitter();
  @Output() clickedSave = new EventEmitter<{
    startDate: Date | null;
    endDate: Date | null;
    startTime?: string | null;
    endTime?: string | null;
  }>();
  @Input() readOnly: boolean = false;
  @Input() enableButtons: boolean = false;
  @Input() singleSelect: boolean = false;
  @Input() className: string = '';
  @Input() enableTimePicker: boolean = false;
  @Input() startDateOut: Date | null = null;
  @Input() endDateOut: Date | null = null;

  opened: boolean = false;
  btnClass: string =
    'btn btn-icon btn-custom btn-icon-muted btn-active-light btn-active-color-primary w-35px h-35px w-md-40px h-md-40px';
  /* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - CUSTOM DATE -*/
  allYears: IFilterList[] = [];
  currentMonthName: string = '';
  currentDate = new Date();
  monthNames: string[] = [];

  weekDays: string[] = [];
  calendar: number[][] = [];
  startDate: Date | null = null;
  endDate: Date | null = null;

  startTime: string | undefined;
  endTime: string | undefined;

  handleClickOpen(val: boolean) {
    this.opened = val;
  }

  format = format;
  localeLang: Locale | undefined;
  constructor(
    private elementRef: ElementRef,
    private translate: TranslateService,
  ) {
    this.calculateYears();
    this.weekDays = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'].map(
      (str) => `${this.translate.instant(`DATEPICKER.${str}`)}`,
    );
    this.monthNames = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ].map((str) => `${this.translate.instant(`DATEPICKER.${str}`)}`);
  }

  @HostListener('document:click', ['$event.target'])
  public onClick(targetElement: any): void {
    const clickedInside = this.elementRef.nativeElement.contains(targetElement);
    if (!clickedInside && this.opened) {
      this.opened = false;
    }
  }

  onClickedClose() {
    this.clickedClose.emit();
    this.opened = false;
  }

  onClickedSave() {
    this.clickedSave.emit({
      startDate: this.startDate,
      endDate: this.endDate,
      startTime: this.startTime,
      endTime: this.endTime,
    });
    this.opened = false;
  }

  public ngOnInit(): void {
    this.updateCalendar(new Date());
    this.startDate = this.startDateOut || new Date();
    this.endDate = this.endDateOut || new Date();

    this.translate.onLangChange.subscribe((language) => {
      if (language.lang === 'en') {
        this.localeLang = enGB;
      } else {
        this.localeLang = tr;
      }
    });

    if (this.translate.currentLang === 'en') {
      this.localeLang = enGB;
    } else {
      this.localeLang = tr;
    }
  }

  private calculateYears(): void {
    for (let i = -2; i <= 2; i++) {
      this.allYears.push({
        id: this.currentDate.getFullYear() + i,
        text: String(this.currentDate.getFullYear() + i),
      });
    }
  }

  updateCalendar(currentDate: Date) {
    this.calendar = [];
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth();
    const firstDayOfMonth = new Date(currentYear, currentMonth, 1);
    const lastDayOfMonth = new Date(currentYear, currentMonth + 1, 0);
    this.currentMonthName = this.monthNames[currentMonth];

    const numDaysInMonth = lastDayOfMonth.getDate();
    const firstWeekday = firstDayOfMonth.getDay();

    let dayCounter = 1;

    for (let week = 0; week < 6; week++) {
      const weekArray: number[] = [];
      for (let weekday = 0; weekday < 7; weekday++) {
        if (week === 0 && weekday < firstWeekday) {
          weekArray.push(0);
        } else if (dayCounter > numDaysInMonth) {
          weekArray.push(0);
        } else {
          weekArray.push(dayCounter);
          dayCounter++;
        }
      }
      this.calendar.push(weekArray);
    }
  }

  previousMonth() {
    this.currentDate.setMonth(this.currentDate.getMonth() - 1);
    this.updateCalendar(this.currentDate);
    this.monthChange.emit(this.currentDate);
  }

  nextMonth() {
    this.currentDate.setMonth(this.currentDate.getMonth() + 1);
    this.updateCalendar(this.currentDate);
    this.monthChange.emit(this.currentDate);
  }

  onDayClicked(day: number) {
    if (!day) return;

    const currentYear = this.currentDate.getFullYear();
    const currentMonth = this.currentDate.getMonth();
    const dateToDay = new Date(currentYear, currentMonth, day);

    if (this.singleSelect) {
      this.startDate = dateToDay;
      if (!this.enableButtons) {
        this.clickedSave.emit({
          startDate: this.startDate,
          endDate: this.endDate,
          startTime: this.startTime,
          endTime: this.endTime,
        });
        this.opened = false;
      }
      return;
    }

    if (!this.startDate) {
      this.startDate = dateToDay;
      this.endDate = null;
      return;
    }

    if (!this.startDate || this.endDate) {
      this.startDate = dateToDay;
      this.endDate = null;
      return;
    }

    if (this.startDate === dateToDay) {
      this.startDate = null;
      return;
    }

    if (this.endDate === dateToDay) {
      this.endDate = null;
      return;
    }

    if (!this.startDate || (this.endDate && day < this.startDate.getDate())) {
      this.startDate = dateToDay;
      this.endDate = null;
      return;
    }

    if (this.startDate && !this.endDate) {
      this.endDate = dateToDay;

      if (this.startDate.getDate() > this.endDate.getDate()) {
        this.startDate = this.endDate;
        this.endDate = null;
        return;
      }
    }

    if (!this.enableButtons) {
      this.clickedSave.emit({
        startDate: this.startDate,
        endDate: this.endDate,
        startTime: this.startTime,
        endTime: this.endTime,
      });
      this.opened = false;
    }
  }

  isDayInRange(day: number): boolean {
    if (this.startDate && !this.endDate) {
      const startDate = new Date(this.startDate);
      startDate.setHours(0, 0, 0, 0);

      const currentDate = new Date(this.currentDate);
      currentDate.setDate(day);
      currentDate.setHours(0, 0, 0, 0);

      return currentDate.getTime() === startDate.getTime();
    }

    if (!this.startDate || !this.endDate) {
      return false;
    }

    const startDate = new Date(this.startDate);
    startDate.setHours(0, 0, 0, 0);
    const endDate = new Date(this.endDate);
    endDate.setHours(0, 0, 0, 0);

    const currentDate = new Date(this.currentDate);
    currentDate.setDate(day);
    currentDate.setHours(0, 0, 0, 0);

    return currentDate >= startDate && currentDate <= endDate;
  }

  getDateForInput(startDate: Date, endDate: Date | null) {
    return `${format(startDate, 'dd MMM yyyy', { locale: this.localeLang })} ${
      endDate
        ? `- ${format(endDate, 'dd MMM yyyy', { locale: this.localeLang })}`
        : `- ${this.translate.instant('GENERAL.EndDate')} ?`
    }`;
  }

  handleSetDirectly(
    type:
      | 'today'
      | 'yesterday'
      | 'thisweek'
      | 'thismonth'
      | 'prevmonth'
      | 'thisyear',
  ) {
    const today = new Date();
    this.endDate = new Date();

    const end = (start: Date, end: Date) => {
      if (!this.enableButtons) {
        this.clickedSave.emit({
          startDate: start,
          endDate: end,
          startTime: this.startTime,
          endTime: this.endTime,
        });
        this.opened = false;
      }
    };

    if (type === 'today') {
      this.startDate = startOfDay(today);
      end(this.startDate, this.endDate);
    }

    if (type === 'yesterday') {
      const yesterday = subDays(new Date(), 1);
      this.startDate = startOfDay(yesterday);
      this.endDate = endOfDay(yesterday);
      end(this.startDate, this.endDate);
    }

    if (type === 'thisweek') {
      this.startDate = startOfWeek(today);
      end(this.startDate, this.endDate);
    }

    if (type === 'thismonth') {
      this.startDate = startOfMonth(today);
      end(this.startDate, this.endDate);
    }

    if (type === 'prevmonth') {
      const lastMonth = subMonths(new Date(), 1);
      this.startDate = startOfMonth(lastMonth);
      this.endDate = endOfMonth(lastMonth);
      end(this.startDate, this.endDate);
    }

    if (type === 'thisyear') {
      this.startDate = startOfYear(today);
      end(this.startDate, this.endDate);
    }
  }

  isToday(): boolean {
    return this.startDate && this.endDate
      ? this.startDate.getDate() === this.endDate.getDate() &&
          this.startDate.getDate() === new Date().getDate()
      : false;
  }
}

export interface IFilterList {
  id: number | string;
  text: string;
}
