import {
  Component,
  ElementRef,
  Input,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';

@Component({
  selector: 'app-pluspay-contract-item',
  templateUrl: './pluspay-contract-item.component.html',
  styleUrls: ['./pluspay-contract-item.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class PluspayContractItemComponent {
  @ViewChild('getPrint') getPrint!: ElementRef;
  @Input({ required: true }) page: string;
  @Input({ required: true }) ip: string;
}
