<div class="timer-outer">
  <div class="loading"></div>
  <div class="inside-counter">{{ countdown }}</div>
</div>

<p class="color-blue fz-15 my-4" *ngIf="!onlyTimer">
  {{ "GENERAL.PleaseEnterTheCodeSentToYourMobilePhone" | translate }}
</p>

<div class="otp-code-outer" *ngIf="!onlyTimer">
  <div
    class="gap-item"
    [class.selected]="getIsSelectedEndFilled(i)"
    (click)="focusInputElement()"
    *ngFor="let item of Array(otpCodeLength); let i = index"
  >
    {{ getValueOfOtpCode(i) }}
  </div>
</div>

<form *ngIf="otpForm" [formGroup]="otpForm" class="hide-otp">
  <input
    #otpInput
    type="text"
    class="reset-input"
    name="otp_code"
    formControlName="otp_code"
    (focus)="onInputFocus()"
    (blur)="onInputBlur()"
    mask="000000"
    (keyup.enter)="handleEnter($event)"
  />
</form>
