import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CreateProductBody } from '../products/products.interface';
import {
  CustomerCreateDto,
  CustomerDetailData,
  CustomerListData,
  Wallet,
  WalletDetailWelcome,
} from './customers.interface';

const API_USERS_URL = `/v1/customers`;

@Injectable({ providedIn: 'root' })
export class CustomersService {
  constructor(private http: HttpClient) {}

  fetchCustomerList(path: string) {
    return this.http.get<CustomerListData[]>(`${API_USERS_URL}${path}`);
  }

  fetchCustomerDetail(customer_id: string) {
    return this.http.get<CustomerDetailData>(`${API_USERS_URL}/${customer_id}`);
  }

  handleCustomerCreateAndUpdate(body: CustomerCreateDto, customer_id?: string) {
    if (customer_id)
      return this.http.put(`${API_USERS_URL}/${customer_id}`, body);

    return this.http.post(API_USERS_URL, body);
  }

  handleDeleteCustomer(customer_id: string) {
    return this.http.delete<any>(`${API_USERS_URL}/${customer_id}`);
  }

  fetchWalletList(customer_id: string) {
    return this.http.get<Wallet[]>(`${API_USERS_URL}/${customer_id}/wallets`);
  }

  handleDeleteWallet(customer_id: string, wallet_id: string) {
    return this.http.delete(
      `${API_USERS_URL}/${customer_id}/wallets/${wallet_id}`,
    );
  }

  handleCreateWalletAndUpdate(
    body: any,
    customer_id: string,
    wallet_id?: string,
  ) {
    if (wallet_id)
      return this.http.put(
        `${API_USERS_URL}/${customer_id}/wallets/${wallet_id}`,
        body,
      );

    return this.http.post(`${API_USERS_URL}/${customer_id}/wallets`, body);
  }

  handleFetchWalletDetail(customer_id: string, wallet_id: string) {
    return this.http.get<WalletDetailWelcome>(
      `${API_USERS_URL}/${customer_id}/wallets/${wallet_id}`,
    );
  }
}
