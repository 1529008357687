import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'getTextLength',
})
export class GetTextLengthPipe implements PipeTransform {
  transform(value: string, localLength: number): unknown {
    return value.length < localLength
      ? value
      : value.slice(0, localLength) + '...';
  }
}
