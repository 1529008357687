import { CommonModule } from '@angular/common';
import { NgModule, APP_INITIALIZER } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { FullCalendarModule } from '@fullcalendar/angular';
import { NgIconsModule } from '@ng-icons/core';
import { TranslateModule } from '@ngx-translate/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgApexchartsModule } from 'ng-apexcharts';
import { IConfig, NgxMaskPipe, provideEnvironmentNgxMask } from 'ngx-mask';
import { NgIcons } from './app-constants';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { RequestsInterceptor } from './services/interceptor/requests-interceptor';
import { AuthService } from './modules/auth';
import { SharedModule } from './shared/shared.module';

function appInitializer(authService: AuthService) {
  return () => {
    return new Promise((resolve) => {
      // @ts-ignore
      authService.getUserByFromLocalStorageToken().subscribe().add(resolve);
    });
  };
}

const maskConfig: Partial<IConfig> = {
  validation: false,
};

@NgModule({
  declarations: [AppComponent],
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    TranslateModule.forRoot(),
    HttpClientModule,
    AppRoutingModule,
    SharedModule,
    NgbModule,
    NgxMaskPipe,
    FullCalendarModule,
    NgApexchartsModule,
    NgIconsModule.withIcons(NgIcons),
  ],
  providers: [
    provideEnvironmentNgxMask(maskConfig),
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RequestsInterceptor,
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializer,
      multi: true,
      deps: [AuthService],
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
