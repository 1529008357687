import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  CategoriesWelcome,
  CategoryProductsWelcome,
  IHandleCategory,
} from './categories.interface';

const API_CATEGORY_PATH = '/category';

@Injectable()
export class CategoriesService {
  constructor(private http: HttpClient) {}

  fetchCategories(various?: boolean) {
    const path = API_CATEGORY_PATH + (various ? '?various=Y' : '');
    return this.http.get<CategoriesWelcome[]>(path);
  }

  deleteCategory(category_id: string) {
    return this.http.delete(`${API_CATEGORY_PATH}/${category_id}`);
  }

  handleCategory(body: IHandleCategory, category_id?: string) {
    if (category_id) {
      return this.http.put(`${API_CATEGORY_PATH}/${category_id}`, {
        name: body.name,
        is_active: body.is_active,
        code: body.code,
      });
    } else {
      return this.http.post(API_CATEGORY_PATH, {
        code: body.code,
        name: body.name,
      });
    }
  }

  fetchCategoryProducts(category_id: string) {
    return this.http.get<CategoryProductsWelcome>(
      `${API_CATEGORY_PATH}/${category_id}/products`,
    );
  }

  handleSortCategoryItems(items: string[]) {
    return this.http.patch<CategoryProductsWelcome>(`${API_CATEGORY_PATH}`, {
      items,
    });
  }
}
