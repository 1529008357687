import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'getPaymentType',
})
export class GetPaymentTypePipe implements PipeTransform {
  constructor(private translate: TranslateService) {}

  transform(value: string): string {
    switch (value) {
      case 'POS':
        return 'Pos';
      case 'PAYCELL':
        return 'Paycell';
      case 'PAYE':
        return 'Paye';
      case 'MULTINET':
        return 'Multinet';
      case 'METROPOL':
        return 'Metropol';
      case 'CASH':
        return this.translate.instant('DASHBOARD.Cash');
      case 'ONLINE':
        return 'Online';
      case 'BANK_TRANSFER':
        return this.translate.instant('DASHBOARD.BANK_TRANSFER');
      case 'CIO_CARD':
        return 'Cio Card';
      case 'FASTPAY':
        return 'Fastpay';
      case 'TICKET':
        return 'Ticket';
      case 'SETCARD':
        return 'Setcard';
      case 'SODEXO':
        return 'Sodexo';
      case 'GETIRPAY':
        return 'Getir Pay';
      case 'TOKENFLEX':
        return 'Token Flex';
      case 'YEMEKMATIK':
        return 'Yemek Matik';
      default:
        return '-';
    }
  }
}
