import { Component } from '@angular/core';

@Component({
  selector: 'atom-loading',
  templateUrl: './atom-loading.component.html',
  styleUrls: ['./atom-loading.component.scss']
})
export class AtomLoadingComponent {

}
