import { format } from 'date-fns';
import { tr } from 'date-fns/locale';
export function randomUppercaseNumber() {
  let result = '';
  let characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
  let charactersLength = characters.length;

  for (let i = 0; i < 8; i++) {
    let randomIndex = Math.floor(Math.random() * charactersLength);
    result += characters.charAt(randomIndex);
  }

  return result;
}

export function generateRandom(): string {
  const letters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
  const numbers = '0123456789';
  let random = '';
  for (let i = 0; i < 5; i++) {
    random += letters.charAt(Math.floor(Math.random() * letters.length));
  }
  for (let i = 0; i < 5; i++) {
    random += numbers.charAt(Math.floor(Math.random() * numbers.length));
  }
  return random;
}
