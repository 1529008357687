<div class="position-relative" style="z-index: 9">
  <div
    class="date-picker-container form-control form-control-solid {{
      className
    }}"
    (click)="handleClickOpen(true)"
  >
    <span class="date-picker-span" *ngIf="!singleSelect && !isToday()">{{
      startDate
        ? getDateForInput(startDate, endDate)
        : ("GENERAL.SelectDate" | translate)
    }}</span>
    <span class="date-picker-span" *ngIf="singleSelect && !isToday()">{{
      startDate
        ? format(startDate, "dd MMM yyyy", { locale: localeLang })
        : ("GENERAL.SelectDate" | translate)
    }}</span>

    <span class="date-picker-span" *ngIf="isToday()">{{
      "GENERAL.Today" | translate
    }}</span>
    <ng-icon
      name="heroCalendarDays"
      style="display: contents"
      size="20"
    ></ng-icon>
  </div>

  <div
    class="card date-picker-alignment"
    style="max-width: 400px"
    *ngIf="opened"
  >
    <div class="datepicker-outer">
      <div class="d-flex align-items-center">
        <div>
          <div
            class="fw-bold text-left bg-light py-4 px-3 me-3 rounded-end rounded-start"
          >
            <div
              class="cursor-pointer my-2 hover-scale whitespace"
              (click)="handleSetDirectly('today')"
            >
              {{ "GENERAL.Today" | translate }}
            </div>
            <div
              class="cursor-pointer my-2 hover-scale whitespace"
              (click)="handleSetDirectly('yesterday')"
            >
              {{ "GENERAL.Yesterday" | translate }}
            </div>
            <div
              class="cursor-pointer my-2 hover-scale whitespace"
              (click)="handleSetDirectly('thisweek')"
            >
              {{ "GENERAL.ThisWeek" | translate }}
            </div>
            <div
              class="cursor-pointer my-2 hover-scale whitespace"
              (click)="handleSetDirectly('thismonth')"
            >
              {{ "GENERAL.ThisMonth" | translate }}
            </div>
            <div
              class="cursor-pointer my-2 hover-scale whitespace"
              (click)="handleSetDirectly('prevmonth')"
            >
              {{ "GENERAL.LastMonth" | translate }}
            </div>
            <div
              class="cursor-pointer my-2 hover-scale whitespace"
              (click)="handleSetDirectly('thisyear')"
            >
              {{ "GENERAL.ThisYear" | translate }}
            </div>
          </div>
        </div>
        <div class="w-100">
          <div class="atom-datepicker-header">
            <div [ngClass]="btnClass" (click)="previousMonth()">
              <ng-icon
                name="heroChevronLeftMini"
                size="22"
                style="display: contents"
              ></ng-icon>
            </div>
            <div class="atom-datepicker-header-label">
              {{ currentMonthName }} {{ currentDate.getFullYear() }}
            </div>
            <div [ngClass]="btnClass" (click)="nextMonth()">
              <ng-icon
                name="heroChevronRightMini"
                size="22"
                style="display: contents"
              ></ng-icon>
            </div>
          </div>
          <table class="atom-date-picker-table">
            <thead>
              <tr class="text-center">
                <th *ngFor="let day of weekDays">{{ day }}</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let week of calendar">
                <td
                  *ngFor="let day of week"
                  [class.active]="isDayInRange(day)"
                  [class.read-only]="readOnly"
                  class="atom-date-picker-custom-item"
                >
                  <span
                    class="position-relative"
                    (click)="!readOnly ? onDayClicked(day) : undefined"
                    *ngIf="day !== 0"
                  >
                    {{ day !== 0 ? day : "" }}
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
          <div
            class="d-flex justify-content-between align-items-center"
            *ngIf="enableTimePicker"
          >
            <div>
              <label class="form-label fs-6 fw-semibold">{{
                "GENERAL.Start" | translate
              }}</label>
              <input
                type="time"
                name="time"
                class="form-control bg-transparent py-2 fs-4"
                [(ngModel)]="startTime"
              />
            </div>
            <div>
              <label class="form-label fs-6 fw-semibold">{{
                "GENERAL.End" | translate
              }}</label>
              <input
                type="time"
                name="time"
                class="form-control bg-transparent py-2 fs-4"
                [(ngModel)]="endTime"
              />
            </div>
            <span
              class="text-primary cursor-pointer fs-6"
              (click)="onClickedSave()"
            >
              <ng-icon name="heroChevronRightMini" size="26"></ng-icon>
            </span>
          </div>
        </div>
      </div>
      <div class="d-flex justify-content-end" *ngIf="enableButtons">
        <button class="btn btn-secondary me-4" (click)="onClickedClose()">
          {{ "ORDER.Cancel" | translate }}
        </button>
        <button class="btn btn-primary" (click)="onClickedSave()">
          {{ "ORDER.Confirm" | translate }}
        </button>
      </div>
    </div>
  </div>
</div>
