<div mat-dialog-content class="app-modal-content">
  <div class="my-4">
    <app-atom-timer
      [onlyTimer]="true"
      (handleOnCountDownComplete)="onApprove()"
      [isLogingOut]="true"
    />
  </div>
  <h3 class="card-title fw-bold text-dark mb-0 fs-1 text-center mb-5">
    {{ "GENERAL.signinOut" | translate }}
  </h3>
</div>
