import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'getStatus',
})
export class GetStatusPipe implements PipeTransform {
  constructor(private translate: TranslateService) {}

  transform(value: string): {
    text: string;
    className: string;
  } {
    switch (value) {
      case 'SUCCESS':
        return {
          className: 'badge-light-success',
          text: this.translate.instant('GENERAL.SUCCESS'),
        };
      case 'CANCEL':
        return {
          className: 'badge-light-warning',
          text: this.translate.instant('GENERAL.CANCEL'),
        };
      case 'FAIL':
        return {
          className: 'badge-light-danger',
          text: this.translate.instant('GENERAL.FAIL'),
        };
      case 'WAITING':
        return {
          className: 'badge-light-info',
          text: this.translate.instant('GENERAL.WAITING'),
        };
      default:
        return {
          className: '-',
          text: '-',
        };
    }
  }
}
