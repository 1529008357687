import { Injectable } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { ParametersService } from '../parameters/parameters.service';

@Injectable()
export class FiltersService {
  currentFiltersSubject = new BehaviorSubject<CurrentPageSubject>({
    ...initialFiltersState,
  });

  constructor(private parametersService: ParametersService) {
    this.localDate.get('start')?.valueChanges.subscribe((start) => {
      if (start)
        this.filterStartDateSubject.next(
          this.formatDateToYYYYMMDD(new Date(start))
        );
      this.filterEndDateSubject.next('');
    });
    this.localDate.get('end')?.valueChanges.subscribe((start) => {
      if (start)
        this.filterEndDateSubject.next(
          this.formatDateToYYYYMMDD(new Date(start))
        );
    });
    this.parametersService.getParameters();
  }

  clearAllFilters() {
    const state = { ...initialFiltersState };
    this.currentFiltersSubject.next(state);
  }

  getCurrentFilterByKey(key: CurrentPageSubjectKeys) {
    return this.currentFiltersSubject.getValue()[key];
  }

  setCurrentFilters(key: CurrentPageSubjectKeys, value: any) {
    let generateFilters = this.currentFiltersSubject.getValue();
    generateFilters[key] = value;
    this.currentFiltersSubject.next(generateFilters);
  }

  getCurrentPage(): number {
    return this.getCurrentFilterByKey('page');
  }

  filterStartDateSubject = new BehaviorSubject<string>('');
  filterEndDateSubject = new BehaviorSubject<string>('');
  localDate = new FormGroup({
    start: new FormControl<Date>(this.getFirstDayOfMonth()),
    end: new FormControl<Date>(this.getLastDayOfMonth()),
  });

  getStartDate(): string {
    return this.filterStartDateSubject.getValue();
  }

  getEndDate(): string {
    return this.filterEndDateSubject.getValue();
  }

  getFirstDayOfMonth(): Date {
    const currentDate = new Date();

    this.filterStartDateSubject.next(this.formatDateToYYYYMMDD(currentDate));

    return currentDate;
  }

  getLastDayOfMonth(): Date {
    const currentDate = new Date();

    this.filterEndDateSubject.next(this.formatDateToYYYYMMDD(currentDate));

    return currentDate;
  }

  handleGetPath(params?: HandleGetPath, disablePagination?: boolean): string {
    if (params?.page) this.setCurrentFilters('page', params.page);
    if (params?.order_status)
      this.setCurrentFilters('order_status', params.order_status);

    if (params?.status) this.setCurrentFilters('status', params.status);

    if (disablePagination) {
      this.setCurrentFilters('limit', '0');
    }

    const pageIn = `${disablePagination ? '' : `?page=${params?.page || 1}`}`;

    const startDateInner = this.getStartDate();
    const endDateInner = this.getEndDate();

    let datesPath = '';
    if (startDateInner) {
      const start_date = disablePagination
        ? `?start_date=${startDateInner}`
        : `&start_date=${startDateInner}`;
      const end_date = `&end_date=${endDateInner || startDateInner}`;

      datesPath = `${start_date}${end_date}`;
    }

    let generateFiltersPath = '';
    const currentStateFilters = this.currentFiltersSubject.getValue();

    for (const filterKey in currentStateFilters) {
      if (filterKey !== 'page') {
        const valueInside = this.getCurrentFilterByKey(
          filterKey as CurrentPageSubjectKeys
        );
        const conditions = valueInside && valueInside !== '0';
        if (conditions) {
          generateFiltersPath += `&${filterKey}=${valueInside}`;
        }
      }
    }

    return `${pageIn}${datesPath}${generateFiltersPath}`;
  }

  formatDateToYYYYMMDD(date: Date): string {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');

    return `${year}-${month}-${day}`;
  }
}

const initialFiltersState = {
  page: 1,
  keyword: '',
  payment_type: '0',
  payment_method: '0',
  order_status: '0',
  status: '0',
  transaction_status: '0',
  product_type: '0',
  product_discount: '0',
  product_unit: '0',
  limit: '20',
  branch_id: '0',
  cashier_id: '0',
  serial_no: '0',
  document_type: '0',
  payment_status: '0',
  document_status: '0',
  category: '0',
  special_code: '',
};

export interface HandleGetPath {
  page?: number;
  keyword?: string;
  order_status?: string;
  status?: string;
}

export type CurrentPageSubject = { [key in CurrentPageSubjectKeys]: any } & {
  page: number;
  keyword: string;
  payment_type: string;
  payment_method: string;
  order_status: string;
  transaction_status: string;
  product_type: string;
  product_discount: string;
  product_unit: string;
  status: string;
  limit: string;
  branch_id: string;
  cashier_id: string;
  serial_no: string;
  document_type: string;
  category: string;
  payment_status: string;
  document_status: string;
  special_code: string;
};

export type CurrentPageSubjectKeys =
  | 'page'
  | 'keyword'
  | 'payment_type'
  | 'payment_method'
  | 'order_status'
  | 'transaction_status'
  | 'product_type'
  | 'product_discount'
  | 'product_unit'
  | 'status'
  | 'limit'
  | 'branch_id'
  | 'cashier_id'
  | 'serial_no'
  | 'document_type'
  | 'payment_status'
  | 'document_status'
  | 'category'
  | 'special_code';
