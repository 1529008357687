<!-- begin::Menu toggle -->
<div
  class="btn btn-icon"
  [ngClass]="toggleBtnClass"
  data-kt-menu-trigger="{default: 'click', lg: 'hover'}"
  data-kt-menu-attach="parent"
  data-kt-menu-placement="bottom-end"
>
  <ng-container *ngIf="mode$ | async as mode">
    <ng-container *ngIf="mode === 'dark'">
      <ng-icon name="heroSun" size="22"></ng-icon>
    </ng-container>
    <ng-container *ngIf="mode === 'light'">
      <ng-icon name="heroMoon" size="22"></ng-icon>
    </ng-container>
  </ng-container>
</div>
<!-- end::Menu toggle -->

<!-- begin::Menu -->
<div
  class="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-title-gray-700 menu-icon-muted menu-active-bg menu-state-primary fw-semibold py-4 fs-base w-175px"
  data-kt-menu="true"
>
  <ng-container *ngIf="menuMode$ | async as menuMode">
    <!-- begin::Menu item  -->
    <div class="menu-item px-3 my-0">
      <a
        class="menu-link px-3 py-2"
        [ngClass]="{ active: menuMode === 'light' }"
        (click)="switchMode('light')"
      >
        <span class="menu-icon" data-kt-element="icon">
          <ng-icon name="heroSun" size="22"></ng-icon>
        </span>
        <span class="menu-title">{{ "GENERAL.LightColor" | translate }}</span>
      </a>
    </div>
    <!-- end::Menu item -->

    <!-- begin::Menu item  -->
    <div class="menu-item px-3 my-0">
      <a
        class="menu-link px-3 py-2"
        [ngClass]="{ active: menuMode === 'dark' }"
        (click)="switchMode('dark')"
      >
        <span class="menu-icon" data-kt-element="icon">
          <ng-icon name="heroMoon" size="22"></ng-icon>
        </span>
        <span class="menu-title">{{ "GENERAL.DarkColor" | translate }}</span>
      </a>
    </div>
    <!-- end::Menu item -->

    <!-- begin::Menu item  -->
    <div class="menu-item px-3 my-0">
      <a
        class="menu-link px-3 py-2"
        [ngClass]="{ active: menuMode === 'system' }"
        (click)="switchMode('system')"
      >
        <span class="menu-icon" data-kt-element="icon">
          <ng-icon name="heroComputerDesktop" size="22"></ng-icon>
        </span>
        <span class="menu-title">{{ "GENERAL.SystemColor" | translate }}</span>
      </a>
    </div>
    <!-- end::Menu item -->
  </ng-container>
</div>
<!-- end::Menu -->
