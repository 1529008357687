<mat-tab-group
  *ngIf="currentCustomerId; else customerFormTemplate"
  mat-stretch-tabs="false"
  mat-align-tabs="start"
>
  <mat-tab label="Bilgiler">
    <ng-container *ngTemplateOutlet="customerFormTemplate"></ng-container>
  </mat-tab>
  <mat-tab label="Cüzdanlar">
    <div class="py-3">
      <div class="d-flex justify-content-between align-items-center">
        <h2 class="fs-2 fw-bold">{{ "wallet.list" | translate }}</h2>
        <div class="category-btn-outer">
          <button
            class="btn p-0 text-success"
            (click)="modalOpenWalletDetail()"
            style="height: 40px"
          >
            <ng-icon
              name="heroPlusCircle"
              size="20"
              style="display: contents"
            ></ng-icon>
          </button>
        </div>
      </div>
      <div class="table-responsive">
        <table
          class="table align-middle table-row-dashed table-row-gray-300 gs-0 gy-3"
        >
          <thead class="td-float-top">
            <tr class="fw-semibold text-muted bg-light text-uppercase">
              <th class="ps-4">{{ "wallet.name" | translate }}</th>
              <th>{{ "wallet.balance" | translate }}</th>
              <th class="td-float-right shadow-lg pe-4 bg-l text-end">#</th>
            </tr>
          </thead>

          <tbody class="text-gray-600 fw-semibold">
            <tr *ngFor="let data of walletList || []">
              <td>
                <span class="text-muted fw-semibold text-muted d-block fs-7">
                  {{ data.title }}
                </span>
              </td>
              <td>
                <span class="text-muted fw-semibold text-muted d-block fs-7">
                  {{ data.balance }}
                </span>
              </td>

              <td class="td-float-right shadow-lg">
                <div class="d-flex justify-content-end flex-shrink-0">
                  <button
                    class="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1"
                  >
                    <ng-icon
                      name="heroPencilSquareMini"
                      size="16"
                      style="display: contents"
                      class="text-gray-600"
                      (click)="modalOpenWalletDetail(data.id)"
                    ></ng-icon>
                  </button>
                  <button
                    type="button"
                    class="btn btn-icon bg-light-danger btn-active-color-primary btn-sm text-danger"
                    (click)="modalDeleteWallet(data.id, data.title)"
                  >
                    <ng-icon
                      name="heroTrashMini"
                      size="16"
                      style="display: contents"
                    ></ng-icon>
                  </button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <div *ngIf="!walletList?.length" class="text-center pb-15 px-5">
          <img
            src="./assets/media/3.png"
            alt=""
            class="mw-100 h-200px h-sm-325px"
          />
        </div>
      </div>
    </div>
  </mat-tab>
</mat-tab-group>

<ng-template #customerFormTemplate>
  <form
    novalidate="novalidate"
    class="form mt-5"
    *ngIf="customerForm"
    [formGroup]="customerForm"
    (ngSubmit)="submit()"
  >
    <div class="w-100 px-3">
      <div class="row mb-6">
        <div class="col-lg-6 mb-4">
          <label class="col-form-label required fw-semibold fs-6"
            >{{ "customer.name" | translate }}:</label
          >
          <input
            type="text"
            class="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
            name="name"
            formControlName="name"
            autocomplete="off"
            [ngClass]="{
              'is-invalid': customerForm.controls['name'].invalid,
              'is-valid': customerForm.controls['name'].valid
            }"
          />
        </div>
        <div class="col-lg-6 mb-4">
          <label class="col-form-label required fw-semibold fs-6"
            >{{ "customer.id" | translate }}:</label
          >
          <input
            type="text"
            class="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
            name="identity"
            formControlName="identity"
            autocomplete="off"
            [ngClass]="{
              'is-invalid': customerForm.controls['identity'].invalid,
              'is-valid': customerForm.controls['identity'].valid
            }"
          />
        </div>
        <div class="col-lg-6 mb-4">
          <label class="col-form-label required fw-semibold fs-6"
            >{{ "wallet.phone" | translate }}:</label
          >
          <input
            type="text"
            class="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
            name="phone"
            formControlName="phone"
            autocomplete="off"
            [ngClass]="{
              'is-invalid': customerForm.controls['phone'].invalid,
              'is-valid': customerForm.controls['phone'].valid
            }"
          />
        </div>
        <div class="col-lg-6 mb-4">
          <label class="col-form-label fw-semibold fs-6"
            >{{ "wallet.email" | translate }}:</label
          >
          <input
            type="text"
            class="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
            name="email"
            formControlName="email"
            autocomplete="off"
            [ngClass]="{
              'is-invalid': customerForm.controls['email'].invalid,
              'is-valid': customerForm.controls['email'].valid
            }"
          />
        </div>
        <div class="col-lg-6 mb-4">
          <label class="col-form-label fw-semibold fs-6"
            >{{ "wallet.taxOffice" | translate }}:</label
          >
          <input
            type="text"
            class="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
            name="tax_office"
            formControlName="tax_office"
            autocomplete="off"
            [ngClass]="{
              'is-invalid': customerForm.controls['tax_office'].invalid,
              'is-valid': customerForm.controls['tax_office'].valid
            }"
          />
        </div>
        <div class="col-lg-6 mb-4">
          <label class="col-form-label fw-semibold fs-6"
            >{{ "wallet.postcode" | translate }}:</label
          >
          <input
            type="text"
            class="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
            name="post_code"
            formControlName="post_code"
            autocomplete="off"
            [ngClass]="{
              'is-invalid': customerForm.controls['post_code'].invalid,
              'is-valid': customerForm.controls['post_code'].valid
            }"
          />
        </div>
        <div class="col-12 mb-4">
          <label class="col-form-label required fw-semibold fs-6"
            >{{ "wallet.addressLine1" | translate }}:</label
          >
          <textarea
            type="text"
            class="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
            name="line"
            formControlName="line"
            autocomplete="off"
            [ngClass]="{
              'is-invalid': customerForm.controls['line'].invalid,
              'is-valid': customerForm.controls['line'].valid
            }"
          ></textarea>
        </div>
        <div class="col-12 mb-4">
          <label class="col-form-label fw-semibold fs-6"
            >{{ "wallet.addressLine2" | translate }}:</label
          >
          <textarea
            type="text"
            class="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
            name="line2"
            formControlName="line2"
            autocomplete="off"
            [ngClass]="{
              'is-invalid': customerForm.controls['line2'].invalid,
              'is-valid': customerForm.controls['line2'].valid
            }"
          ></textarea>
        </div>
        <div class="col-lg-4 mb-4">
          <label class="col-form-label fw-semibold fs-6"
            >{{ "wallet.city" | translate }}:</label
          >
          <input
            type="text"
            class="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
            name="city"
            formControlName="city"
            autocomplete="off"
            [ngClass]="{
              'is-invalid': customerForm.controls['city'].invalid,
              'is-valid': customerForm.controls['city'].valid
            }"
          />
        </div>
        <div class="col-lg-4 mb-4">
          <label class="col-form-label fw-semibold fs-6"
            >{{ "wallet.district" | translate }}:</label
          >
          <input
            type="text"
            class="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
            name="district"
            formControlName="district"
            autocomplete="off"
            [ngClass]="{
              'is-invalid': customerForm.controls['district'].invalid,
              'is-valid': customerForm.controls['district'].valid
            }"
          />
        </div>
        <div class="col-lg-4 mb-4">
          <label class="col-form-label fw-semibold fs-6"
            >{{ "wallet.neighborhood" | translate }}:</label
          >
          <input
            type="text"
            class="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
            name="neighbourhood"
            formControlName="neighbourhood"
            autocomplete="off"
            [ngClass]="{
              'is-invalid': customerForm.controls['neighbourhood'].invalid,
              'is-valid': customerForm.controls['neighbourhood'].valid
            }"
          />
        </div>
      </div>
    </div>

    <div class="d-flex justify-content-end py-6">
      <button class="btn btn-primary" type="submit">
        <ng-container *ngIf="!loading">{{
          "STOCK_CATEGORY.Save" | translate
        }}</ng-container>
        <ng-container *ngIf="loading">
          <span class="indicator-progress" [style.display]="'block'"
            >{{ "GENERAL.PleaseWait" | translate }}{{ " " }}
            <span
              class="spinner-border spinner-border-sm align-middle ms-2"
            ></span>
          </span>
        </ng-container>
      </button>
    </div>
  </form>
</ng-template>
