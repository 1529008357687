<div class="p-10">
  <form
    class="form w-100"
    [formGroup]="verifyOtp"
    novalidate="novalidate"
    (ngSubmit)="submit()"
    *ngIf="!isCompleted"
  >
    <div class="login-form-content">
      <div class="form-inner-resizer mt-0">
        <!-- begin::Alert error-->
        <ng-container *ngIf="hasError">
          <div class="mb-lg-15 alert alert-danger">
            <div class="alert-text font-weight-bold">
              {{ "AUTH.LOGIN.LoginInformationIsInvalid" | translate }}
            </div>
          </div>
        </ng-container>
        <!-- end::Alert error-->

        <!--begin::Form group-->
        <div class="fv-row mb-10">
          <label class="form-label fs-6 fw-semibold color-blue">OTP Kodu Giriniz</label>
          <input
            class="form-control bg-transparent fs-2 border-blue"
            name="otp_code"
            formControlName="otp_code"
            mask="000000"
            autocomplete="off"
            [ngClass]="{
              'is-invalid':
                verifyOtp.controls['otp_code'].invalid &&
                verifyOtp.controls['otp_code'].touched,
              'is-valid':
                verifyOtp.controls['otp_code'].valid &&
                verifyOtp.controls['otp_code'].touched
            }"
          />
        </div>
        <!--end::Form group-->

        <button type="submit" class="btn btn-lg bg-cyan w-100 mb-5">
          <ng-container *ngIf="isLoading$ | async">
            <span class="indicator-progress" [style.display]="'block'">
              {{ "GENERAL.PleaseWait" | translate }}
              <span
                class="spinner-border spinner-border-sm align-middle ms-2"
              ></span>
            </span>
          </ng-container>
          <ng-container *ngIf="(isLoading$ | async) === false">
            <span class="indicator-label">Doğrula</span>
          </ng-container>
        </button>
      </div>
    </div>
  </form>

  <form
    class="form w-100"
    [formGroup]="changePinCode"
    novalidate="novalidate"
    (ngSubmit)="submitChangePin()"
    *ngIf="isCompleted"
  >
    <div class="login-form-content">
      <div class="form-inner-resizer mt-0">
        <!-- begin::Alert error-->
        <ng-container *ngIf="hasError">
          <div class="mb-lg-15 alert alert-danger">
            <div class="alert-text font-weight-bold">
              {{ "AUTH.LOGIN.LoginInformationIsInvalid" | translate }}
            </div>
          </div>
        </ng-container>
        <!-- end::Alert error-->

        <!--begin::Form group-->
        <div class="fv-row mb-10">
          <label class="form-label fs-6 fw-semibold color-blue">Yeni PIN Giriniz</label>
          <input
            class="form-control bg-transparent fs-2 border-blue"
            name="pin_code"
            formControlName="pin_code"
            mask="000000"
            autocomplete="off"
            [ngClass]="{
              'is-invalid':
                changePinCode.controls['pin_code'].invalid &&
                changePinCode.controls['pin_code'].touched,
              'is-valid':
                changePinCode.controls['pin_code'].valid &&
                changePinCode.controls['pin_code'].touched
            }"
          />
        </div>
        <!--end::Form group-->

        <button type="submit" class="btn btn-lg bg-cyan w-100 mb-5">
          <ng-container *ngIf="isLoading$ | async">
            <span class="indicator-progress" [style.display]="'block'">
              {{ "GENERAL.PleaseWait" | translate }}
              <span
                class="spinner-border spinner-border-sm align-middle ms-2"
              ></span>
            </span>
          </ng-container>
          <ng-container *ngIf="(isLoading$ | async) === false">
            <span class="indicator-label">Değiştir</span>
          </ng-container>
        </button>
      </div>
    </div>
  </form>
</div>
