import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

const API_TRANSACTION_URL = '/reports';

@Injectable()
export class ReportTurnoverService {
  constructor(private http: HttpClient) {}

  fetchReportTurnoverList(path: string) {
    return this.http.post<ReportTurnoverWelcome>(
      `${API_TRANSACTION_URL}/sales-report${path}`,
      {},
    );
  }
}

export interface ReportTurnoverWelcome {
  page: number;
  limit: number;
  data: ReportTurnoverData[];
  total_count: number;
  total_page: number;
  last_page: number;
  prev_page: number;
  next_page: number;
}

export interface ReportTurnoverData {
  id: string | null;
  serial_no: string;
  pos_id: string;
  integrator_id: string;
  branch: string;
  branch_id: string;
  company_id: string;
  company_name: string;
  cashier: string;
  cashier_id: string;
  document_number: string | null;
  ettn: string | null;
  merchant_document_type: string | null;
  document_date: Date | null;
  payment_info: string;
  payment_type: string;
  payment_method: string;
  total_quantity: number;
  total_sales_amount: number;
  total_vat_amounts: number | null;
  customer_name: string;
  status: string;
  status_title: string;
  document_url: string | null;
  payment_id: string;
  payment_status: string;
}
