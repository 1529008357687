<div class="{{ isFastProduct ? 'card' : '' }}" *ngIf="productForm">
  <div class="{{ isFastProduct ? 'card-body' : '' }}">
    <form
      novalidate="novalidate"
      class="form"
      [formGroup]="productForm"
      (ngSubmit)="submit()"
    >
      <div class="card-body p-9 w-100">
        <div class="row mb-6">
          <div class="col-lg-6 mb-4">
            <label class="col-form-label required fw-semibold fs-6"
              >{{ "STOCK_CATEGORY.ProductName" | translate }}:</label
            >
            <input
              type="text"
              class="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
              name="title"
              formControlName="title"
              autocomplete="off"
              [ngClass]="{
                'is-invalid': productForm.controls['title'].invalid,
                'is-valid': productForm.controls['title'].valid
              }"
            />
          </div>
          <div class="col-lg-6 mb-4" *ngIf="!isVariousProduct">
            <label class="col-form-label fw-semibold fs-6 required"
              >{{ "STOCK_CATEGORY.ProductCategory" | translate }}:</label
            >
            <mat-select
              multiple
              formControlName="categories"
              class="form-control form-control-lg form-control-solid"
              [ngClass]="{
                'is-invalid': productForm.controls['categories'].invalid,
                'is-valid': productForm.controls['categories'].valid
              }"
            >
              <mat-option
                *ngFor="let category of categoryList"
                [value]="category.id"
                [hidden]="!category.is_active"
              >
                {{ category.name }}
              </mat-option>
            </mat-select>
          </div>
          <div class="col-lg-6 mb-4">
            <label class="col-form-label fw-semibold fs-6 required"
              >{{ "STOCK_CATEGORY.ProductType" | translate }}:</label
            >
            <select
              class="form-select form-select-solid form-select-lg"
              name="product_type"
              formControlName="product_type"
              autocomplete="off"
              [ngClass]="{
                'is-invalid': productForm.controls['product_type'].invalid,
                'is-valid': productForm.controls['product_type'].valid
              }"
            >
              <option value="0"></option>
              <option
                [value]="parameter.code"
                *ngFor="let parameter of getProductTypeParameters()"
              >
                {{ parameter.title }}
              </option>
            </select>
          </div>
          <div class="col-lg-6 mb-4" *ngIf="!isVariousProduct">
            <label class="col-form-label fw-semibold fs-6 required"
              >{{ "STOCK_CATEGORY.Unit" | translate }}:</label
            >
            <select
              class="form-select form-select-solid form-select-lg"
              name="unit"
              formControlName="unit"
              autocomplete="off"
              [ngClass]="{
                'is-invalid': productForm.controls['unit'].invalid,
                'is-valid': productForm.controls['unit'].valid
              }"
            >
              <option value="0"></option>
              <option
                [value]="parameter.code"
                *ngFor="let parameter of getProductUnitParameters()"
              >
                {{ parameter.title }}
              </option>
            </select>
          </div>
          <div class="col-lg-6 mb-4" *ngIf="!isVariousProduct">
            <label class="col-form-label fw-semibold fs-6"
              >{{ "STOCK_CATEGORY.ProductPrice" | translate }}:</label
            >
            <input
              type="number"
              class="form-control form-control-lg form-control-solid"
              name="price"
              formControlName="price"
              autocomplete="off"
            />
          </div>
          <div class="col-lg-6 mb-4">
            <label class="col-form-label fw-semibold fs-6"
              >{{ "STOCK_CATEGORY.VATRate" | translate }}:</label
            >
            <select
              class="form-select form-select-lg form-select-solid"
              name="tax_rate"
              formControlName="tax_rate"
              autocomplete="off"
              [ngClass]="{
                'is-invalid': productForm.controls['tax_rate'].invalid,
                'is-valid': productForm.controls['tax_rate'].valid
              }"
            >
              <option [value]="20">%20</option>
              <option [value]="10">%10</option>
              <option [value]="1">%1</option>
            </select>
          </div>
          <div class="col-lg-4 mb-4" *ngIf="!isVariousProduct">
            <label class="col-form-label fw-semibold fs-6"
              >{{ "STOCK_CATEGORY.ProductPrice2" | translate }}:</label
            >
            <input
              type="number"
              class="form-control form-control-lg form-control-solid"
              name="price_1"
              formControlName="price_1"
              autocomplete="off"
            />
          </div>
          <div class="col-lg-4 mb-4" *ngIf="!isVariousProduct">
            <label class="col-form-label fw-semibold fs-6"
              >{{ "STOCK_CATEGORY.ProductPrice3" | translate }}:</label
            >
            <input
              type="number"
              class="form-control form-control-lg form-control-solid"
              name="price_2"
              formControlName="price_2"
              autocomplete="off"
            />
          </div>
          <div class="col-lg-4 mb-4" *ngIf="!isVariousProduct">
            <label class="col-form-label fw-semibold fs-6"
              >{{ "STOCK_CATEGORY.PurchasePrice" | translate }}:</label
            >
            <input
              type="number"
              class="form-control form-control-lg form-control-solid"
              name="purchase_price"
              formControlName="purchase_price"
              autocomplete="off"
            />
          </div>
          <div class="col-lg-12 mb-4" *ngIf="!isVariousProduct">
            <label class="col-form-label fw-semibold fs-6"
              >{{ "STOCK_CATEGORY.ProductDescription" | translate }}:</label
            >
            <textarea
              type="text"
              class="form-control form-control-lg form-control-solid"
              name="description"
              formControlName="description"
              autocomplete="off"
            ></textarea>
          </div>
          <div class="col-lg-6 mb-4">
            <label class="col-form-label required fw-semibold fs-6"
              >{{ "STOCK_CATEGORY.ProductCode" | translate }}:</label
            >
            <input
              type="text"
              class="form-control form-control-lg form-control-solid"
              name="code"
              formControlName="code"
              autocomplete="off"
              [ngClass]="{
                'is-invalid': productForm.controls['code'].invalid,
                'is-valid': productForm.controls['code'].valid
              }"
            />
          </div>
          <div class="col-lg-6 mb-4">
            <label class="col-form-label fw-semibold fs-6">SKU:</label>
            <input
              type="text"
              class="form-control form-control-lg form-control-solid"
              name="sku"
              formControlName="sku"
              autocomplete="off"
            />
          </div>
          <div class="col-lg-6 mb-4" *ngIf="!isVariousProduct">
            <label class="col-form-label fw-semibold fs-6"
              >{{ "STOCK_CATEGORY.Stock" | translate }}:</label
            >
            <input
              type="number"
              class="form-control form-control-lg form-control-solid"
              name="stock"
              formControlName="stock"
              autocomplete="off"
            />
          </div>
          <div class="col-lg-6 mb-4" *ngIf="!isVariousProduct">
            <label class="col-form-label fw-semibold fs-6"
              >{{ "STOCK_CATEGORY.StockWarningCount" | translate }}:</label
            >
            <input
              type="number"
              class="form-control form-control-lg form-control-solid"
              name="stock_alert"
              formControlName="stock_alert"
              autocomplete="off"
            />
          </div>
          <div class="col-lg-6 mb-4" *ngIf="currentProductId">
            <label class="col-form-label fw-semibold fs-6"
              >{{ "STOCK_CATEGORY.IsActive" | translate }}:</label
            >
            <div class="form-check form-check-solid form-switch fv-row">
              <input
                class="form-check-input w-45px h-30px"
                type="checkbox"
                name="is_active"
                formControlName="is_active"
                autocomplete="off"
              />
              <label class="form-check-label"></label>
            </div>
          </div>
        </div>
      </div>

      <div class="card-footer d-flex justify-content-end py-6 px-9">
        <button class="btn btn-primary" type="submit">
          <ng-container *ngIf="!loading">{{
            "STOCK_CATEGORY.Save" | translate
          }}</ng-container>
          <ng-container *ngIf="loading">
            <span class="indicator-progress" [style.display]="'block'"
              >{{ "GENERAL.PleaseWait" | translate }}{{ " " }}
              <span
                class="spinner-border spinner-border-sm align-middle ms-2"
              ></span>
            </span>
          </ng-container>
        </button>
      </div>
    </form>
  </div>
</div>

<div
  class="d-flex align-items-center justify-content-center"
  *ngIf="isFastProduct && !productForm"
>
  <div class="text-center pb-15 px-5">
    <img src="./assets/media/3.png" alt="" class="mw-100 h-200px h-sm-325px" />
  </div>
</div>
