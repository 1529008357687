import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, finalize, map, switchMap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { LoginWelcome, TransactionWelcome, UserWelcome } from './user.model';
import { AuthHTTPService } from './auth-http';

export type TransactionType = TransactionWelcome | undefined;
export type UserType = UserWelcome | undefined;
export type PinCodeTransactionType = {
  transaction_id: 'Zztg2CElVSdw3HsngRzjPzguLxzI42Ok0a4y1KIHzOW8RJEXoZXDxTaNxNOkSW1ENymbvLJNjZtwvh6l4cmvabg3GVH_AZuprdGlMvAmQgE';
  completed: true;
} | undefined;

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private authLocalStorageTokenV2 = `platform.pluspay.com-${environment.USERDATA_KEY}-V2`;
  isLoading$: Observable<boolean>;
  currentLoggedInUserSubject = new BehaviorSubject(<UserType>undefined);
  currentTransactionSubject = new BehaviorSubject(<TransactionType>undefined);
  currentTokenSubject = new BehaviorSubject(<string>'');
  currentPinCodeTransactionSubject = new BehaviorSubject(
    <PinCodeTransactionType>undefined
  );
  isLoadingSubject: BehaviorSubject<boolean>;
  errorMessage = new BehaviorSubject<string>('');
  pinCode = new BehaviorSubject<string>('');

  getPinCode() {
    return this.pinCode.getValue();
  }

  getCurrentLoggedInUser() {
    return this.currentLoggedInUserSubject.getValue();
  }

  getCurrentTransaction() {
    return this.currentTransactionSubject.getValue();
  }

  getCurrentPinCodeTransaction() {
    return this.currentPinCodeTransactionSubject.getValue();
  }

  getCurrentToken() {
    return this.currentTokenSubject.getValue();
  }

  clearTransaction() {
    this.currentTransactionSubject.next(undefined);
  }

  clearPinTransaction() {
    this.currentPinCodeTransactionSubject.next(undefined);
  }

  constructor(
    private authHttpService: AuthHTTPService,
    private router: Router
  ) {
    this.isLoadingSubject = new BehaviorSubject<boolean>(false);
    this.isLoading$ = this.isLoadingSubject.asObservable();
  }

  pinCodeSendOtp(mobile_phone: string): Observable<boolean> {
    this.isLoadingSubject.next(true);
    this.pinCode.next('');
    return this.authHttpService.pinCodeSendOtp(mobile_phone).pipe(
      map((transaction: PinCodeTransactionType) => {
        return this.setPinCodeTransaction(transaction);
      }),
      catchError(() => {
        return of(false);
      }),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }

  verification(branch_id: string, otp_code: string): Observable<LoginWelcome> {
    this.isLoadingSubject.next(true);
    return this.authHttpService
      .verification(
        this.getCurrentTransaction()?.transaction_id,
        branch_id,
        otp_code
      )
      .pipe(
        catchError(() => {
          return of(false);
        }),
        map((login: LoginWelcome) => {
          return this.setTokenToLocalStorageFromAPI(login.token);
        }),
        switchMap(() => this.getUserByFromLocalStorageToken()),
        finalize(() => this.isLoadingSubject.next(false))
      );
  }

  transaction(mobile_phone: string, pin_code: string): Observable<boolean> {
    this.isLoadingSubject.next(true);
    return this.authHttpService.transaction(mobile_phone, pin_code).pipe(
      map((transaction: TransactionType) => {
        return this.setTransaction(transaction);
      }),
      catchError((error) => {
        if (error.includes('pin')) {
          this.errorMessage.next(error);
        } else {
          this.errorMessage.next('');
        }
        return of(false);
      }),
      finalize(() => {
        this.isLoadingSubject.next(false);
      })
    );
  }

  logout() {
    localStorage.removeItem(this.authLocalStorageTokenV2);
    this.router.navigateByUrl('/auth/login');
  }

  getUserByFromLocalStorageToken(): Observable<any> {
    const token = localStorage.getItem(this.authLocalStorageTokenV2);

    if (!token || token === 'undefined' || token === 'false') {
      return of(false);
    }

    const tokenJP = JSON.parse(token);

    this.currentTokenSubject.next(tokenJP);

    this.isLoadingSubject.next(true);

    return this.authHttpService.getLoggedInUserByToken(tokenJP).pipe(
      map((user: UserWelcome) => {
        if (user) {
          this.currentLoggedInUserSubject.next(user);
        } else {
          this.logout();
        }
        return user;
      }),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }

  forgotPassword(email: string): Observable<boolean> {
    this.isLoadingSubject.next(true);
    return this.authHttpService
      .forgotPassword(email)
      .pipe(finalize(() => this.isLoadingSubject.next(false)));
  }

  private setTokenToLocalStorageFromAPI(token: string): boolean {
    try {
      localStorage.setItem(this.authLocalStorageTokenV2, JSON.stringify(token));
      return true;
    } catch (e) {
      return false;
    }
  }

  private setTransaction(transaction: TransactionType): boolean {
    if (transaction && transaction.transaction_id) {
      this.currentTransactionSubject.next(transaction);
      return true;
    }
    return false;
  }

  private setPinCodeTransaction(transaction: PinCodeTransactionType): boolean {
    if (transaction && transaction.transaction_id) {
      this.currentPinCodeTransactionSubject.next(transaction);
      return true;
    }
    return false;
  }

  public changeBranch(branch_id: string) {
    this.authHttpService.changeBranch(branch_id).subscribe((res) => {
      this.currentLoggedInUserSubject.next(res);
      if (res.token) {
        this.setTokenToLocalStorageFromAPI(res.token);
        location.reload();
      }
    });
  }
}
