import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { MatDialogModule } from "@angular/material/dialog";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { MatSnackBarModule } from "@angular/material/snack-bar";

@NgModule({
  imports: [
    CommonModule,
    MatProgressBarModule,
    MatSnackBarModule,
    MatDialogModule
  ],
  exports: [
    MatProgressBarModule,
    MatSnackBarModule,
    MatDialogModule
  ]
})
export class MaterialModule { }
