<div class="position-relative disable-mobile" style="z-index: 9">
  <div
    class="date-picker-container form-control form-control-solid {{
      className
    }}"
    (click)="opened = true"
  >
    <span class="date-picker-span" *ngIf="startTime !== '00:00'">
      {{ startTime }} / {{ endTime }}
    </span>
    <ng-icon name="heroClock" style="display: contents" size="20"></ng-icon>
  </div>

  <div
    id="timePickerAlignment"
    class="card date-picker-alignment"
    style="max-width: 400px"
    *ngIf="opened"
  >
    <div class="datepicker-outer">
      <div class="row">
        <div class="col-6">
          <label class="col-form-label required fw-semibold fs-6 mx-2"
            >{{ "GENERAL.Start" | translate }}:</label
          >
          <input
            type="time"
            name="time"
            class="form-control bg-transparent py-2 fs-4 mx-2"
            [(ngModel)]="startTime"
          />
        </div>
        <div class="col-6">
          <label class="col-form-label required fw-semibold fs-6"
            >{{ "GENERAL.End" | translate }}:</label
          >
          <input
            type="time"
            name="time"
            class="form-control bg-transparent py-2 fs-4"
            [(ngModel)]="endTime"
          />
        </div>
      </div>
    </div>
  </div>
</div>
