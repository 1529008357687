<div class="print-pages-outer">
  <div class="print-pages" #getPrint>
    <div class="flex-between mb-5 header-print page-{{ page }}">
      <img
        alt="Logo"
        src="./assets/media/logos/default.png"
        class="h-40px logo"
      />

      <div class="text-center" style="max-width: 250px">
        <p class="c59">PlusPay üye İşyeri Sözleşmesi & Başvuru Formu</p>
      </div>

      <p>Form-ID : UIS-2023-10</p>
    </div>
    <div>
      <ng-content></ng-content>
    </div>
    <div class="flex-between py-5 mt-auto footer-print">
      <p class="whitespace">Sabit IP: {{ ip }}</p>
      <p class="whitespace">Sayfa {{ page }}</p>
      <p class="whitespace">Kaşe/imza</p>
    </div>
  </div>
</div>
