export class PluspayContractModel {
  constructor(
    public tarihsaat: string,
    public unvan: string,
    public vergi_no: string,
    public yetkili: string,
    public yetkili_tc: string,
    public marka_id: string,
    public POSLIMIT: string,
    public SUBELIMIT: string,
    public firma_id: string,
    public vkn: string,
    public vd: string,
    public tck: string,
    public cep: string,
    public mail: string,
    public merkez_il: string,
    public merkez_ilce: string,
    public merkez_adres: string,
    public toplam_pos: string,
    public PAYCELL_L: string,
    public PAYCELL_K: string,
    public PAYCELL_T: string,
    public PAYCELL_U: string,
    public sube_adet: string,
    public ENT_L: string,
    public ENT_K: string,
    public ENT_T: string,
    public ENT_U: string,
    public EPLUS_L: string,
    public EPLUS_K: string,
    public EPLUS_T: string,
    public EPLUS_U: string,
    public AKTIF_L: string,
    public AKTIF_K: string,
    public AKTIF_T: string,
    public AKTIF_U: string,
    public GENEL_TOPLAM: string,
    public price_h_2: string,
    public price_1_2: string,
    public price_7_2: string,
    public price_15_2: string,
    public price_32_2: string,
    public price_h_3: string,
    public price_1_3: string,
    public price_7_3: string,
    public price_15_3: string,
    public price_32_3: string,
    public price_h_4: string,
    public price_1_4: string,
    public price_7_4: string,
    public price_15_4: string,
    public price_32_4: string,
    public price_h_5: string,
    public price_1_5: string,
    public price_7_5: string,
    public price_15_5: string,
    public price_32_5: string,
    public price_h_6: string,
    public price_1_6: string,
    public price_7_6: string,
    public price_15_6: string,
    public price_32_6: string,
    public price_h_7: string,
    public price_1_7: string,
    public price_7_7: string,
    public price_15_7: string,
    public price_32_7: string,
    public price_h_8: string,
    public price_1_8: string,
    public price_7_8: string,
    public price_15_8: string,
    public price_32_8: string,
    public price_h_9: string,
    public price_1_9: string,
    public price_7_9: string,
    public price_15_9: string,
    public price_32_9: string,
    public price_h_10: string,
    public price_1_10: string,
    public price_7_10: string,
    public price_15_10: string,
    public price_32_10: string,
    public price_h_11: string,
    public price_1_11: string,
    public price_7_11: string,
    public price_15_11: string,
    public price_32_11: string,
    public price_h_12: string,
    public price_1_12: string,
    public price_7_12: string,
    public price_15_12: string,
    public price_32_12: string,
    public MARKA_KODU: string,
    public SUBE_ADI: string,
    public SUBE_IBAN: string,
    public SUBE_YETKILI: string,
    public SUBE_YETKILI_TCKN: string,
    public SUBE_YETKILI_CEP: string,
    public SUBE_YETKILI_MAIL: string,
    public SUBE_TEL: string,
    public SUBE_MAIL: string,
    public SUBE_IL: string,
    public SUBE_ILCE: string,
    public SUBE_ADRES: string,
    public SUBE_POS: string,
    public price_h_yi: string,
    public price_1_yi: string,
    public price_7_yi: string,
    public price_15_yi: string,
    public price_32_yi: string,
    public price_h_yd: string,
    public price_1_yd: string,
    public price_7_yd: string,
    public price_15_yd: string,
    public price_32_yd: string,
    public price_h_tm: string,
    public price_1_tm: string,
    public price_7_tm: string,
    public price_15_tm: string,
    public price_32_tm: string,
  ) {}

  static fromObject(obj: any): PluspayContractModel {
    return new PluspayContractModel(
      'AAAA',
      'AAAA',
      'AAAA',
      'AAAA',
      'AAAA',
      'AAAA',
      'AAAA',
      'AAAA',
      'AAAA',
      'AAAA',
      'AAAA',
      'AAAA',
      'AAAA',
      'AAAA',
      'AAAA',
      'AAAA',
      'AAAA',
      'AAAA',
      'AAAA',
      'AAAA',
      'AAAA',
      'AAAA',
      'AAAA',
      'AAAA',
      'AAAA',
      'AAAA',
      'AAAA',
      'AAAA',
      'AAAA',
      'AAAA',
      'AAAA',
      'AAAA',
      'AAAA',
      'AAAA',
      'AAAA',
      'AAAA',
      'AAAA',
      'AAAA',
      'AAAA',
      'AAAA',
      'AAAA',
      'AAAA',
      'AAAA',
      'AAAA',
      'AAAA',
      'AAAA',
      'AAAA',
      'AAAA',
      'AAAA',
      'AAAA',
      'AAAA',
      'AAAA',
      'AAAA',
      'AAAA',
      'AAAA',
      'AAAA',
      'AAAA',
      'AAAA',
      'AAAA',
      'AAAA',
      'AAAA',
      'AAAA',
      'AAAA',
      'AAAA',
      'AAAA',
      'AAAA',
      'AAAA',
      'AAAA',
      'AAAA',
      'AAAA',
      'AAAA',
      'AAAA',
      'AAAA',
      'AAAA',
      'AAAA',
      'AAAA',
      'AAAA',
      'AAAA',
      'AAAA',
      'AAAA',
      'AAAA',
      'AAAA',
      'AAAA',
      'AAAA',
      'AAAA',
      'AAAA',
      'AAAA',
      'AAAA',
      'AAAA',
      'AAAA',
      'AAAA',
      'AAAA',
      'AAAA',
      'AAAA',
      'AAAA',
      'AAAA',
      'AAAA',
      'AAAA',
      'AAAA',
      'AAAA',
      'AAAA',
      'AAAA',
      'AAAA',
      'AAAA',
      'AAAA',
      'AAAA',
      'AAAA',
      'AAAA',
      'AAAA',
      'AAAA',
      'AAAA',
      'AAAA',
      'AAAA',
      'AAAA',
      'AAAA',
      'AAAA',
      'AAAA',
      'AAAA',
      'AAAA',
    );
  }
}
