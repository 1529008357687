import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CategoriesWelcome } from '../categories/categories.interface';
import { CurrentPageSubjectKeys } from '../filters/filters.service';
import {
  InfoParametersWelcome,
  ParametersWelcome,
  Status,
} from './parameters.interface';
import { parametersStatic } from './parameters.static';

const API_PARAMETERS_PATH = '/parameters';

@Injectable({ providedIn: 'root' })
export class ParametersService {
  parameters = new BehaviorSubject<ParametersWelcome | undefined>(undefined);

  getParameters$() {
    return this.parameters.asObservable();
  }

  parameterByKey(key: string) {
    return this.parameters.getValue()?.[key] || undefined;
  }

  constructor(private http: HttpClient) {}

  getParameters(callBack?: () => void) {
    this.http
      .get<ParametersWelcome>(API_PARAMETERS_PATH)
      .subscribe((parametersAll) => {
        this.http
          .get<CategoriesWelcome[]>('/category')
          .subscribe((categories) => {
            this.http
              .get<InfoParametersWelcome>(`/info${API_PARAMETERS_PATH}`)
              .subscribe((res) => {
                this.parameters.next({
                  ...parametersAll,
                  document_type: parametersAll.document_types,
                  product_type: parametersAll.product_type.filter(
                    (f) => f.code === 'PHYSICALLY' || f.code === 'VIRTUAL',
                  ),
                  branch_id: res.branchs.map((m) => ({
                    code: m.id,
                    title: m.title,
                  })),
                  cashier_id: res.users.map((m) => ({
                    code: m.id,
                    title: m.name,
                  })),
                  serial_no: res.pos.map((m) => ({
                    code: m.id,
                    title: m.title,
                  })),
                  category: categories.map((m) => ({
                    code: m.id,
                    title: m.name,
                  })),
                  ...parametersStatic,
                });
              });
          });

        callBack?.();
      });
  }

  getParametersWithArrayKeys(
    keys: CurrentPageSubjectKeys[],
    parameters: ParametersWelcome | undefined,
  ): { [key: string]: Status[] } {
    const result: { [key: string]: Status[] } = {};

    if (!parameters) return result;

    keys.forEach((key) => {
      if (parameters[key]) {
        result[key] = parameters[key];
      }
    });

    return result;
  }

  getParametersByKeyValue(key: string, value: string): Observable<any> {
    return this.getParameters$().pipe(
      map((parameter) => {
        return (
          parameter?.[key]?.find((f: Status) => f.code === value) || undefined
        );
      }),
    );
  }
}
