import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { generateRandom } from '../../../functions/utils';
import { CategoriesWelcome } from '../../../services/categories/categories.interface';
import { CategoriesService } from '../../../services/categories/categories.service';
import { SNACKBAR_CONFIGS } from '../../../services/configs';
import { CurrentPageSubjectKeys } from '../../../services/filters/filters.service';
import { ParametersWelcome } from '../../../services/parameters/parameters.interface';
import { ParametersService } from '../../../services/parameters/parameters.service';
import {
  CreateProductBody,
  ProductDetailWelcome,
} from '../../../services/products/products.interface';
import { ProductsService } from '../../../services/products/products.service';
import { AtomSnackbarComponent } from '../../components/atom-snackbar/atom-snackbar.component';

@Component({
  selector: 'app-temp-product-form',
  templateUrl: './temp-product-form.component.html',
  styles: [],
  providers: [ProductsService],
})
export class TempProductFormComponent implements OnInit {
  @Input({ required: true }) loading: boolean;
  @Output() handleOnAddedNewProduct = new EventEmitter();
  @Input() isFastProduct: boolean = false;
  @Input() isVariousProduct: boolean = false;

  currentProductId: string | undefined;
  productForm: FormGroup | undefined;
  categoryList: CategoriesWelcome[] = [];
  parameters: ParametersWelcome | undefined;

  constructor(
    private parametersService: ParametersService,
    private categoriesService: CategoriesService,
    private cdr: ChangeDetectorRef,
    private fb: FormBuilder,
    private productsService: ProductsService,
    private angularSnackbar: MatSnackBar,
    private translate: TranslateService,
  ) {}

  public ngOnInit(): void {
    this.parametersService.getParameters();
    this.parametersService.getParameters$().subscribe((res) => {
      this.cdr.detectChanges();
      if (res) this.parameters = res;
    });
    this.categoriesService
      .fetchCategories(this.isVariousProduct)
      .subscribe((res) => {
        this.cdr.detectChanges();
        this.categoryList = this.isVariousProduct
          ? res.filter((f) => f.is_various)
          : res;
      });
  }

  getOneParameter(parameter_key: CurrentPageSubjectKeys) {
    return this.parametersService.getParametersWithArrayKeys(
      [parameter_key],
      this.parameters,
    );
  }

  getProductUnitParameters() {
    return this.getOneParameter('product_unit')?.product_unit || [];
  }

  getProductTypeParameters() {
    return this.getOneParameter('product_type')?.product_type || [];
  }

  handleOpenProductDetail(product_id: string) {
    this.productsService.fetchProductDetail(product_id).subscribe((res) => {
      if (res.id) {
        this.initForm(res);
        this.currentProductId = res.id;

        if (!this.isFastProduct)
          setTimeout(() => {
            const kt_activities_toggle = document.getElementById(
              'kt_activities_toggle',
            );
            kt_activities_toggle?.click();
          }, 100);
      }
    });
  }

  initForm(product_detail?: ProductDetailWelcome) {
    this.productForm = undefined;
    this.currentProductId = product_detail?.id || undefined;

    this.productForm = this.fb.group({
      code: [
        product_detail?.code || generateRandom(),
        Validators.compose([Validators.required, Validators.minLength(3)]),
      ],
      title: [
        product_detail?.title || '',
        Validators.compose([Validators.required, Validators.minLength(3)]),
      ],
      sku: [product_detail?.sku || generateRandom()],
      description: [product_detail?.description || ''],
      unit: [product_detail?.unit || 'ADET', Validators.required],
      product_type: [
        product_detail?.product_type || 'PHYSICALLY',
        Validators.required,
      ],
      tax_rate: [product_detail?.tax_rate || 20, Validators.required],
      purchase_price: [product_detail?.purchase_price || 0],
      price: [product_detail?.price_with_tax || 0],
      price_1: [product_detail?.price_1 || 0],
      price_2: [product_detail?.price_2 || 0],
      stock_alert: [product_detail?.stock_alert || 0],
      stock: [product_detail?.stock || 0],
      categories: [
        this.isVariousProduct
          ? this.categoryList?.map((item) => item.id)
          : product_detail?.categories?.map((item) => item.id) || [],
        Validators.required,
      ],
      is_active: [product_detail?.is_active || true],
      is_various: [this.isVariousProduct],
    });
  }

  submit() {
    const createProductBody = this.productForm?.value as
      | CreateProductBody
      | undefined;

    if (createProductBody) {
      createProductBody.price = Number(
        Number(
          createProductBody.price /
            Number(
              `1.${
                createProductBody.tax_rate === 1
                  ? '01'
                  : createProductBody.tax_rate
              }`,
            ),
        ).toFixed(4),
      );

      this.productsService
        .handleProductCreateAndUpdate(createProductBody, this.currentProductId)
        .subscribe({
          next: () => {
            const kt_activities_close = document.getElementById(
              'kt_activities_close',
            );

            kt_activities_close?.click();
            this.handleOnAddedNewProduct.emit();

            this.angularSnackbar.openFromComponent(
              AtomSnackbarComponent,
              SNACKBAR_CONFIGS['topRightSuccess'](
                this.translate.instant('GENERAL.SuccessfullySaved'),
              ),
            );
          },
          error: (error) => {
            this.angularSnackbar.openFromComponent(
              AtomSnackbarComponent,
              SNACKBAR_CONFIGS['topRightError'](error),
            );
          },
        });
    }
  }
}
