import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { format } from 'date-fns';
import { tr, enGB } from 'date-fns/locale';

@Pipe({
  name: 'formatListDates',
})
export class FormatListDatesPipe implements PipeTransform {
  localeLang: Locale = tr;

  constructor(private translate: TranslateService) {
    translate.onLangChange.subscribe((language) => {
      if (language.lang === 'en') {
        this.localeLang = enGB;
      } else {
        this.localeLang = tr;
      }
    });

    if (this.translate.currentLang === 'en') {
      this.localeLang = enGB;
    } else {
      this.localeLang = tr;
    }
  }

  transform(date: string | Date | null | undefined): string {
    return date
      ? format(new Date(date), 'dd MMM yyyy, HH:mm', {
          locale: this.localeLang,
        })
      : '-';
  }
}
