import { Component, Input } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'atom-date-picker',
  template: `
    <div class="{{ variant }}">
      <mat-form-field class="form--background-grey w-100 disable-hint">
        <mat-date-range-input [formGroup]="parentForm" [rangePicker]="picker">
          <input
            matStartDate
            formControlName="start"
            placeholder="Start date"
          />
          <input matEndDate formControlName="end" placeholder="End date" />
        </mat-date-range-input>
        <mat-datepicker-toggle
          matIconSuffix
          [for]="picker"
        ></mat-datepicker-toggle>
        <mat-date-range-picker #picker></mat-date-range-picker>
      </mat-form-field>
    </div>
  `,
  styles: [],
})
export class AtomDatePickerComponent {
  @Input() parentForm = new FormGroup({
    start: new FormControl(new Date()),
    end: new FormControl(new Date()),
  });
  @Input() variant!: string;
}
