<div class="card-header d-flex align-items-center justify-content-between">
  <h3 class="card-title fw-semibold text-dark mb-0 fs-1">
    {{ data.head }}
  </h3>
  <button mat-dialog-close="" class="btn btn-sm btn-icon">
    <ng-icon
      name="heroXMarkSolid"
      size="22"
      style="display: contents"
    ></ng-icon>
  </button>
</div>
<div mat-dialog-content class="app-modal-content">
  <div class="fs-3 my-5 text-dark" *ngIf="data.valor">
    <div
      class="d-flex align-items-center justify-content-between border-gray-300 pb-2 mb-2 border-bottom-dashed"
      style="border-bottom-width: 1px"
    >
      <div class="font-bold">Şube adı:</div>
      <div>{{ data.valor.branch_name }}</div>
    </div>
    <div
      class="d-flex align-items-center justify-content-between border-gray-300 pb-2 mb-2 border-bottom-dashed"
      style="border-bottom-width: 1px"
    >
      <div class="font-bold">Şube kodu:</div>
      <div>{{ data.valor.branch_code }}</div>
    </div>
    <div
      class="d-flex align-items-center justify-content-between border-gray-300 pb-2 mb-2 border-bottom-dashed"
      style="border-bottom-width: 1px"
    >
      <div class="font-bold">Hesap kodu:</div>
      <div>{{ data.valor.account_code }}</div>
    </div>
    <div
      class="d-flex align-items-center justify-content-between border-gray-300 pb-2 mb-2 border-bottom-dashed"
      style="border-bottom-width: 1px"
    >
      <div class="font-bold">Sonraki değer:</div>
      <div>{{ data.valor.is_next_valor }}</div>
    </div>
    <div
      class="d-flex align-items-center justify-content-between border-gray-300 pb-2 mb-2 border-bottom-dashed"
      style="border-bottom-width: 1px"
    >
      <div class="font-bold">Değer tarihi:</div>
      <div>{{ data.valor.pp_valor_date }}</div>
    </div>
    <div
      class="d-flex align-items-center justify-content-between border-gray-300 pb-2 mb-2 border-bottom-dashed"
      style="border-bottom-width: 1px"
    >
      <div class="font-bold">Şirket adı:</div>
      <div>{{ data.valor.company_name }}</div>
    </div>

    <div
      class="d-flex align-items-center justify-content-between border-gray-300 pb-2 mb-2 border-bottom-dashed"
      style="border-bottom-width: 1px"
    >
      <div class="font-bold">IBAN:</div>
      <div>{{ data.valor.iban }}</div>
    </div>
    <div
      class="d-flex align-items-center justify-content-between border-gray-300 pb-2 mb-2 border-bottom-dashed"
      style="border-bottom-width: 1px"
    >
      <div class="font-bold">Brüt miktar:</div>
      <div>{{ data.valor.gross_amount | currency: "₺" }}</div>
    </div>
    <div
      class="d-flex align-items-center justify-content-between border-gray-300 pb-2 mb-2 border-bottom-dashed"
      style="border-bottom-width: 1px"
    >
      <div class="font-bold">Komisyon miktarı:</div>
      <div>{{ data.valor.pp_commission_amount | currency: "₺" }}</div>
    </div>
    <div
      class="d-flex align-items-center justify-content-between border-gray-300 pb-2 mb-2 border-bottom-dashed"
      style="border-bottom-width: 1px"
    >
      <div class="font-bold">Gönderilebilir miktar:</div>
      <div>{{ data.valor.pp_sendable_amount | currency: "₺" }}</div>
    </div>
    <div
      class="d-flex align-items-center justify-content-between border-gray-300 pb-2 mb-2 border-bottom-dashed"
      style="border-bottom-width: 1px"
    >
      <div class="font-bold">Vergi miktarı:</div>
      <div>{{ data.valor.pp_tax_amount | currency: "₺" }}</div>
    </div>
    <div
      class="d-flex align-items-center justify-content-between border-gray-300 pb-2 mb-2 border-bottom-dashed"
      style="border-bottom-width: 1px"
    >
      <div class="font-bold">Toplam eylem:</div>
      <div>{{ data.valor.total_action }}</div>
    </div>
  </div>
  <div class="separator mb-5"></div>
  <div class="d-flex justify-content-end">
    <button class="btn" mat-dialog-close="">
      {{ "GENERAL.Cancel" | translate }}
    </button>
    <button class="btn btn-primary" (click)="onClick()">
      {{ "GENERAL.Confirm" | translate }}
    </button>
  </div>
</div>
