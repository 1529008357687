import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  CategoriesWelcome,
  CategoryProductsWelcome,
} from '../../../services/categories/categories.interface';

@Component({
  selector: 'app-atom-accordion',
  templateUrl: './atom-accordion.component.html',
  styleUrls: ['./atom-accordion.component.scss'],
})
export class AtomAccordionComponent {
  @Output() handleClickCard = new EventEmitter();
  @Output() handleClickEdit = new EventEmitter();
  @Output() handleClickDelete = new EventEmitter();
  @Output() handleEditProduct = new EventEmitter<string>();
  @Output() handleDeleteProduct = new EventEmitter<string>();
  @Output() handleClickSave = new EventEmitter<any>();
  @Input() opened: boolean = false;
  @Input() categoryWithProducts: CategoryProductsWelcome | undefined;
  @Input() category: CategoriesWelcome | undefined;
  @Input() enableDragDrop: boolean = false;

  newProduct: FormGroup | undefined;
  selectedProductId: string = '';

  constructor(
    private fb: FormBuilder,
    private http: HttpClient,
  ) {}

  handleDeleteProductFunc(e: any, product_id: string) {
    e.stopPropagation();
    this.handleDeleteProduct.emit(product_id);
  }

  drop(event: CdkDragDrop<string[]>) {
    if (this.categoryWithProducts?.products) {
      moveItemInArray(
        this.categoryWithProducts.products,
        event.previousIndex,
        event.currentIndex,
      );

      this.http
        .patch(`/category/${this.categoryWithProducts.id}/products-sortable`, {
          items: this.categoryWithProducts.products.map((item) => item.id),
        })
        .subscribe(() => {});
    }
  }

  getBoolean(): boolean {
    return this.categoryWithProducts
      ? this.categoryWithProducts?.products?.length <= 1
      : false;
  }

  handleOnEditProduct(product_id: string) {
    this.selectedProductId = product_id;
    this.handleEditProduct.emit(product_id);
  }

  onClickCard(event: any) {
    const classNames = event.target.className;

    if (classNames.includes('path')) return;

    this.handleClickCard.emit();
  }

  handleAddNewProduct() {
    const getKdv = this.categoryWithProducts?.products[0]?.tax_rate || 0;

    this.newProduct = this.fb.group({
      title: ['', Validators.required],
      tax_rate: [getKdv, Validators.required],
      price: [0, [Validators.min(1)]],
    });
  }

  handleSave() {
    if (this.newProduct?.valid)
      this.handleClickSave.emit(this.newProduct.value);
  }

  getValid(name: string) {
    return {
      'is-invalid':
        this.newProduct?.controls[name].invalid &&
        this.newProduct?.controls[name].touched,
      'is-valid':
        this.newProduct?.controls[name].valid &&
        this.newProduct?.controls[name].touched,
    };
  }
}
