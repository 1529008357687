export const parametersStatic = {
  status: [
    {
      code: 'CANCEL',
      title: 'İptal',
    },
    {
      code: 'WAITING',
      title: 'Bekliyor',
    },
    {
      code: 'SUCCESS',
      title: 'Başarılı',
    },
    {
      code: 'FAIL',
      title: 'Başarısız',
    },
  ],
  payment_status: [
    {
      code: 'CANCEL',
      title: 'İptal',
    },
    {
      code: 'SUCCESS',
      title: 'Başarılı',
    },
    {
      code: 'FAIL',
      title: 'Başarısız',
    },
  ],
  document_status: [
    {
      code: 'CANCEL',
      title: 'İptal',
    },
    {
      code: 'SUCCESS',
      title: 'Başarılı',
    },
    {
      code: 'FAIL',
      title: 'Başarısız',
    },
    {
      code: 'WAITING',
      title: 'Bekliyor',
    },
  ],
};
