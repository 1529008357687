import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import * as XLSX from 'xlsx';
import { ModalExportComponent } from '../../components/modal-export/modal-export.component';

@Component({
  selector: 'app-tables-widget11',
  templateUrl: './tables-widget11.component.html',
})
export class TablesWidget11Component implements OnInit {
  @Output() handleChangeDate = new EventEmitter<{ start: Date; end: Date }>();
  @Output() handleOnClickAddNew = new EventEmitter();
  @Input() head: string = '';
  @Input() noHeaders: boolean = false;
  @Input() tableVariant!: string;
  @Input() exportList: any[] = [];
  @Input() path!: string;
  @Input() enableDatePicker: boolean = false;
  @Input() disableExport: boolean = false;
  @Input() exportWithPath: boolean = false;
  @Input() enableAddNew: boolean = false;
  @Input() disableOverflow: boolean = false;

  datePickerForm: FormGroup | undefined;

  constructor(
    public dialog: MatDialog,
    private http: HttpClient,
    private fb: FormBuilder,
  ) {}

  ngOnInit(): void {
    if (this.enableDatePicker) {
      this.datePickerForm = this.fb.group({
        start: [new Date()],
        end: [new Date()],
      });

      this.datePickerForm.valueChanges.subscribe((value) => {
        this.handleChangeDate.emit(value);
      });
    }
  }

  handleSaveDates(start: Date | null, end: Date | null) {
    this.datePickerForm?.setValue({ start, end });
  }

  exportXLSX(data: any) {
    if (data.length) {
      const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(data);
      const wb: XLSX.WorkBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Veriler');

      XLSX.writeFile(wb, `${this.head}.xlsx`);
    }
  }

  formatDateToYYYYMMDD(date: Date): string {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');

    return `${year}-${month}-${day}`;
  }

  justExportWithApi() {
    this.http.post(this.path, {}, { responseType: 'blob' }).subscribe((res) => {
      const downloadUrl = window.URL.createObjectURL(res);
      const a = document.createElement('a');
      a.href = downloadUrl;
      a.download = `${this.head}.xlsx`;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(downloadUrl);
      document.body.removeChild(a);
    });
  }

  openDialog() {
    const dialogRef = this.dialog.open(ModalExportComponent, {
      data: {
        head: this.head,
        path: this.path,
      },
    });

    dialogRef.afterClosed().subscribe((res) => {
      if (res === 'all') {
        const currentDate = new Date();
        const firstDayOfMonth = new Date(
          currentDate.getFullYear(),
          currentDate.getMonth(),
          1,
        );

        const lastDayOfMonth = new Date(
          currentDate.getFullYear(),
          currentDate.getMonth() + 1,
          0,
        );

        this.http
          .get<any>(
            `${
              this.path
            }?page=1&limit=1000&start_date=${this.formatDateToYYYYMMDD(
              firstDayOfMonth,
            )}&end_date=${this.formatDateToYYYYMMDD(lastDayOfMonth)}`,
          )
          .subscribe((res) => {
            this.exportXLSX(res?.data || []);
          });
      } else if (res === 'filters') {
        this.exportXLSX(this.exportList || []);
      }
    });
  }
}
