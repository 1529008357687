import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { UserWelcome } from '../user.model';

const API_USERS_URL = `/auth`;

@Injectable({
  providedIn: 'root',
})
export class AuthHTTPService {
  constructor(private http: HttpClient) {}

  // public methods
  login(email: string, password: string): Observable<any> {
    return this.http.post(`${API_USERS_URL}/login`, {
      email,
      password,
    });
  }

  verification(
    transaction_id: string | undefined,
    branch_id: string,
    otp_code: string
  ): Observable<any> {
    return this.http.post(`${API_USERS_URL}/verification`, {
      transaction_id,
      branch_id,
      otp_code,
    });
  }

  transaction(mobile_phone: string, pin_code: string): Observable<any> {
    return this.http.post(`${API_USERS_URL}/login`, { mobile_phone, pin_code });
  }

  forgotPassword(email: string): Observable<boolean> {
    return this.http.post<boolean>(`${API_USERS_URL}/forgot-password`, {
      email,
    });
  }

  getLoggedInUserByToken(token: string): Observable<UserWelcome> {
    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${token}`,
    });

    return this.http.get<UserWelcome>(`${API_USERS_URL}/user`, {
      headers: httpHeaders,
    });
  }

  changeBranch(branch_id: string) {
    return this.http.post<UserWelcome>(`${API_USERS_URL}/change-branch`, {
      branch_id,
    });
  }

  pinCodeSendOtp(phone: string): Observable<any> {
    return this.http.get(`${API_USERS_URL}/pin-code-send-otp?phone=${phone}`);
  }
}
