<div
  *ngIf="!loading"
  class="text-center pb-15 px-5 no-data-resizer fade-in-element show {{
    className
  }}"
>
  <img src="./assets/media/3.png" alt="" class="mw-100 h-200px h-sm-325px" />
</div>
<div
  *ngIf="loading"
  class="h-200px h-sm-325px d-flex align-items-center justify-content-center"
  style="position: absolute; left: 0; right: 0; top: 100px"
>
  <div class="spinner"></div>
</div>
