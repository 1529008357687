import { DialogRef } from '@angular/cdk/dialog';
import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { AuthService } from 'src/app/modules/auth';

@Component({
  selector: 'app-modal-pin',
  templateUrl: './modal-pin.component.html',
  styles: [],
})
export class ModalPinComponent implements OnInit {
  isLoading$: Observable<boolean>;
  verifyOtp: FormGroup;
  changePinCode: FormGroup;
  isCompleted: boolean = false;
  hasError: boolean = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public dialogRef: DialogRef<ModalPinComponent>,
    private fb: FormBuilder,
    private authService: AuthService,
    private http: HttpClient
  ) {
    this.isLoading$ = this.authService.isLoading$;
  }

  ngOnInit(): void {
    this.verifyOtp = this.fb.group({
      otp_code: ['', [Validators.required, Validators.minLength(6)]],
    });
    this.changePinCode = this.fb.group({
      pin_code: ['', [Validators.required, Validators.minLength(6)]],
    });
    this.authService.pinCodeSendOtp(this.data.phone).subscribe();
  }

  submit() {
    this.hasError = false;
    this.authService.isLoadingSubject.next(true);
    if (this.verifyOtp.valid) {
      const transaction = this.transaction();
      const otp_code = this.verifyOtp.get('otp_code')?.value;

      if (transaction && otp_code) {
        this.http
          .get(
            `/auth/verify-otp?transaction_id=${transaction.transaction_id}&otp_code=${otp_code}`
          )
          .subscribe((res: any) => {
            if (res.completed) {
              this.isCompleted = true;
            } else {
              this.hasError = true;
            }
            this.authService.isLoadingSubject.next(false);
          });
      }
    }
  }

  submitChangePin() {
    this.hasError = false;
    this.authService.isLoadingSubject.next(true);
    if (this.changePinCode.valid) {
      const transaction = this.transaction();
      const pin_code = this.changePinCode.get('pin_code')?.value;

      if (transaction && pin_code) {
        this.http
          .put(
            `/auth/update-pin-code?transaction_id=${
              transaction.transaction_id
            }&new_pin_code=${pin_code}&otp_code=${
              this.verifyOtp.get('otp_code')?.value
            }&phone=${this.data.phone}`,
            {}
          )
          .subscribe((res: any) => {
            if (res.completed) {
              this.authService.pinCode.next(pin_code);
              this.dialogRef.close();
            } else {
              this.hasError = true;
            }
            this.authService.isLoadingSubject.next(false);
          });
      }
    }
  }

  transaction() {
    return this.authService.getCurrentPinCodeTransaction();
  }
}

interface DialogData {
  phone: string;
}
