import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'getPaymentMethod',
})
export class GetPaymentMethodPipe implements PipeTransform {
  constructor(private translate: TranslateService) {}

  transform(value: string): string {
    switch (value) {
      case 'QR':
        return this.translate.instant('DASHBOARD.PayWithQR');
      case 'NFC':
        return this.translate.instant('DASHBOARD.PayWithNFC');
      case 'CASH':
        return this.translate.instant('DASHBOARD.Cash');
      case 'CC':
        return this.translate.instant('DASHBOARD.CreditCard');
      case 'NONE':
        return this.translate.instant('DASHBOARD.RdParty');
      default:
        return '-';
    }
  }
}
