<form
  novalidate="novalidate"
  class="form"
  [formGroup]="personnelForm"
  (ngSubmit)="submit()"
  *ngIf="personnelForm"
>
  <div class="card-body p-9 w-100">
    <div class="row mb-6">
      <div class="col-lg-6 mb-4">
        <label class="col-form-label required fw-semibold fs-6"
          >{{ "GENERAL.Name" | translate }}:</label
        >
        <input
          type="text"
          class="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
          name="name"
          formControlName="name"
          autocomplete="off"
          [ngClass]="{
            'is-invalid': personnelForm.controls['name'].invalid,
            'is-valid': personnelForm.controls['name'].valid
          }"
        />
      </div>
      <div class="col-lg-6 mb-4">
        <label class="col-form-label required fw-semibold fs-6"
          >{{ "GENERAL.Phone" | translate }}:</label
        >
        <input
          type="text"
          class="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
          name="phone"
          formControlName="phone"
          autocomplete="off"
          [ngClass]="{
            'is-invalid': personnelForm.controls['phone'].invalid,
            'is-valid': personnelForm.controls['phone'].valid
          }"
        />
      </div>
      <div class="col-lg-6 mb-4">
        <label class="col-form-label required fw-semibold fs-6"
          >{{ "GENERAL.Email" | translate }}:</label
        >
        <input
          type="text"
          class="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
          name="email"
          formControlName="email"
          autocomplete="off"
          [ngClass]="{
            'is-invalid': personnelForm.controls['email'].invalid,
            'is-valid': personnelForm.controls['email'].valid
          }"
        />
      </div>
      <div class="col-lg-6 mb-4">
        <label class="col-form-label fw-semibold fs-6 required"
          >{{ "GENERAL.Role" | translate }}:</label
        >
        <select
          class="form-select form-select-solid form-select-lg"
          name="role"
          formControlName="role"
          autocomplete="off"
          [ngClass]="{
            'is-invalid': personnelForm.controls['role'].invalid,
            'is-valid': personnelForm.controls['role'].valid
          }"
        >
          <option [value]="role" *ngFor="let role of roleList">
            {{ "GENERAL." + role | translate }}
          </option>
        </select>
      </div>
      <div class="col-lg-6 mb-4">
        <label class="col-form-label fw-semibold fs-6 required"
          >{{ "GENERAL.FirmIds" | translate }}:</label
        >
        <mat-select
          multiple
          formControlName="firm_ids"
          class="form-control form-control-lg form-control-solid"
          [ngClass]="{
            'is-invalid': personnelForm.controls['firm_ids'].invalid,
            'is-valid': personnelForm.controls['firm_ids'].valid
          }"
        >
          <mat-option *ngFor="let branch of branchList" [value]="branch.id">
            {{ branch.title }}
          </mat-option>
        </mat-select>
      </div>
      <div class="col-lg-6 mb-4">
        <label class="col-form-label fw-semibold fs-6 required"
          >{{ "GENERAL.Roles" | translate }}:</label
        >
        <mat-select
          multiple
          formControlName="roles"
          class="form-control form-control-lg form-control-solid"
          [ngClass]="{
            'is-invalid': personnelForm.controls['roles'].invalid,
            'is-valid': personnelForm.controls['roles'].valid
          }"
        >
          <mat-option *ngFor="let role of roles" [value]="role">
            {{ "GENERAL." + role | translate }}
          </mat-option>
        </mat-select>
      </div>
      <div class="col-lg-6 mb-4" *ngIf="currentPersonnelId">
        <label class="col-form-label fw-semibold fs-6"
          >{{ "GENERAL.IsActive" | translate }}:</label
        >
        <div class="form-check form-check-solid form-switch fv-row">
          <input
            class="form-check-input w-45px h-30px"
            type="checkbox"
            name="is_active"
            formControlName="is_active"
            autocomplete="off"
          />
          <label class="form-check-label"></label>
        </div>
      </div>
    </div>
  </div>
  <div class="card-footer d-flex justify-content-end py-6 px-9">
    <button class="btn btn-primary" type="submit">
      <ng-container *ngIf="!loading">{{
        "STOCK_CATEGORY.Save" | translate
      }}</ng-container>
      <ng-container *ngIf="loading">
        <span class="indicator-progress" [style.display]="'block'"
          >{{ "GENERAL.PleaseWait" | translate }}{{ " " }}
          <span
            class="spinner-border spinner-border-sm align-middle ms-2"
          ></span>
        </span>
      </ng-container>
    </button>
  </div>
</form>
