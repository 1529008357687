import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'priceFormat',
})
export class PriceFormatPipe implements PipeTransform {
  transform(value: number): string {
    const parts = value.toFixed(2).split('.');
    const integerPart = parts[0].replace(/\d(?=(\d{3})+$)/g, '$&,');
    return `${integerPart}.${parts[1]} ₺`;
  }
}
