<div class="text-end">
  <h1 class="text-dark fw-bolder my-0" style="font-size: 5rem">
    {{ totalAmount || 0 | currency: "₺" }}
  </h1>
</div>

<div
  id="chart"
  *ngIf="
    chartOptions &&
    chartOptions.series &&
    chartOptions.chart &&
    chartOptions.xaxis &&
    chartOptions.stroke &&
    chartOptions.tooltip &&
    chartOptions.dataLabels
  "
>
  <apx-chart
    [series]="chartOptions.series"
    [chart]="chartOptions.chart"
    [xaxis]="chartOptions.xaxis"
    [stroke]="chartOptions.stroke"
    [tooltip]="chartOptions.tooltip"
    [dataLabels]="chartOptions.dataLabels"
  ></apx-chart>
</div>
