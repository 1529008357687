import {
  Component,
  Inject
} from '@angular/core';
import { MAT_SNACK_BAR_DATA } from "@angular/material/snack-bar";

@Component({
  selector: 'app-atom-snackbar',
  templateUrl: './atom-snackbar.component.html',
  styleUrls: ['./atom-snackbar.component.scss']
})
export class AtomSnackbarComponent {
  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: { text: string, variant: string }) { }
}
