<div
  id="kt_activities"
  class="bg-body"
  data-kt-drawer="true"
  data-kt-drawer-name="activities"
  data-kt-drawer-activate="true"
  data-kt-drawer-overlay="true"
  data-kt-drawer-width="{default:'100%', 'lg': '600px'}"
  data-kt-drawer-direction="end"
  data-kt-drawer-toggle="#kt_activities_toggle"
  data-kt-drawer-close="#kt_activities_close"
>
  <div class="card shadow-none rounded-0 w-100">
    <div class="card-header" id="kt_activities_header">
      <h3 class="card-title fw-semibold text-dark">{{ head }}</h3>

      <div class="card-toolbar">
        <button
          type="button"
          class="btn btn-sm btn-icon btn-active-light-primary"
          id="kt_activities_close"
          (click)="handleOnCloseClick()"
        >
          <ng-icon
            name="heroXMarkSolid"
            size="22"
            style="display: contents"
          ></ng-icon>
        </button>
      </div>
    </div>
    <div class="card-body position-relative" id="kt_activities_body">
      <div
        id="kt_activities_scroll"
        class="position-relative scroll-y me-n5 pe-5"
        data-kt-scroll="true"
        data-kt-scroll-height="auto"
        data-kt-scroll-wrappers="#kt_activities_body"
        data-kt-scroll-dependencies="#kt_activities_header, #kt_activities_footer"
        data-kt-scroll-offset="5px"
      >
        <ng-content></ng-content>
      </div>
    </div>
  </div>
</div>
