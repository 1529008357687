import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatTabsModule } from '@angular/material/tabs';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { NgIcon } from '@ng-icons/core';
import { TranslateModule } from '@ngx-translate/core';
import { NgxMaskDirective } from 'ngx-mask';
import { ComponentsModule } from '../components/components.module';
import { MaterialModule } from '../material/material.module';
import { TablesWidget10Component } from './tables-widget10/tables-widget10.component';
import { TablesWidget11Component } from './tables-widget11/tables-widget11.component';
import { TempSideContentComponent } from './temp-side-content/temp-side-content.component';
import { TempApproveModalComponent } from './temp-approve-modal/temp-approve-modal.component';
import { TempProductFormComponent } from './temp-product-form/temp-product-form.component';
import { TempPersonnelFormComponent } from './temp-personnel-form/temp-personnel-form.component';
import { TempCustomerFormComponent } from './temp-customer-form/temp-customer-form.component';
import { TempAddWalletComponent } from './temp-add-wallet/temp-add-wallet.component';
import { TempValorModalComponent } from './temp-valor-modal/temp-valor-modal.component';

@NgModule({
  imports: [
    CommonModule,
    ComponentsModule,
    RouterLink,
    RouterLinkActive,
    MaterialModule,
    FormsModule,
    MatOptionModule,
    MatSelectModule,
    ReactiveFormsModule,
    TranslateModule,
    NgIcon,
    MatTabsModule,
    NgxMaskDirective,
  ],
  declarations: [
    TempSideContentComponent,
    TempApproveModalComponent,
    TempProductFormComponent,
    TablesWidget10Component,
    TablesWidget11Component,
    TempPersonnelFormComponent,
    TempCustomerFormComponent,
    TempAddWalletComponent,
    TempValorModalComponent,
  ],
  exports: [
    TempSideContentComponent,
    TempApproveModalComponent,
    TempProductFormComponent,
    TablesWidget10Component,
    TablesWidget11Component,
    TempPersonnelFormComponent,
    TempCustomerFormComponent,
  ],
})
export class TemplatesModule {}
