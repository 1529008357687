<div class="app-snackbar {{ data.variant }}">
  <img
    [src]="
      './assets/media/icons/' +
      (data.variant === 'success'
        ? 'icon-check-circle-green.svg'
        : data.variant === 'alert'
        ? 'icon-alert-circle-yellow.svg'
        : data.variant === 'error'
        ? 'icon-alert-circle-red.svg'
        : '')
    "
    alt=""
  />
  <div class="ms-1">
    <p class="fw-semibold m-0">
      {{
        data.variant === "success"
          ? ("GENERAL.Success" | translate)
          : data.variant === "alert"
          ? ("GENERAL.Warning" | translate)
          : data.variant === "error"
          ? ("GENERAL.Failure" | translate)
          : ""
      }}
    </p>
    <p class="headline-6 mb-0">{{ data.text }}</p>
  </div>
</div>
