import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { SNACKBAR_CONFIGS } from '../../../services/configs';
import {
  CustomerCreateDto,
  CustomerDetailData,
  Wallet,
} from '../../../services/customers/customers.interface';
import { CustomersService } from '../../../services/customers/customers.service';
import { AtomSnackbarComponent } from '../../components/atom-snackbar/atom-snackbar.component';
import { handleAddWalletModal } from '../temp-add-wallet/temp-add-wallet.component';
import { handleApproveModal } from '../temp-approve-modal/temp-approve-modal.component';

@Component({
  selector: 'app-temp-customer-form',
  templateUrl: './temp-customer-form.component.html',
  styles: [],
})
export class TempCustomerFormComponent implements OnInit {
  @Input({ required: true }) loading: boolean;
  @Output() handleOnAddedNewCustomer = new EventEmitter();

  customerForm: FormGroup | undefined;
  currentCustomerId: string | undefined;
  walletList: Wallet[] = [];

  constructor(
    private customerService: CustomersService,
    private fb: FormBuilder,
    private angularSnackbar: MatSnackBar,
    private translate: TranslateService,
    private dialog: MatDialog,
  ) {}

  public ngOnInit(): void {}

  handleOpenCustomerDetail(customer_id: string) {
    this.customerService.fetchCustomerDetail(customer_id).subscribe((res) => {
      if (res.id) {
        this.initForm(res);
        this.currentCustomerId = res.id;

        if (this.currentCustomerId) this.handleFetchWalletList();

        setTimeout(() => {
          const kt_activities_toggle = document.getElementById(
            'kt_activities_toggle',
          );
          kt_activities_toggle?.click();
        }, 100);
      }
    });
  }

  initForm(customer_detail?: CustomerDetailData) {
    this.customerForm = undefined;
    this.currentCustomerId = customer_detail?.id || undefined;

    this.customerForm = this.fb.group({
      name: [customer_detail?.name || '', Validators.required],
      identity: [customer_detail?.identity || '', Validators.required],
      tax_office: [customer_detail?.tax_office || ''],
      phone: [customer_detail?.phone || '', Validators.required],
      email: [customer_detail?.email || '', Validators.email],
      line: [customer_detail?.address[0]?.line || '', Validators.required],
      line2: [customer_detail?.address[0]?.line2 || ''],
      city: [customer_detail?.address[0]?.city || ''],
      district: [customer_detail?.address[0]?.district || ''],
      neighbourhood: [customer_detail?.address[0]?.neighbourhood || ''],
      post_code: [customer_detail?.address[0]?.post_code || ''],
    });
  }

  submit() {
    const createCustomerBody = this.customerForm?.value as
      | CustomerCreateDto
      | undefined;

    if (createCustomerBody) {
      this.customerService
        .handleCustomerCreateAndUpdate(
          createCustomerBody,
          this.currentCustomerId,
        )
        .subscribe({
          next: () => {
            const kt_activities_close = document.getElementById(
              'kt_activities_close',
            );

            kt_activities_close?.click();
            this.handleOnAddedNewCustomer.emit();

            this.angularSnackbar.openFromComponent(
              AtomSnackbarComponent,
              SNACKBAR_CONFIGS['topRightSuccess'](
                this.translate.instant('GENERAL.SuccessfullySaved'),
              ),
            );
          },
          error: (error) => {
            this.angularSnackbar.openFromComponent(
              AtomSnackbarComponent,
              SNACKBAR_CONFIGS['topRightError'](error),
            );
          },
        });
    }
  }

  handleFetchWalletList() {
    if (this.currentCustomerId) {
      this.customerService
        .fetchWalletList(this.currentCustomerId)
        .subscribe((res) => {
          this.walletList = res;
        });
    }
  }

  modalOpenWalletDetail(wallet_id?: string) {
    if (this.currentCustomerId)
      handleAddWalletModal(
        this.dialog,
        {
          customer_id: this.currentCustomerId,
          wallet_id,
        },
        () => this.handleFetchWalletList(),
      );
  }

  modalDeleteWallet(wallet_id: string, wallet_name: string) {
    if (this.currentCustomerId) {
      handleApproveModal(
        this.dialog,
        {
          head: this.translate.instant('wallet.deleteWallet'),
          content: `(${wallet_name}) ${this.translate.instant(
            'wallet.confirmDeleteWallet',
          )}`,
        },
        () => {
          if (this.currentCustomerId)
            this.customerService
              .handleDeleteWallet(this.currentCustomerId, wallet_id)
              .subscribe(() => this.handleFetchWalletList());
        },
      );
    }
  }
}
