import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  Output,
} from '@angular/core';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'temp-side-content',
  templateUrl: './temp-side-content.component.html',
  styles: [],
})
export class TempSideContentComponent {
  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() onCloseClick = new EventEmitter();
  @Input() head!: string;

  handleOnCloseClick() {
    setTimeout(() => this.onCloseClick.emit(), 500);
  }

  @HostListener('document:click', ['$event'])
  onClickOverlay(event: MouseEvent) {
    const clickedElement = event?.target as HTMLElement | undefined;
    const tagName = clickedElement?.tagName.toLowerCase();
    const elementClass = clickedElement?.className;

    if (tagName === 'div' && elementClass?.includes('drawer-overlay')) {
      this.onCloseClick.emit();
    }
  }
}
