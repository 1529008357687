<div class="calendar-container">
  <div class="calendar-header">
    <button (click)="prevMonth()" class="btn btn-primary">
      {{ getPrevMonthName() }}
    </button>
    <h2>{{ monthNames[currentMonth] }} {{ currentYear }}</h2>
    <button
      (click)="nextMonth()"
      [disabled]="isLastMonth()"
      [ngStyle]="{ opacity: isLastMonth() ? '0.5' : '1' }"
      class="btn btn-primary"
    >
      {{ getNextMonthName() }}
    </button>
  </div>
  <table class="calendar-table">
    <thead>
      <tr>
        <th *ngFor="let day of weekDays">{{ day }}</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let week of calendar">
        <td *ngFor="let day of week" [class.today]="isToday(day)">
          <div
            style="aspect-ratio: 1/1"
            *ngIf="day.date !== 0"
            class="overflow-y-auto"
          >
            {{ day.date }}
            <div
              *ngFor="
                let event of getEventsForDate(day.year, day.month, day.date)
              "
              class="cursor-pointer"
              (click)="handleOpenModal(event)"
              style="
                border-radius: 8px;
                background-color: rgba(12, 30, 51, 0.58);
                color: white;
                padding: 6px;
                margin-bottom: 6px;
              "
            >
              <p style="margin: 0">{{ event.company_name }}</p>
              <p style="margin: 0">{{ event.gross_amount | currency: "₺" }}</p>
            </div>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
</div>
