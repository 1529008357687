import {
  heroChevronLeftMini,
  heroChevronRightMini,
  heroPencilSquareMini,
  heroTrashMini,
} from '@ng-icons/heroicons/mini';
import {
  heroAdjustmentsHorizontal,
  heroArchiveBoxXMark,
  heroArrowLeft,
  heroArrowPath,
  heroArrowRight,
  heroArrowsPointingOut,
  heroBanknotes,
  heroBars3BottomLeft,
  heroCalendarDays,
  heroChartBar,
  heroChartPie,
  heroChevronDown,
  heroClipboardDocumentCheck,
  heroClock,
  heroCloudArrowDown,
  heroCog6Tooth,
  heroCog8Tooth,
  heroComputerDesktop,
  heroCreditCard,
  heroCube,
  heroCurrencyDollar,
  heroEllipsisVertical,
  heroFlag,
  heroFunnel,
  heroHeart,
  heroMagnifyingGlass,
  heroMapPin,
  heroMinusCircle,
  heroMoon,
  heroPencilSquare,
  heroPlusCircle,
  heroPlusSmall,
  heroPrinter,
  heroQuestionMarkCircle,
  heroSun,
  heroTrash,
  heroUserCircle,
  heroUsers,
  heroWallet,
  heroXMark,
} from '@ng-icons/heroicons/outline';
import { heroHeartSolid, heroXMarkSolid } from '@ng-icons/heroicons/solid';

export const NgIcons = {
  heroUsers,
  heroArrowPath,
  heroCube,
  heroCurrencyDollar,
  heroChartPie,
  heroChartBar,
  heroAdjustmentsHorizontal,
  heroClipboardDocumentCheck,
  heroMagnifyingGlass,
  heroSun,
  heroMoon,
  heroCalendarDays,
  heroCog8Tooth,
  heroFunnel,
  heroCloudArrowDown,
  heroPlusSmall,
  heroPlusCircle,
  heroPencilSquare,
  heroTrash,
  heroChevronDown,
  heroPencilSquareMini,
  heroTrashMini,
  heroChevronLeftMini,
  heroChevronRightMini,
  heroXMarkSolid,
  heroMapPin,
  heroXMark,
  heroBars3BottomLeft,
  heroComputerDesktop,
  heroPrinter,
  heroArrowsPointingOut,
  heroEllipsisVertical,
  heroQuestionMarkCircle,
  heroWallet,
  heroBanknotes,
  heroCog6Tooth,
  heroArchiveBoxXMark,
  heroHeart,
  heroHeartSolid,
  heroClock,
  heroFlag,
  heroUserCircle,
  heroArrowRight,
  heroArrowLeft,
  heroMinusCircle,
  heroCreditCard,
};
