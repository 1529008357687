import { Injectable } from '@angular/core';
import JsPDF from 'jspdf';
import * as html2image from 'html-to-image';

@Injectable({
  providedIn: 'root',
})
export class PdfService {
  pdf: JsPDF | undefined;
  loading: boolean = false;

  generatePdf(htmlContent: HTMLElement[], fileName: string) {
    if (this.pdf) {
      this.pdf.save(fileName);
    } else {
      this.loading = true;

      const pdf = new JsPDF('p', 'mm', 'a4', true);
      const images = async () => {
        for (const element of htmlContent) {
          const image = await html2image.toPng(element, { quality: 10 });
          pdf.addImage(image, 'PNG', 10, 10, 186, 287);
          pdf.addPage();
        }
      };

      images().then(() => {
        this.pdf = pdf;
        pdf.deletePage(htmlContent.length + 1);
        pdf.save(fileName);
        this.loading = false;
      });
    }
  }
}
