import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { AuthService } from '../../modules/auth';
import { LoadingService } from '../loading/loading.service';

const BASE_URL = environment.apiUrl;

@Injectable()
export class RequestsInterceptor implements HttpInterceptor {
  constructor(
    private authService: AuthService,
    private loadingService: LoadingService,
  ) {}

  setHeaders(request: HttpRequest<any>) {
    return request.clone({
      url: BASE_URL + request.url,
      setHeaders: {
        Authorization: `Bearer ${this.authService.getCurrentToken()}`,
      },
    });
  }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    this.loadingService.setLoading(true, request.url);

    return next
      .handle(this.setHeaders(request))
      .pipe(
        catchError((error: HttpErrorResponse) => {
          this.loadingService.setLoading(false, request.url);

          if (error.status === 401) {
            this.authService.logout();
          }

          const message =
            error?.error?.message ||
            error?.error?.detail ||
            error.message ||
            error;

          return throwError(() => {
            return message;
          });
        }),
      )
      .pipe(
        map<HttpEvent<any>, any>((evt: HttpEvent<any>) => {
          if (evt instanceof HttpResponse) {
            this.loadingService.setLoading(false, request.url);
          }
          return evt;
        }),
      );
  }
}
