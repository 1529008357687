import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-atom-timer',
  templateUrl: './atom-timer.component.html',
  styleUrls: ['./atom-timer.component.scss'],
})
export class AtomTimerComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('otpInput') otpInput!: ElementRef<HTMLInputElement>;
  @Output() handleOnCountDownComplete = new EventEmitter();
  @Output() handleOtpChange = new EventEmitter();
  @Output() handleOnEnter = new EventEmitter();
  @Input() handleSetCountDown: number | undefined;
  @Input() onlyTimer: boolean = false;
  @Input() isLogingOut: boolean = false;
  otpInputFocused = false;
  otpCodeLength = 6;
  countdown: number = 60;

  private timeoutId: any;
  private interval: any;

  otpForm: FormGroup | undefined;

  constructor(
    private fb: FormBuilder,
    private titleService: Title,
    private translate: TranslateService,
  ) {}

  public ngOnInit(): void {
    this.startCountdown();

    if (!this.onlyTimer) {
      this.otpForm = this.fb.group({
        otp_code: [''],
      });

      this.otpForm?.valueChanges?.subscribe(({ otp_code }) => {
        this.handleOtpChange.emit(otp_code);
        if (otp_code.length === this.otpCodeLength) this.handleOnEnter.emit();
      });
    }
  }

  handleEnter(e: any) {
    e.preventDefault();
    this.handleOnEnter.emit();
  }

  public ngOnDestroy(): void {
    if (this.timeoutId) clearTimeout(this.timeoutId);
    if (this.interval) clearInterval(this.interval);
  }

  public ngAfterViewInit(): void {
    this.focusInputElement();
  }

  startCountdown() {
    this.countdown = this.handleSetCountDown || 60;
    clearInterval(this.interval);
    clearInterval(this.timeoutId);

    if (this.isLogingOut) {
      this.titleService.setTitle(
        `${this.translate.instant('GENERAL.signinOut')} (${
          this.countdown
        }sn) - Pluspay TR`,
      );
    }

    this.timeoutId = setTimeout(() => {
      this.interval = setInterval(() => {
        this.countdown = this.countdown - 1;

        if (this.isLogingOut) {
          this.titleService.setTitle(
            `${this.translate.instant('GENERAL.signinOut')} (${
              this.countdown
            }sn) - Pluspay TR`,
          );
        }

        if (this.countdown <= 0) {
          clearInterval(this.interval);
          this.onCountdownComplete();
        }
      }, 1000);
    }, 5);
  }

  onCountdownComplete() {
    this.handleOnCountDownComplete.emit();
  }

  getIsSelectedEndFilled(countLength: number) {
    return (
      this.otpForm?.get('otp_code')?.value.length === countLength &&
      this.otpInputFocused
    );
  }

  getValueOfOtpCode(countLength: number) {
    return String(this.otpForm?.get('otp_code')?.value)[countLength];
  }

  focusInputElement() {
    this.otpInput.nativeElement.focus();
  }

  onInputFocus() {
    this.otpInputFocused = true;
  }

  onInputBlur() {
    this.otpInputFocused = false;
  }

  protected readonly Array = Array;
}
