import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-table-thead',
  template: `
    <thead class="td-float-top">
      <app-table-thead-tr>
        <th
          *ngFor="let item of items; let last = last; let i = index"
          class="{{ last ? 'px-4 text-end rounded-top-end' : 'ps-4' }} {{
            i === 0 ? 'rounded-top-start' : ''
          }} bg-light"
          [class.td-float-right]="!disableFloat && last"
          [class.shadow-lg]="!disableFloat && last"
          [class.bg-l]="!disableFloat && last"
        >
          {{ item | translate }}
        </th>
      </app-table-thead-tr>
    </thead>
  `,
  styles: [
    `
      :host {
        display: contents;
      }
    `,
  ],
})
export class TableTheadComponent {
  @Input({ required: true }) items: string[] = [];
  @Input() disableFloat: boolean = false;
}
