import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { generateArrayFromNumber } from '../../../functions/generate-array-from-number';
import {
  CurrentPageSubjectKeys,
  FiltersService,
} from '../../../services/filters/filters.service';
import { Status } from '../../../services/parameters/parameters.interface';
import { ParametersService } from '../../../services/parameters/parameters.service';
import { AuthService, Branch } from 'src/app/modules/auth';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'atom-advanced-search',
  templateUrl: './atom-advanced-search.component.html',
  styles: [],
})
export class AtomAdvancedSearchComponent implements OnInit {
  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() onClickApply = new EventEmitter();
  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() onClickPrev = new EventEmitter<number>();
  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() onClickNext = new EventEmitter<number>();
  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() onClickPagination = new EventEmitter<number>();
  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() onChangeBranch = new EventEmitter<any>();
  @Input() activeParameters: {
    name: string;
    key: CurrentPageSubjectKeys;
  }[] = [];
  @Input() searchPlaceholder!: string;
  @Input() filterLength: number = 0;
  @Input() total_page: number = 0;
  @Input() total_count: number = 0;
  @Input() parameterWithCols: boolean = false;
  @Input() enableOkayBtn: boolean = false;
  @Input() disableDatePicker: boolean = false;
  @Input() disablePagination: boolean = false;
  @Input() isFirmSelect: boolean = false;
  branchList: Branch[] = [];

  public readonly pagination = generateArrayFromNumber;

  collapse: boolean = false;

  localParameters: { [key: string]: Status[] } | undefined;

  constructor(
    public filtersService: FiltersService,
    public parametersService: ParametersService,
    private auth: AuthService
  ) {}

  public ngOnInit(): void {
    const activeParameters = this.activeParameters.map((item) => item.key);

    if (this.isFirmSelect) {
      this.auth.currentLoggedInUserSubject.subscribe((res) => {
        this.branchList = res?.branchs || [];
      });
    }

    this.parametersService.getParameters$().subscribe((parameters) => {
      if (activeParameters.length)
        this.localParameters =
          this.parametersService.getParametersWithArrayKeys(
            activeParameters,
            parameters
          );
    });
  }

  onSelectionChange(select: any, event: any) {
    select.close();
    this.onChangeBranch.emit(event.value);
  }

  handleSetSearchKeyword(event: any) {
    this.filtersService.setCurrentFilters('keyword', event.target.value || '');
  }

  handleSetLimit(event: any) {
    this.filtersService.setCurrentFilters('limit', event.target.value || '20');
    this.onClickApply.emit();
  }

  handleChangeStatus(key: CurrentPageSubjectKeys, event: any) {
    const value = event.target.value !== '0' ? event.target.value : '';
    this.filtersService.setCurrentFilters(key, value);
  }

  getLocalParameter(key: string): Status[] {
    const findL = this.localParameters?.[key];
    if (!findL) return [];
    return findL;
  }

  handleSaveDates(start: Date | null, end: Date | null) {
    this.filtersService.localDate.setValue({
      start: start || new Date(),
      end: end || new Date(),
    });
  }
}
