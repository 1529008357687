<h1 mat-dialog-title>{{ data.head }}</h1>
<div mat-dialog-content>
  <div
    class="px-8 pt-10"
    [ngStyle]="{ 'max-width': data.path ? '800px' : '400px' }"
  >
    <div class="fv-row mb-8">
      <div class="row justify-content-center g-9">
        <div
          class="{{ data.path ? 'col-md-6 col-lg-12 col-xxl-6' : 'col-12' }}"
        >
          <label
            class="btn btn-outline btn-outline-dashed btn-active-light-primary {{
              selectedRadio === 'filters' ? 'active' : ''
            }}  d-flex text-start p-6"
            (click)="selectedRadio = 'filters'"
          >
            <span
              class="form-check form-check-custom form-check-solid form-check-sm align-items-start mt-1"
            >
              <input
                class="form-check-input"
                type="radio"
                [checked]="selectedRadio === 'filters'"
              />
            </span>
            <span class="ms-5">
              <span class="fs-4 fw-bold text-gray-800 mb-2 d-block"
                >Filtrelerle Dışa Aktarma</span
              >
              <span class="fw-bold fs-7 text-gray-600">
                Seçtiğiniz filtreleri kullanarak dışa aktarma modülümüz
                sayesinde istediğiniz verileri kolayca dışa aktarabilirsiniz.
              </span>
            </span>
          </label>
        </div>
        <div *ngIf="data.path" class="col-md-6 col-lg-12 col-xxl-6">
          <label
            class="btn btn-outline btn-outline-dashed btn-active-light-primary {{
              selectedRadio === 'all' ? 'active' : ''
            }} d-flex text-start p-6"
            (click)="selectedRadio = 'all'"
          >
            <span
              class="form-check form-check-custom form-check-solid form-check-sm align-items-start mt-1"
            >
              <input
                class="form-check-input"
                type="radio"
                [checked]="selectedRadio === 'all'"
              />
            </span>
            <span class="ms-5">
              <span class="fs-4 fw-bold text-gray-800 mb-2 d-block"
                >Bu ayki Tüm Verileri Aktarma</span
              >
              <span class="fw-bold fs-7 text-gray-600">
                Sadece belirli verileri değil, tüm veri setinizi eksiksiz bir
                şekilde dışa aktarmak için bu seçeneği kullanabilirsiniz.
              </span>
            </span>
          </label>
        </div>
      </div>
    </div>
  </div>
</div>
<div mat-dialog-actions style="justify-content: flex-end" class="pb-6 px-8">
  <button class="btn btn-outline me-3" (click)="onClose()">
    {{ "APPLY.Cancel" | translate }}
  </button>
  <button class="btn btn-primary" [mat-dialog-close]="selectedRadio">
    {{ "APPLY.Save" | translate }}
  </button>
</div>
