import { CdkDrag, CdkDropList } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { RouterLink } from '@angular/router';
import { FullCalendarModule } from '@fullcalendar/angular';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { NgIcon } from '@ng-icons/core';
import { TranslateModule } from '@ngx-translate/core';
import { NgApexchartsModule } from 'ng-apexcharts';
import { NgxMaskDirective } from 'ngx-mask';
import { ThemeModeModule } from '../../extras/theme-mode-switcher/theme-mode.module';
import { IconsModule } from '../icons/icons.module';
import { MaterialModule } from '../material/material.module';
import { PipesModule } from '../pipes/pipes.module';
import { AtomLoadingComponent } from './atom-loading/atom-loading.component';
import { AtomSearchComponent } from './atom-search/atom-search.component';
import { AtomAdvancedSearchComponent } from './atom-advanced-search/atom-advanced-search.component';
import { AtomSnackbarComponent } from './atom-snackbar/atom-snackbar.component';
import { AtomNoDataComponent } from './atom-no-data/atom-no-data.component';
import { AtomDatePickerComponent } from './atom-date-picker/atom-date-picker.component';
import { AtomFullCalendarComponent } from './atom-full-calendar/atom-full-calendar.component';
import { PluspayCompContractComponent } from './pluspay-contract/pluspay-contract.component';
import { PluspayContractItemComponent } from './pluspay-contract-item/pluspay-contract-item.component';
import { AtomAccordionComponent } from './atom-accordion/atom-accordion.component';
import { ModalExportComponent } from './modal-export/modal-export.component';
import { AtomTimerComponent } from './atom-timer/atom-timer.component';
import { AtomSelectComponent } from './atom-select/atom-select.component';
import { AtomSelectDropdownComponent } from './atom-select-dropdown/atom-select-dropdown.component';
import { AtomDatepickerV2Component } from './atom-datepicker-v2/atom-datepicker-v2.component';
import { AtomApexChartComponent } from './atom-apex-chart/atom-apex-chart.component';
import { AtomTimepickerComponent } from './atom-timepicker/atom-timepicker.component';
import { AtomLogoutConfirmComponent } from './atom-logout-confirm/atom-logout-confirm.component';
import { TableTheadComponent } from './table-thead/table-thead.component';
import { TableTheadTrComponent } from './table-thead-tr/table-thead-tr.component';
import { AtomFullCalendarV2Component } from './atom-full-calendar-v2/atom-full-calendar-v2.component';
import { ModalPinComponent } from './modal-pin/modal-pin.component';
import { MatSelectModule } from '@angular/material/select';

@NgModule({
  declarations: [
    AtomLoadingComponent,
    AtomSearchComponent,
    AtomAdvancedSearchComponent,
    AtomSnackbarComponent,
    AtomNoDataComponent,
    AtomDatePickerComponent,
    AtomFullCalendarComponent,
    PluspayCompContractComponent,
    PluspayContractItemComponent,
    AtomAccordionComponent,
    ModalExportComponent,
    AtomTimerComponent,
    AtomSelectComponent,
    AtomSelectDropdownComponent,
    AtomDatepickerV2Component,
    AtomApexChartComponent,
    AtomTimepickerComponent,
    AtomLogoutConfirmComponent,
    TableTheadComponent,
    TableTheadTrComponent,
    AtomFullCalendarV2Component,
    ModalPinComponent,
  ],
  exports: [
    AtomLoadingComponent,
    AtomAdvancedSearchComponent,
    AtomSnackbarComponent,
    AtomSearchComponent,
    AtomNoDataComponent,
    AtomDatePickerComponent,
    AtomDatePickerComponent,
    AtomFullCalendarComponent,
    PluspayCompContractComponent,
    AtomAccordionComponent,
    AtomTimerComponent,
    AtomSelectComponent,
    AtomDatepickerV2Component,
    AtomApexChartComponent,
    AtomTimepickerComponent,
    TableTheadComponent,
    TableTheadTrComponent,
    AtomFullCalendarV2Component,
  ],
  imports: [
    CommonModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    NgxMaskDirective,
    MatFormFieldModule,
    MatDatepickerModule,
    PipesModule,
    FullCalendarModule,
    MatIconModule,
    RouterLink,
    IconsModule,
    TranslateModule,
    NgIcon,
    NgApexchartsModule,
    CdkDrag,
    CdkDropList,
    NgbTooltipModule,
    ThemeModeModule,
    MatSelectModule
  ],
})
export class ComponentsModule {}
