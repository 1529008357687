<div class="card-header d-flex align-items-center justify-content-between">
  <h3 class="card-title fw-semibold text-dark mb-0 fs-1">
    {{
      data.wallet_id
        ? ("wallet.editWallet" | translate)
        : ("wallet.addWallet" | translate)
    }}
  </h3>

  <button mat-dialog-close="" class="btn btn-sm btn-icon">
    <ng-icon
      name="heroXMarkSolid"
      size="22"
      style="display: contents"
    ></ng-icon>
  </button>
</div>
<div mat-dialog-content class="app-modal-content">
  <div class="mT-5 text-dark">
    <form
      novalidate="novalidate"
      class="form mt-5"
      *ngIf="walletForm"
      [formGroup]="walletForm"
      (ngSubmit)="submit()"
    >
      <div class="w-100 px-3">
        <div class="row">
          <div class="col-6 mb-3">
            <label class="col-form-label required fw-semibold fs-6"
              >{{ "wallet.name" | translate }}:</label
            >
            <input
              type="text"
              class="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
              name="title"
              formControlName="title"
              autocomplete="off"
              [ngClass]="{
                'is-invalid': walletForm.controls['title'].invalid,
                'is-valid': walletForm.controls['title'].valid
              }"
            />
          </div>
          <div class="col-6 mb-3">
            <label class="col-form-label fw-semibold fs-6"
              >{{ "wallet.type" | translate }}:</label
            >
            <select
              class="form-select form-select-lg form-select-solid"
              name="type"
              formControlName="type"
              autocomplete="off"
              [ngClass]="{
                'is-invalid': walletForm.controls['type'].invalid,
                'is-valid': walletForm.controls['type'].valid
              }"
            >
              <option [value]="'POSTPAID'">
                {{ "wallet.payLater" | translate }}
              </option>
              <option [value]="'PREPAID'">
                {{ "wallet.prepaid" | translate }}
              </option>
            </select>
          </div>
          <div class="col-6 mb-3" *ngIf="!walletId">
            <label class="col-form-label required fw-semibold fs-6"
              >{{ "wallet.balance" | translate }}:</label
            >
            <input
              type="number"
              class="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
              name="balance"
              formControlName="balance"
              autocomplete="off"
              [ngClass]="{
                'is-invalid': walletForm.controls['balance'].invalid,
                'is-valid': walletForm.controls['balance'].valid
              }"
            />
          </div>
          <div class="col-6 mb-3">
            <label class="col-form-label required fw-semibold fs-6"
              >{{ "wallet.dailyUsageAmount" | translate }}:</label
            >
            <input
              type="number"
              class="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
              name="daily_using_limit"
              formControlName="daily_using_limit"
              autocomplete="off"
              [ngClass]="{
                'is-invalid': walletForm.controls['daily_using_limit'].invalid,
                'is-valid': walletForm.controls['daily_using_limit'].valid
              }"
            />
          </div>
          <div class="col-4 mb-3">
            <label class="col-form-label required fw-semibold fs-6"
              >{{ "wallet.dailyLimit" | translate }}:</label
            >
            <input
              type="number"
              class="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
              name="daily_limit"
              formControlName="daily_limit"
              autocomplete="off"
              [ngClass]="{
                'is-invalid': walletForm.controls['daily_limit'].invalid,
                'is-valid': walletForm.controls['daily_limit'].valid
              }"
            />
          </div>
          <div class="col-4 mb-3">
            <label class="col-form-label required fw-semibold fs-6"
              >{{ "wallet.weeklyLimit" | translate }}:</label
            >
            <input
              type="number"
              class="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
              name="weekly_limit"
              formControlName="weekly_limit"
              autocomplete="off"
              [ngClass]="{
                'is-invalid': walletForm.controls['weekly_limit'].invalid,
                'is-valid': walletForm.controls['weekly_limit'].valid
              }"
            />
          </div>
          <div class="col-4 mb-3">
            <label class="col-form-label required fw-semibold fs-6"
              >{{ "wallet.monthlyLimit" | translate }}:</label
            >
            <input
              type="number"
              class="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
              name="montly_limit"
              formControlName="montly_limit"
              autocomplete="off"
              [ngClass]="{
                'is-invalid': walletForm.controls['montly_limit'].invalid,
                'is-valid': walletForm.controls['montly_limit'].valid
              }"
            />
          </div>

          <div class="col-6 mb-3">
            <label class="col-form-label required fw-semibold fs-6"
              >{{ "wallet.name" | translate }}:</label
            >
            <input
              type="text"
              class="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
              name="title"
              formControlName="title"
              autocomplete="off"
              [ngClass]="{
                'is-invalid': walletForm.controls['title'].invalid,
                'is-valid': walletForm.controls['title'].valid
              }"
            />
          </div>
          <div class="col-4 mb-3">
            <label class="col-form-label fw-semibold fs-6"
              >{{ "wallet.cardType" | translate }}:</label
            >
            <select
              class="form-select form-select-lg form-select-solid"
              name="card_type"
              formControlName="card_type"
              autocomplete="off"
              [ngClass]="{
                'is-invalid': walletForm.controls['card_type'].invalid,
                'is-valid': walletForm.controls['card_type'].valid
              }"
            >
              <option [value]="'CUZDANPLUS'">
                {{ "wallet.wallet+" | translate }}
              </option>
              <option [value]="'IDENTITY'">
                {{ "wallet.idCard" | translate }}
              </option>
              <option [value]="'SPECIAL'">
                {{ "wallet.specialCard" | translate }}
              </option>
            </select>
          </div>
          <div class="col-4 mb-3">
            <label class="col-form-label required fw-semibold fs-6"
              >{{ "wallet.cardNumber" | translate }}:</label
            >
            <input
              type="text"
              class="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
              name="card_number"
              formControlName="card_number"
              mask="0{36}"
              autocomplete="off"
              [ngClass]="{
                'is-invalid': walletForm.controls['card_number'].invalid,
                'is-valid': walletForm.controls['card_number'].valid
              }"
            />
          </div>
          <div class="col-4 mb-3">
            <label class="col-form-label fw-semibold fs-6"
              >{{ "wallet.expiryDate" | translate }}:</label
            >
            <input
              type="text"
              class="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
              name="expire_date"
              formControlName="expire_date"
              autocomplete="off"
              [ngClass]="{
                'is-invalid': walletForm.controls['expire_date'].invalid,
                'is-valid': walletForm.controls['expire_date'].valid
              }"
            />
          </div>
        </div>
      </div>

      <div class="separator mb-5"></div>
      <div class="d-flex justify-content-end">
        <button class="btn btn-primary" type="submit">
          <ng-container>{{ "STOCK_CATEGORY.Save" | translate }}</ng-container>
        </button>
      </div>
    </form>
  </div>
</div>
