// USA
export const locale = {
  lang: 'en',
  data: {
    TRANSLATOR: {
      SELECT: 'Select Your Language',
    },
    DATEPICKER: {
      January: 'January',
      February: 'February',
      March: 'March',
      April: 'April',
      May: 'May',
      June: 'June',
      July: 'July',
      August: 'August',
      September: 'September',
      October: 'October',
      November: 'November',
      December: 'December',
      Sun: 'Sun',
      Mon: 'Mon',
      Tue: 'Tue',
      Wed: 'Wed',
      Thu: 'Thu',
      Fri: 'Fri',
      Sat: 'Sat',
    },
    DASHBOARD: {
      Sales: 'Sales',
      Filters: 'Filters',
      TransactionCount: 'Transaction Count',
      TotalNet: 'Total Net',
      SalesAmount: 'Sales Amount',
      TotalVAT: 'Total VAT',
      Amount: 'Amount',
      GrossSales: 'Gross Sales',
      TotalCancellation: 'Total Cancellation',
      Collections: 'Collections',
      CollectionDetails: 'Collection Details',
      Collection: 'Collection',
      Type: 'Type',
      Total: 'Total',
      Transaction: 'Transaction',
      Piece: 'Piece',
      Cart: 'Cart',
      Cash: 'Cash',
      ForwardSale: 'Forward Sale',
      BANK_TRANSFER: 'Bank Transfer',
      CreditCard: 'Credit Card',
      PayWithQR: 'Pay With QR',
      PayWithNFC: 'Pay With NFC',
      RdParty: '3rd Party',
    },
    MENU: {
      NEW: 'New',
      ACTIONS: 'Actions',
      CREATE_POST: 'Create New Post',
      PAGES: 'Pages',
      FEATURES: 'Features',
      APPS: 'Applications',
      DASHBOARD: 'Dashboard',
      TRANSACTIONS: 'Transactions',
      ORDERS: 'Orders',
      COLLECTIONS: 'Collections',
      E_DOCUMENTS: 'E-Documents',
      CANCELLATION_REFUND: 'Cancellation / Refund',
      CURRENT_ACCOUNT: 'Current Account',
      BILLS: 'Bills',
      OUTGOING_INVOICES: 'Outgoing Invoices',
      INCOMING_INVOICES: 'Incoming Invoices',
      REPORTS: 'List and Reports',
      END_OF_DAY_REPORT: 'End of Day Report',
      TURNOVER_REPORT: 'Document Report',
      VALUE_REPORT: 'Value Report',
      COMMISSION_REPORT: 'Commission Report',
      INVOICED_TRANSACTIONS: 'E-Document',
      STOCK_TRANSACTIONS: 'Stock',
      CATEGORIES: 'Quick Entry',
      PRODUCTS: 'All Products',
      VARIOUS_PRODUCTS: 'Various Products',
      DailyCashReport: 'Daily Cash Report',
      UserCashReport: 'User Cash Report',
      UserReport: 'User Report',
      XReport: 'X Report',
      MonthlySalesReport: 'Monthly Sales Report',
      ProductSalesReport: 'Product Sales Report',
      POSCashReport: 'POS Terminal Detail Report',
      Management: 'Management',
      CompanyInformation: 'Company Information',
      Profiles: 'Profiles',
      Branches: 'Branches',
      Users: 'Users',
      Terminals: 'Terminals',
      Application: 'Application',
      ApplicationInformation: 'Application Information',
      PluspayContract: 'Pluspay Contract',
      PaycellContract: 'Paycell Contract',
      CompanyDocuments: 'Company Documents',
      VUK507Application: 'VUK 507 Application',
      CompleteApplication: 'Complete Application',
      Taxis: 'Taxis',
      Pauses: 'Pauses',
    },
    GENERAL: {
      numberOfTransactions: 'Number Of Transactions',
      endorsement: 'Endorsement',
      clickToCopy: 'Click to Download PDF and Copy Number',
      Today: 'Today',
      Yesterday: 'Yesterday',
      ThisWeek: 'This Week',
      ThisMonth: 'This Month',
      LastMonth: 'Last Month',
      ThisYear: 'This Year',
      PleaseWait: 'Please wait...',
      AllRightsReserved: '© 2024 PlusPay. All rights reserved.',
      PlusPayTechnologyServices:
        'Pluspay Technology Services Joint Stock Company, T.R. the central bank',
      PaycellPaymentInstitution:
        'It is the integrator of Paycell Payment Institution, which has the permission of (TCMB).',
      OPTIONAL: 'Optional',
      PleaseEnterTheCodeSentToYourMobilePhone:
        'Please enter the code sent to your mobile phone.',
      SelectDate: 'Select Date',
      Filter: 'Filter',
      Search: 'Search',
      Export: 'Export',
      NotFound: 'Not Found',
      ListedResults: 'Listed {{length}} Results',
      Confirm: 'Confirm',
      Cancel: 'Cancel',
      FilterOptions: 'Filter Options',
      NoSelection: 'No Selection',
      Reset: 'Reset',
      SelectOrderStatus: 'Select Order Status',
      EndDate: 'End Date',
      LightColor: 'Light Color',
      DarkColor: 'Dark Color',
      SystemColor: 'System Color',
      SearchOrder: 'Search Order...',
      Orders: 'Orders',
      RemoveSelected: 'Remove Selected',
      City: 'City',
      SelectCashier: 'Select Cashier',
      SelectTerminalPOS: 'Select Terminal',
      SelectDocument: 'Select Document',
      DeleteCategory: 'Delete Category',
      All: 'All',
      ConfirmDeleteCategory:
        'Are you sure you want to delete the category named?',
      SuccessfullyDeleted: 'Successfully deleted',
      SuccessfullySaved: 'Successfully saved',
      ProductSuccessfullyCreated: 'Product Successfully Created',
      ProductSuccessfullyDeleted: 'Product Successfully Deleted',
      DeleteProduct: 'Delete Product',
      ConfirmDeleteProduct: 'Are you sure you want to delete the product?',
      MyProfile: 'My Profile',
      SUCCESS: 'Success',
      FAIL: 'Fail',
      WAITING: 'Waiting',
      LOG_OUT: 'Log Out',
      Profile: 'Profile',
      Language: 'Language',
      Yes: 'Yes',
      No: 'No',
      TotalPaid: 'Total Paid',
      SelectStatus: 'Select Status',
      Success: 'Success',
      Warning: 'Warning',
      Failure: 'Failure',
      SUPERADMIN: 'Superadmin',
      AUTHORIZED: 'Authorized',
      CASHIER: 'Cashier',
      COURIER: 'Courier',
      DeleteUser: 'Delete User',
      ConfirmDeleteUser: 'Are you sure you want to delete the selected user?',
      Name: 'Name',
      Phone: 'Phone',
      Email: 'Email',
      Role: 'Role',
      FirmIds: 'Firms',
      Roles: 'Roles',
      USER: 'User',
      CANCEL: 'Cancel',
      REFUND: 'Refund',
      DISCOUNT: 'Discount',
      PRODUCT: 'Product',
      PaymentStatus: 'Payment Status',
      DocumentStatus: 'Document Status',
      serial_no: 'Serial Number',
      terminal_id: 'Terminal ID',
      param_4: 'Username',
      param_5: 'Password',
      invoice_prefix: 'Invoice Prefix',
      param_3: 'Terminal Code',
      Start: 'Start Time',
      End: 'End Time',
      TotalOfMealCards: 'Total Of Meal Cards',
      signinOut: 'Will sign out soon.',
      allCards: 'All Cards',
      hide: 'Hide',
      show: 'Show',
      brand: 'Brand',
      region: 'Region',
    },
    APPLY: {
      CompanyInformation: 'Company Information',
      AuthorizedInformation: 'Authorized Information',
      IBANInformation: 'IBAN Information',
      BranchInformation: 'Branch Information',
      Details: 'Details',
      Brand: 'Brand',
      Tariff: 'Tariff',
      Source: 'Source',
      CompanyType: 'Select Company Type',
      IndividualEnterprise: 'Individual Enterprise',
      IndividualEnterpriseDescription:
        'Individual enterprise is a type of business structure where the owner and the business itself are completely controlled and operated by the owner.',
      LimitedOrAS: 'Limited or A.S.',
      LimitedOrASDescription:
        'Anonim Şirket (A.S.) is a more common business structure for larger enterprises and publicly traded companies.',
      TaxNumber: 'Tax Number',
      GetFromGIB: 'Get from GIB',
      TaxOffice: 'Tax Office',
      Title: 'Title',
      City: 'City',
      District: 'District',
      HeadquartersAddress: 'Headquarters Address',
      PosInformation: 'POS Information',
      Sector: 'Sector',
      InstallmentOption: 'Installment Option - Yes/No',
      POSType: 'POS Type',
      TechnicalPartner: 'Technical Partner',
      BlockedDayCount: 'Blocked Day Count',
      NextStep: 'Next Step',
      Day: 'Day',
      Weekly: 'Weekly',
      Monthly: 'Monthly',
      BlockedDayCountDefinition:
        'Blocked day count is the number of days a specific financial transaction or asset cannot be used or is restricted',
      TCIdentity: 'TC Identity',
      AuthorizedName: 'Authorized Name',
      AuthorizedSurname: 'Authorized Surname',
      AuthorizedPhone: 'Authorized Phone',
      AuthorizedEmail: 'Authorized Email',
      PrivateAuthorizedInformation:
        'Private Authorized Information for Your Company',
      InitiateAuthorizedCreation:
        'Click the button below to initiate the authorized creation model',
      AddAuthorized: 'Add Authorized',
      AuthorizedList: 'Authorized List',
      NameSurname: 'Name / Surname',
      PhoneNumber: 'Phone Number',
      Email: 'Email',
      Operations: 'Operations',
      PreviousStep: 'Previous Step',
      Cancel: 'Cancel',
      Add: 'Add',
      AddIBAN: 'Add IBAN',
      IBANName: 'IBAN Name',
      ConfirmIBANOwnership: 'I confirm that the IBAN belongs to my company',
      PrivateIBANInformation: 'Private IBAN Information for Your Company',
      InitiateIBANCreation:
        'Click the button below to initiate the IBAN creation model',
      IBANList: 'IBAN List',
      SelectBank: 'Select Bank',
      AddBranch: 'Add Branch',
      BranchName: 'Branch Name',
      PosCount: 'POS Count',
      SelectIBAN: 'Select IBAN',
      BranchManagerName: 'Branch Manager Name',
      BranchManagerSurname: 'Branch Manager Surname',
      BranchManagerPhone: 'Branch Manager Phone',
      BranchManagerEmail: 'Branch Manager Email',
      AddressInformation: 'Address Information',
      BranchCity: 'Branch City',
      BranchDistrict: 'Branch District',
      BranchAddress: 'Branch Address',
      PrivateBranchInformation: 'Private Branch Information for Your Company',
      InitiateBranchCreation:
        'Click the button below to initiate the branch creation model',
      BranchList: 'Branch List',
      Branch: 'Branch',
      AuthorizedNameSurname: 'Authorized Name / Surname',
      Address: 'Address',
      Quantity: 'Quantity',
      CreatingCustomContract:
        'Creating custom contract (.pdf) for your company, please wait',
      CustomContract: 'Custom Contract for Your Company',
      NoPrinterMessage: "If you don't have a printer to print the contract",
      NoPrinter: 'No Printer',
      RequestFreeShipping:
        'You can request free shipping to your address with the button',
      PluspayContractReview:
        'Pluspay contracts will be uploaded to the system and will be reviewed and approved by our operations team.',
      FreeShippingAfterApproval:
        'After approval, a free shipping company will be automatically directed to your address.',
      DeliveryInstructions:
        'Please deliver the signed original documents in full to the courier personnel.',
      PleaseWait: 'Please Wait',
      DownloadContract: 'Download Contract',
      PrintContract: 'Print Contract',
      UploadSignedContract: 'Upload Signed Contract',
      UploadTaxCertificate: 'Upload Tax Certificate',
      FileLimitInfo: 'File limit is 1',
      UploadedFiles: 'Uploaded Files',
      NoFileUploaded: "You haven't uploaded any files yet",
      UploadSignatureCirculations: 'Upload Signature Circulations',
      UploadTaxCertificatePluspay: 'Pluspay tax certificate',
      Remove: 'Remove',
      UploadAuthorizedIDFront: 'Upload Authorized ID Front',
      IDCopyMaxMinInfo: 'File limit is 1',
      TaxCertificatePluspay: 'Pluspay tax certificate',
      UploadAuthorizedIDBack: 'Upload Authorized ID Back',
      UploadIBANConfirmationDocument: 'Upload IBAN Confirmation Document',
      IBANDocumentMaxMinInfo:
        'IBAN Confirmation Document or Bank Statement Copy with IBAN Information. File limit is 1',
      IBANCopyMaxMinInfo: 'File limit is 1',
      UploadOtherDocuments: 'Upload Other Documents',
      OtherDocumentInfo:
        'You can upload other documents such as business activity certificate, chamber registration document, trade registry newspaper, etc. here',
      NeedMoreInfo: 'If you need more information, please contact us',
      Save: 'Save',
      SendUploadedDocuments: 'Send Uploaded Documents',
      IDCopyMaxMinInfo2:
        "ID Copy (ID Card / Driver's License / Passport). File limit is 1",
      VUK507ApplicationProcess: 'VUK 507 Application Process',
      InteractiveTaxOffice: 'Interactive Tax Office',
      ViewProcess: 'View Process',
      ReadAndApproveProcess: 'Read and Approve Process',
      CompleteProcess: 'Complete Process / Apply',
      CompleteApplicationNote:
        'Our team needs to review your application for completion. After that, we will reach out to you.',
      CompleteApplication: 'Complete Application',
      ApplicationUnderReview: 'Review',
      DocumentVerification: 'Progress',
      ContractApproval: 'Contract Approval',
      ActivationPending: 'Activation is Pending',
      CongratulationsApplicationReady:
        'Congratulations! Your Application is Ready!',
    },
    ORDER_DETAIL: {
      Print: 'Print',
      Order: 'Order',
      OrderCode: 'Order Code',
      OrderDate: 'Order Date',
      PaymentType: 'Payment Type',
      Partial: 'Partial',
      OrderSource: 'Order Source',
      Installment: 'Installment',
      Actions: 'Actions',
      ClientIP: 'Client IP',
      TotalAmount: 'Total Amount',
      Products: 'Products',
      ProductName: 'Product Name',
      Unit: 'Unit',
      Quantity: 'Quantity',
      TotalPrice: 'Total Price',
      OrderStatus: 'Order Status',
      CustomerDetail: 'Customer Detail',
      CustomerName: 'Customer Name',
      ContactInfo: 'Contact Information',
      CustomerCode: 'Customer Code',
      TaxOffice: 'Tax Office',
      InvoiceDetail: 'Invoice Detail',
      Subtotal: 'Subtotal',
      VATAmount: 'VAT Amount',
      Discount: 'Discount',
      GeneralAmount: 'General Amount',
      AmountDue: 'Amount Due',
    },
    POS_LIST: {
      PosList: 'Pos List',
      Definition: 'Definition',
      Company: 'Branch',
      AccountCode: 'Account Code',
      SerialNumber: 'Serial Number',
      TerminalID: 'Terminal ID',
      Mode: 'Mode',
      Online: 'Online',
      Status: 'Status',
      Active: 'Active',
      Passive: 'Passive',
      OnlineStatus: 'Online',
      OfflineStatus: 'Offline',
      TerminalInformation: 'Terminal Information',
      LoadTerminalParameters: 'Load Terminal Parameters',
      GetEndOfDay: 'Get End of Day',
      TerminalDetail: 'Terminal Detail',
    },
    USERS: {
      User: 'User',
      FullName: 'Full Name',
      PhoneNumber: 'Phone Number',
      Role: 'Role',
      RegistrationDate: 'Registration Date',
      Status: 'Status',
      UserList: 'User List',
      SearchUser: 'Search User',
      EMail: 'E-Mail',
    },
    BRANCHES: {
      BranchList: 'Branch List',
      BranchCode: 'Branch Code',
      BranchName: 'Branch Name',
      Location: 'Location',
      TerminalS: 'Terminal S',
      Record: 'Record',
      Date: 'Date',
      Status: 'Status',
      SearchBranch: 'Search Branch',
    },
    DAILY_POS_REPORT: {
      UserCashReports: 'POS Cash Reports',
      TotalCashSales: 'TOTAL CASH SALES',
      TotalCashRefund: 'TOTAL CASH REFUND',
      date: 'Date',
      time: 'Time',
      terminalNo: 'Terminal No (Device No)',
      user: 'User',
      vehiclePlate: 'Vehicle Plate',
      stopInformation: 'Stop Information',
      totalRevenue: 'Total Revenue',
      userEarnedAmount: 'User Earned Amount',
      userEarnedDate: 'User Earned Date',
    },
    PRODUCT_SALE_REPORT: {
      SearchProduct: 'Search Product',
      OrderList: 'Product Sale Report',
      ProductName: 'Product Name',
      TaxRate: 'Tax Rate',
      Quantity: 'Quantity',
      TotalPrice: 'Total Price',
    },
    REPORT_MONTHLY: {
      SearchMonthlyReport: 'Search Monthly Report',
      MonthlySalesList: 'Monthly Sales List',
      DocumentType: 'Document Type',
      Branch: 'Branch',
      PaymentType: 'Payment Type',
      PaymentAgentType: 'Payment Agent Type',
      VATRate: 'VAT Rate (%)',
      VATAmount: 'VAT Amount',
      PaymentAmount: 'Payment Amount',
      PaymentCount: 'Payment Count',
      TotalAmount: 'Total Amount',
    },
    REPORT_TURNOVER: {
      SearchSale: 'Search Sale',
      SelectBranch: 'Select Branch',
      SelectCashier: 'Select Cashier',
      SelectDocumentType: 'Select Document Type',
      SelectPOS: 'Select POS',
      SaleList: 'Document Report',
      POS: 'POS',
      Branch: 'Branch',
      Cashier: 'Cashier',
      MerchantDocumentType: 'Document Type',
      DocumentSerialNo: 'Document Serial No',
      PaymentType: 'Payment Type',
      VATRate: 'VAT Rate',
      TotalQuantity: 'Total Product Quantity',
      Unit: 'Unit',
      TotalSalesAmount: 'Total Amount',
      TotalVATAmounts: 'VAT Amount',
      GeneralTotal: 'General Total',
      Status: 'Status',
      Detail: 'Detail',
      DocumentDownload: 'Document Download',
      CustomerDetail: 'Customer Detail',
      CustomerName: 'Customer Name',
      INTEGRATOR: 'INTEGRATOR',
      Company: 'Company',
      InvoiceDetail: 'Invoice Detail',
      Payment: 'Payment',
      Print: 'Print',
    },
    X_REPORT: {
      SearchXReportNo: 'Search X Report No',
      XReportList: 'X Report',
      XReportNo: 'X Report No',
      CreationDate: 'Creation Date',
      Branch: 'Branch',
      Terminal: 'Terminal',
      User: 'User',
      TotalPaymentCount: 'Total Payment Count',
      TotalPaymentAmount: 'Total Payment Amount',
      TotalCancelCount: 'Total Cancel Count',
      TotalCancelAmount: 'Total Cancel Amount',
      FirstPaymentDate: 'First Payment Date',
      LastPaymentDate: 'Last Payment Date',
      Status: 'Status',
    },
    Z_REPORT: {
      SearchZReportNo: 'Search Z Report No',
      EndOfDayReportList: 'End of Day Report',
      ZReportNo: 'Z Report No',
      CreationDate: 'Creation Date',
      Branch: 'Branch',
      Terminal: 'Terminal',
      User: 'User',
      TotalPaymentCount: 'Total Payment Count',
      TotalPaymentAmount: 'Total Payment Amount',
      TotalCancelCount: 'Total Cancel Count',
      TotalCancelAmount: 'Total Cancel Amount',
      Status: 'Status',
    },
    USER_CASH_REPORT: {
      UserCashReports: 'User Cash Reports',
      User: 'User',
      TotalCashSales: 'Total Cash Sales',
      TotalCashRefund: 'Total Cash Refund',
    },
    DAILY_CASH_REPORT: {
      DailyCashReportList: 'Daily Cash Report List',
      TransactionDate: 'Transaction Date',
      TotalCashSales: 'Total Cash Sales',
      TotalCashRefund: 'Total Cash Refund',
    },
    REPORT_COMMISSION: {
      SearchCommission: 'Search Commission',
      CommissionList: 'Commission List',
      TransactionDate: 'Transaction Date',
      Branch: 'Branch',
      OrderNumber: 'Order Number',
      CollectionType: 'Collection Type',
      Installment: 'Installment',
      Amount: 'Amount',
      CommissionRate: 'Commission Rate',
      Commission: 'Commission',
      NetAmount: 'Net Amount',
      ValueDate: 'Value Date',
    },
    CURRENT_ACCOUNT: {
      SearchCurrent: 'Search Current',
      TransactionDate: 'Transaction Date',
      TransactionType: 'Transaction Type',
      TransactionDescription: 'Transaction Description',
      Debt: 'Debt',
      Credit: 'Credit',
      Balance: 'Balance',
      CurrentAccountList: 'Current Account List',
    },
    ORDER: {
      TransactionDate: 'Transaction Date',
      OrderCode: 'Order Code',
      SpecialCode: 'Special Code',
      Customer: 'Customer',
      TotalAmount: 'Total Amount',
      RemainingAmount: 'Remaining Amount',
      CollectedAmount: 'Collected Amount',
      Status: 'Status',
      OrderList: 'Order List',
      SearchOrder: 'Search Order...',
    },
    COLLECTION: {
      OrderDate: 'Order Date',
      PaymentMethod: 'Payment Method',
      PaymentType: 'Payment Type',
      OrderCode: 'Order Code',
      OrderSpecialCode: 'Order Special Code',
      Customer: 'Customer',
      TotalAmount: 'Total',
      Status: 'Status',
      SearchCollection: 'Search Collection...',
      CollectionList: 'Collection List',
      SelectCollectionStatus: 'Select Collection Status',
      SelectPaymentType: 'Select Payment Type',
      SelectPaymentMethod: 'Select Payment Method',
      PaymentTypeMethod: 'Payment Type/Method',
    },
    TRANSACTION_CANCEL: {
      SearchCollection: 'Search Cancel...',
      InvoiceList: 'Cancellation / Refund List',
      OrderDate: 'Order Date',
      PaymentMethod: 'Payment Method',
      PaymentType: 'Payment Type',
      OrderCode: 'Order Code',
      OrderSpecialCode: 'Order Special Code',
      Customer: 'Customer',
      Status: 'Status',
      SelectCollectionStatus: 'Select Collection Status',
      SelectPaymentType: 'Select Payment Type',
      SelectPaymentMethod: 'Select Payment Method',
    },
    INVOICE: {
      DocumentList: 'Document List',
      SearchDocument: 'Search Document...',
      OrderDate: 'Order Date',
      PaymentMethod: 'Payment Method',
      PaymentType: 'Payment Type',
      OrderCode: 'Order Code',
      OrderSpecialCode: 'Order Special Code',
      Customer: 'Customer',
      Status: 'Status',
      SelectCollectionStatus: 'Select Collection Status',
      SelectPaymentType: 'Select Payment Type',
      SelectPaymentMethod: 'Select Payment Method',
    },
    STOCK_CATEGORY: {
      CategorySelect: 'Select Category',
      Categories: 'List',
      ProductName: 'Product Name',
      ProductCategory: 'Product Category',
      ProductType: 'Product Type',
      Unit: 'Unit',
      ProductPrice: 'Product Price (TL)',
      VATRate: 'VAT Rate (%)',
      ProductPrice2: 'Product Price - 2',
      ProductPrice3: 'Product Price - 3',
      PurchasePrice: 'Purchase Price',
      ProductDescription: 'Product Description',
      ProductCode: 'Product Code',
      Stock: 'Stock',
      StockWarningCount: 'Stock Warning Count',
      IsActive: 'Is Product Active?',
      Save: 'Save',
      CategoryName: 'Category Name',
      CategoryCode: 'Category Code',
      IsCategoryActive: 'Is Category Active?',
      EditCategory: 'Edit Category',
      AddNewCategory: 'Add New Category',
      NoCategoriesYet: "You haven't created any categories yet.",
      CreateCategoryInstructions:
        'To create a category, you can use the button below.',
      CreateCategoryButton: 'Create Category',
      UseButtonBelow: 'Use the button below',
      NoAddedProductsFound: 'No added products found.',
      VAT: 'VAT',
      Price: 'Price',
      AddNewProduct: 'Add New Product',
    },
    STOCK_PRODUCT: {
      ProductList: 'Product List',
      Product: 'Product',
      VAT: 'VAT',
      UnitStock: 'Unit / Stock',
      StockWarning: 'Stock Warning',
      IsActive: 'Is Active?',
      Operations: 'Operations',
      AddNew: 'Add New',
      AddNewProduct: 'Add New Product',
      Warning: 'Warning',
      Active: 'Active',
      Passive: 'Passive',
      ProductDetail: 'Product Detail',
      Search: 'Search Product...',
      SelectProductType: 'Select Product Type',
    },
    AUTH: {
      GENERAL: {
        OR: 'or',
        SUBMIT_BUTTON: 'Submit',
        NO_ACCOUNT: "Don't have an account?",
        SIGNUP_BUTTON: 'Sign Up',
        FORGOT_BUTTON: 'Forgot Password',
        BACK_BUTTON: 'Back',
        PRIVACY: 'Privacy',
        LEGAL: 'Legal',
        CONTACT: 'Contact',
      },
      LOGIN: {
        TITLE: 'Login to Account',
        BUTTON: 'Login',
        ToOurInternetBranch: 'To our Internet Branch',
        Welcome: 'Welcome.',
        Now: 'Now',
        Login: 'Login',
        LoginInformationIsInvalid: 'Login Information is Invalid.',
        Branch: 'Branch',
        PhoneNumber: 'Phone Number',
        PhoneRequired: 'Phone Required',
        PhoneInvalid: 'Phone Invalid',
        PhoneNumberMustBe12CharactersLong:
          'Phone Number Must Be 12 Characters Long.',
        Verify: 'Verify',
        CreateAccount: 'Create Account',
      },
      FORGOT: {
        TITLE: 'Forgot Your Password?',
        DESC: 'Enter your email address to reset your password.',
        SUCCESS: 'Your account has been successfully reset.',
      },
      REGISTER: {
        TITLE: 'Sign Up',
        DESC: 'Enter your information to create your account',
        SUCCESS: 'Your account has been successfully registered.',
        CreateRegistration: 'Create Registration',
        YourName: 'Your Name',
        EMailAddress: 'E-Mail Address',
        CampaignCode: 'Campaign Code',
        BrandCode: 'Brand Code',
        PartnerCode: 'Partner Code',
        ClarificationText: 'Clarification text',
        And: 'and',
        KVKKRules: 'KVKK Rules',
        IReadIApprove: 'I have read and approve.',
        CommercialMessageText: 'Commercial message text',
        CompleteApplication: 'Complete Application',
        ReturnToLoginScreen: 'Return to Login Screen',
      },
      INPUT: {
        EMAIL: 'Email',
        FULLNAME: 'Full Name',
        PASSWORD: 'Password',
        CONFIRM_PASSWORD: 'Confirm Password',
        USERNAME: 'Username',
      },
      VALIDATION: {
        INVALID: '{{name}} is not valid',
        REQUIRED: '{{name}} is required',
        MIN_LENGTH: '{{name}} minimum length is {{min}}',
        AGREEMENT_REQUIRED: 'Accepting the terms is required',
        NOT_FOUND: '{{name}} not found',
        INVALID_LOGIN: 'Invalid login information',
        REQUIRED_FIELD: 'This field is required',
        MIN_LENGTH_FIELD: 'Minimum field length:',
        MAX_LENGTH_FIELD: 'Maximum field length:',
        INVALID_FIELD: 'Invalid field',
      },
    },
    customer: {
      search: 'Search Customer',
      list: 'Customer List',
      name: 'Name',
      id: 'ID',
      taxOffice: 'Tax Office',
      phone: 'Phone',
      email: 'Email',
      address: 'Address',
      city: 'City',
      district: 'District',
      neighborhood: 'Neighborhood',
      detail: 'Customer Detail',
      addCustomer: 'Add Customer',
      deleteCustomer: 'Delete Customer',
      confirmDeleteCustomer:
        'Are you sure you want to delete the customer named',
      account_type: 'Account Type',
      account_tag: 'Account Tag',
    },
    wallet: {
      list: 'Wallet List',
      name: 'Wallet Name',
      balance: 'Balance',
      id: 'ID',
      phone: 'Phone',
      email: 'Email',
      taxOffice: 'Tax Office',
      postcode: 'Postcode',
      addressLine1: 'Address Line 1',
      addressLine2: 'Address Line 2',
      city: 'City',
      district: 'District',
      neighborhood: 'Neighborhood',
      deleteWallet: 'Delete Wallet',
      confirmDeleteWallet: 'Are you sure you want to delete the wallet named',
      editWallet: 'Edit Wallet',
      addWallet: 'Add Wallet',
      walletName: 'Wallet Name',
      type: 'Type',
      payLater: 'Pay Later',
      prepaid: 'Prepaid',
      dailyUsageAmount: 'Daily Usage Amount',
      dailyLimit: 'Daily Limit',
      weeklyLimit: 'Weekly Limit',
      monthlyLimit: 'Monthly Limit',
      cardType: 'Card Type',
      'wallet+': 'Wallet+',
      idCard: 'ID Card',
      specialCard: 'Special Card',
      cardNumber: 'Card Number',
      expiryDate: 'Expiry Date',
    },
  },
};
