export interface TransactionWelcome {
  transaction_id: string;
  branchs:        Branch[];
}

export interface Branch {
  id:    string;
  title: string;
}

export interface LoginWelcome {
  token: string;
  data:  UserData;
}

export interface UserData {
  id:        string;
  name:      string;
  phone:     string;
  role:      string;
  company:   Company;
  branchs:   Branch[];
  branch_id: string;
}


export interface Company {
  id:   string;
  name: string;
}

export interface UserWelcome {
  id:        number;
  name:      string;
  phone:     string;
  role:      string;
  company:   Company;
  branch_id: string;
  branchs:   Branch[];
  token?: string
}

export interface Company {
  id:   string;
  name: string;
}
